import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthApi {

  private user: any;
  public doctorIsIn: boolean = false;


  constructor(
    public afAuth: AngularFireAuth,
    public events: Events,
    private storage: Storage) {

    this.initAuthState();
  }

  initAuthState() {
    this.afAuth.authState.subscribe(user => {
      if (user != null) {
        this.user = user;
        if (user['uid'] != null) this.doctorIsIn = true;
        console.log("AuthTrack AuthApi: initAuthState user logged in", user['uid']);
      } else {
        // there is no user Logged In
        console.log("AuthTrack AuthApi: initAuthState there is no user logged in");
      }
    });
  }

  // signInWithFacebook() {
  // 	console.log('Sign in with google');
  // 	return this.oauthSignIn(new firebase.auth.FacebookAuthProvider());
  // }
  //
  // signInWithGoogle() {
  // 	console.log('Sign in with google');
  // 	return this.oauthSignIn(new firebase.auth.GoogleAuthProvider());
  // }

  signInWithEmail(credentials) {
    console.log('Sign in with email');
    return this.afAuth.auth.signInWithEmailAndPassword(credentials.email,
      credentials.password);
  }

  sendPasswordReset(credentials) {
    return this.afAuth.auth.sendPasswordResetEmail(credentials.email);
  }

  newUserWithEmail(credentials) {
    console.log('Sign up with email', credentials);
    return this.afAuth.auth.createUserWithEmailAndPassword(credentials.email,
      credentials.password);
  }

  signOut() {
    this.afAuth.auth.signOut().then(result => {
      //result is undefined
      console.log("Signed Out")
      this.doctorIsIn = false;
      // clear saved variables
      // these functions have been moved to configApi

    }, error=>{
      console.log("Error while signing out authorized user");
    })
  }

}
