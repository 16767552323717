import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, MatInputModule } from '@angular/material';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Zoom } from '@ionic-native/zoom/ngx';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BillableModalPageModule } from './billable-modal/billable-modal.module';
import { ContentModalPageModule } from './content-modal/content-modal.module';
import { EditEventPageModule } from './edit-event/edit-event.module';
import { MenuPageModule } from './menu/menu.module';
import { NewChargeSlipPageModule } from './new-charge-slip/new-charge-slip.module';
import { PatientModalPageModule } from './patient-modal/patient-modal.module';
import { PrescriptionFormPageModule } from './prescription-form/prescription-form.module';
import { RecordVitalsPageModule } from './record-vitals/record-vitals.module';
import { RecordListPageModule } from './record-list/record-list.module';
import { ReportsPageModule } from './reports/reports.module';
import { ReportModalPageModule } from './report-modal/report-modal.module';
import { ScanImagePageModule } from './scan-image/scan-image.module';
import { ScanProfilePageModule } from './scan-profile/scan-profile.module';
import { TeleScriptPageModule } from './tele-script/tele-script.module';

import { ComponentsModule } from './components.module';
//import { VisitsComponent } from "./visits/visits.component";
import { AppointmentListSettingComponent } from './appointment-list-setting/appointment-list-setting.component';
import { ImageModalPageModule } from './image-modal/image-modal.module';
import { MedicineLookupModalPage } from './medicine-lookup-modal/medicine-lookup-modal.page';
import { MedicineLookupModalPageModule } from './medicine-lookup-modal/medicine-lookup-modal.module';

@NgModule({
  declarations: [AppComponent,AppointmentListSettingComponent],
  entryComponents: [
    //VisitsComponent
    AppointmentListSettingComponent
  ],
  imports: [BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ComponentsModule,
    BillableModalPageModule,
    ContentModalPageModule,
    EditEventPageModule,
    MenuPageModule,
    NewChargeSlipPageModule,
    PatientModalPageModule,
    PrescriptionFormPageModule,
    RecordVitalsPageModule,
    RecordListPageModule,
    ReportsPageModule,
    ReportModalPageModule,
    ScanImagePageModule,
    ScanProfilePageModule,
    TeleScriptPageModule,
    ImageModalPageModule,
    MedicineLookupModalPageModule],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    Crop,
    Chooser,
    File,
    FileOpener,
    FileTransfer,
    PhotoViewer,
    Zoom,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
