import { Injectable } from '@angular/core';
import { Observable , of} from 'rxjs';
import { Billable } from '../billable';



@Injectable({
  providedIn: 'root'
})
export class BillingMasterProvider {
  billingMaster: any[];

  constructor() {
    console.log('Hello BillingMasterProvider Provider');
    this.loadBillingMaster();
  }

  loadBillingMaster() {
    this.billingMaster = [{
      description: 'Consultation',
      unit: 'encounter',
      unitPrice: 1000
    },
    {
      description: 'Medical Certification',
      unit: 'printout',
      unitPrice: 500
    },
    {
      description: 'Vaccination',
      unit: 'dosage',
      unitPrice: 2000
    }];
  }

  getBillingMaster(): Observable<any[]> {
    let data: any[] = this.billingMaster;
    return of(data);
  }

  removeBillable(billingMaster: any) {
    let index: number;

    for (let i = 0; i < this.billingMaster.length; i++) {
      if (this.billingMaster[i] == billingMaster) {
        index = i;
        i = this.billingMaster.length;
      }
    }

    this.billingMaster.splice(index, 1);
  }

  addBillable(newBillable: Billable) {
    this.billingMaster.push(newBillable);
  }

  editBillable(origBillable: Billable, updatedBillable: Billable) {
    let index: number;

    for (let i = 0; i < this.billingMaster.length; i++) {
      if (this.billingMaster[i] == origBillable) {
        index = i;
        i = this.billingMaster.length;
      }
    }

    this.billingMaster[index] = updatedBillable;
  }

}
