import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, NavParams, IonInput } from '@ionic/angular';

@Component({
  selector: 'app-medicine-lookup-modal',
  templateUrl: './medicine-lookup-modal.page.html',
  styleUrls: ['./medicine-lookup-modal.page.scss'],
})
export class MedicineLookupModalPage implements OnInit {
  @ViewChild('newBrandInput', {  static: false }) brandInputEl: IonInput;
  brandNamesList: any[];
  medicineForm: FormGroup;
  submit: boolean = false;
  genericName: string;
  formMode: string;
  edit = "Edit";
  new = "New";

  constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController, private navParams: NavParams) { }

  ngOnInit() {
    this.brandNamesList = this.navParams.get('entryMatches') || [];
    this.checkDuplicates();
    this.formMode = !this.brandNamesList.length ? this.new : this.edit;
    this.genericName = this.brandNamesList.length ? this.brandNamesList[0]['entry'].generic : '';

    this.medicineForm = this.formBuilder.group({
      genericName: [this.genericName, [Validators.required]],
      brandNames: ['']
    });
  }

  onBrandChange({target: { value }}, index) {
    if (!value) {
      this.brandNamesList = this.brandNamesList.filter((b, i) => i != index);
      this.brandInputEl.setFocus();
    } else {
      if(this.brandNamesList[index].hasOwnProperty('index')) {
        this.brandNamesList[index]['entry'] = {
          generic: this.genericName,
          name: value
        };
      } else {
        this.brandNamesList[index]['name'] = value;
      }
    }
  }

  checkDuplicates(){
    if(this.formMode === this.new){
      console.log("checkDuplicates()", this.brandNamesList);
      this.brandNamesList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      console.log("checkDuplicates() sorted", this.brandNamesList);
      let past_entry = {name:"uncomparable"};
      let forDeletion = [];
      this.brandNamesList.forEach((entry, index)=>{
        this.brandNamesList[index]['name'] = this.brandNamesList[index]['name'].toUpperCase();
        if(past_entry.name.toUpperCase() == entry.name.toUpperCase()){
          console.log("Found duplicate", past_entry.name, entry.name);
          forDeletion.push(index);
        }
        Object.assign(past_entry, entry);
      });
      if(forDeletion.length > 0){
        forDeletion.sort((a, b)=>{return b-a});
        forDeletion.forEach(idx =>{
          console.log("splice", idx);
          this.brandNamesList.splice(idx,1);
        })
      }
    }else{
      console.log("checkDuplicates()", this.brandNamesList);
      this.brandNamesList.sort((a,b) => (a.entry.name > b.entry.name) ? 1 : ((b.entry.name > a.entry.name) ? -1 : 0));
      console.log("checkDuplicates() sorted", this.brandNamesList);
      let past_entry = {entry:{name:"uncomparable"}};
      let forDeletion = [];
      this.brandNamesList.forEach((entry, index)=>{
        this.brandNamesList[index]['entry']['name'] = this.brandNamesList[index]['entry']['name'].toUpperCase();
        if(past_entry.entry.name.toUpperCase() == entry.entry.name.toUpperCase()){
          console.log("Found duplicate", past_entry.entry.name, entry.entry.name);
          forDeletion.push(index);
        }
        Object.assign(past_entry, entry);
      });
      if(forDeletion.length > 0){
        forDeletion.sort((a, b)=>{return b-a});
        forDeletion.forEach(idx =>{
          console.log("splice", idx);
          this.brandNamesList.splice(idx,1);
        })
      }
    }

  }

  addBrandName() {
    if(this.medicineForm.value.brandNames) {
      const newEntry = this.formMode === this.new
        ? {
          generic: this.medicineForm.value.genericName,
          name: this.medicineForm.value.brandNames
        } : {
          index: -1,
          entry: {
            generic: this.medicineForm.value.genericName,
            name: this.medicineForm.value.brandNames
          }
        };

      this.brandNamesList = [
        newEntry,
        ...this.brandNamesList
      ];
      this.checkDuplicates();
      this.medicineForm.controls.brandNames.reset();
    }

  }

  close() {
    this.modalCtrl.dismiss();
  }

  removeBrandName(index) {
    this.brandNamesList = this.brandNamesList.filter((b, i) => i != index);
  }

  saveMedicine() {
    this.submit = true;

    let newEntries;

    if (this.formMode === this.edit) {
      newEntries = this.brandNamesList.map(({ entry: { name }, index }) => ({
        index,
        entry: {
          name,
          generic: this.medicineForm.value.genericName
        }
      }))
    } else {
      newEntries = this.brandNamesList.map(({ name }) => ({
        name,
        generic: this.medicineForm.value.genericName
      }))
    }
    this.modalCtrl.dismiss({newEntries});
  }

}
