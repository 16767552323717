import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
// my interfaces

import { Appointment } from '../appointment';
import { ClinicConfig } from '../config';
import { QueueInfo } from '../queue-info';

@Injectable({
  providedIn: 'root'
})
export class ClinicsApiProvider {

  queueInfo:QueueInfo = null;
  clinicHours:any=null;
  afsPath:string="";
  appointmentsCollection:AngularFirestoreCollection<any>;
  clinicInfo:ClinicConfig={
    clinicID:"",
    clinicName:"",
    doctor:[],
    staff:[],
    clinicHours:[],
    doctorHours:[],
    contactInfo:null,
    requiredVitals:[]
  };


  constructor(
    private afs: AngularFirestore) {

    console.log('Hello Clinics Provider');
    this.resetQueue();

  }

  // a clinic holds several collections. Set the path to the clinic

  setPath(clinicPath){
    if(clinicPath == '') return;
    this.afsPath = clinicPath;
    console.log("Clinic: setPath", clinicPath);
    return new Promise((resolve, reject)=>{
      this.appointmentsCollection = this.afs.collection<Appointment>(this.afsPath + '/appointments');
      console.log('Appointments path', clinicPath);
      let clinicDoc = this.afs.doc<ClinicConfig>(clinicPath);
      clinicDoc.valueChanges().pipe(take(1)).subscribe(clinicInfo=>{
        console.log("Clinic: clinicInfo", clinicInfo);
        //clinicDoc.unsubscribe();
        this.clinicInfo = clinicInfo;
        resolve(clinicInfo);
      }, err=>{
        console.log("Could not find clinic", clinicPath);
        reject(err);
      });
    });

  }

  // QUEUE Methods

  getQueue(queueID:string = ""):Observable<Appointment[]>{
    console.log("Clinic: getQueue()", queueID);
    if(queueID == ""){
      let appointmentCollection = this.afs.collection<Appointment>(this.afsPath + '/appointments/');
      return appointmentCollection.valueChanges();
    } else {
      let appointmentCollection = this.afs.collection<Appointment>(this.afsPath + '/appointments/', ref => ref.where('queueID', '==', queueID));
      return appointmentCollection.valueChanges();
    }
  }

  resetQueue(dateID = 0){
    var dateStr="";

    this.queueInfo = {
        queueID : dateID,
        queueDate : dateStr,
        queue: [] // array of appointments for a given queue.
    }

  }

  nextQueue() {
    return new Promise((resolve, reject)=>{
        //shift the day to plus one then get the queue
        resolve(this.queueInfo);
    })
  }

  prevQueue()  {
    return new Promise((resolve, reject)=>{
        //shift the day to less one then get the queue
        resolve(this.queueInfo);
    })
  }

  // APPOINTMENT Methods

  addAppointment(appointmentInfo):Promise<Appointment>{
    let appointmentID = appointmentInfo.appointmentID;

    console.log("Add Appointment", appointmentInfo);
    return new Promise((resolve, reject)=>{
      let appointmentDoc = this.afs.doc<Appointment>(this.afsPath + '/appointments/' + appointmentID);
      appointmentDoc.set(appointmentInfo).then(newAppointment=>{
        console.log("New appointment", newAppointment);
        resolve(appointmentInfo);
      }, err=>{
        reject(err);
        console.log("could not add new Appointment to the appointments");
      })
    })

  }

  getAppointmentByID(appointmentID):Observable<Appointment>{
    console.log("getAppointmentByID()", appointmentID);
    let appointmentDoc = this.afs.doc<Appointment>(this.afsPath + '/appointments/' + appointmentID);
    return appointmentDoc.valueChanges();
  }

  // update the information of a clinic
  updateAppointment(appointmentInfo:Appointment = null){
    if(appointmentInfo == null) return;
    console.log("Clinic: updateAppointment()", appointmentInfo)
    let appointmentDoc = this.afs.doc<Appointment>(this.afsPath + '/appointments/' + appointmentInfo.appointmentID);
    appointmentDoc.set(appointmentInfo).then(() =>{
      console.log("successfully update Appointment", appointmentInfo.appointmentID);
    }, err=>{
      console.log("Failed to update Queue Info", err);
    })
  }

  // delete appointment
  deleteAppointment(appointmentInfo:Appointment = null){
    if(appointmentInfo == null) return;
    console.log("Clinic: deleteAppointment()", appointmentInfo)
    let appointmentDoc = this.afs.doc<Appointment>(this.afsPath + '/appointments/' + appointmentInfo.appointmentID);
    appointmentDoc.delete().then(() =>{
      console.log("successfully delete Appointment", appointmentInfo.appointmentID);
    }, err=>{
      console.log("Failed to update Queue Info", err);
    })
  }

}
