import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Patient } from '../patient';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientProvider {
  private patients: Patient[];

  constructor() {
    console.log('Hello PatientProvider Provider');
    this.loadPatients();
  }

  loadPatients() {
    this.patients = [{
      patientID: '1',
      firstName: "John",
      lastName: "Doe"
    },
    {
      patientID: '2',
      firstName: "Johnny",
      lastName: "Appleseed"
    },
    {
      patientID: '3',
      firstName: "Jane",
      lastName: "Doe"
    }];
  }

  getPatientsArray(): Observable<Patient[]> {
    let data: Patient[] = this.patients;
    return of(data);
  }

  getPatient(patient: Patient): Observable<any> {
    let index: number;
    for (let i = 0; i < this.patients.length; i++) {
      if (this.patients[i] == patient) {
        index = i;
        i = this.patients.length;
      }
    }

    let data: any = this.patients[index];
    return of(data);
  }

  getPatientById(patientID: string): Observable<Patient> {
    let index: number;

    for (let i = 0; i < this.patients.length; i++) {
      if (this.patients[i].patientID == patientID) {
        index = i;
        i = this.patients.length;
      }
    }

    let data: Patient = this.patients[index]
    return of(data);
  }
}
