import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ToastController } from "@ionic/angular";
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountConfig, ClinicConfig } from '../config';
import { BillingInfo } from '../billing-info';

export interface CalendarEvent {
  id: string,
  title: string,
  allDay?: boolean,
  start: string,
  end: string,
  url?: string,
  className?: string,
  editable?: boolean,
  startEditable?: boolean,
  durationEditable?: boolean,
  resourceEditable?: boolean,
  rendering?: string,
  overlap?: boolean,
  constraint?: string,
  color?: string,
  backgroundColor?: string,
  borderColor?: string,
  textColor?: string
}

export interface Allergy {
  allergyID: string,
  description: string,
  icd10code: string,

}
export interface Comorbid {
  comorbidID: string,
  description: string,
  icd10code: string
}

// export interface HoursInfo{
//   id:number,
//   dow:Array<number>,
//   dayNames:Array<string>,
//   start:string,
//   end:string,
//   title:string,
// }
export interface BusinessHour {
  businessHourID: string,
  dow: number[],
  start: string,
  end: string,
  title?: string,
  dayNames?: string[]

}



@Injectable({
  providedIn: 'root'
})
export class ConfigProvider {

  accountNum: string = "1";
  accountInfo: AccountConfig = {
    allergies: [],
    billingMaster: [],
    comorbids: [],
    doctorPIN: "",
    doctorPRC: "",
    doctorPTR: "",
    doctorTIN: "",
    doctorName: "",
    doctorSpecialization: "",
    doctorAffiliation: [],
    doctorSuffix: "",
    doctorS2Num: "",
    doctorS2NumExpiry: "",
    formLookup: [],
    frequencyLookup: [],
    medicineLookup: [],
    requiredVitals: [],
    routeLookup: [],
    uomLookup: []
  };

  private clinicCollection: AngularFirestoreCollection<ClinicConfig>;
  myClinics: any = [];
  //clinicLists: Observable<ClinicConfig[]>;
  clinic: ClinicConfig = {
    clinicID: "",
    clinicName: "",
    doctor: [],
    staff: [],
    clinicHours: [],
    doctorHours: [],
    contactInfo: {
      line1: "",
      line2: "",
      barangay: "",
      city: "",
      province: "",
      zipcode: "",
      email: "",
      cellphone: "",
      telephone: "",
      fax: ""
    },
    requiredVitals: [],
    hisid: ""
  }

  pinLoggedIn: boolean = false;
  pinLoginUser: any = { name: "", role: "", userID: "" };
  pinLoginUserType: string = "";
  pinPreset: string = "";

  constructor(
    private storage: Storage,
    private afs: AngularFirestore,
    private alertCtrl: ToastController,
    public events: Events) {
    console.log('Created ConfigProvider', this.clinic);

  }

  setConfig(accountNum) {
    console.log("Config: setConfig()", accountNum);
    return new Promise((resolve, reject) => {
      this.accountNum = accountNum;
      this.clinicCollection = this.afs.collection<ClinicConfig>('accounts/' + this.accountNum + '/clinics', ref => ref.orderBy('sequence'));
      this.clinicCollection.valueChanges().subscribe(listClinics => {
        console.log("setConfig: Got Clinics", listClinics);
        this.myClinics = [];
        listClinics.forEach(clinic => {
          this.myClinics.push(clinic);
          //console.log("adding clinic", clinic.clinicName, clinic.clinicID);
        })
        resolve({ result: "ok" });
      }, err => {
        console.log("setConfig: could not get clinics");
        reject({ result: "no clinics" });
      });
      //this.clinicLists = this.clinicCollection.valueChanges();
    });

  }

  async showWizardAlert() {
    let alert = await this.alertCtrl.create({
      header: 'Welcome to VersaClinic!',
      message: 'It is recommended that you run the setup wizard which will take you through the various initial settings to setup the clinic',
      color: 'light',
      showCloseButton: true,
      duration:5000
    });
    if(this.accountInfo.wizardRun !== undefined && this.accountInfo.wizardRun == 1 ) alert.present

  }

  saveWizardRun() {
    console.log("saveWizardRun", this.accountInfo.wizardRun);
    if(this.accountInfo.wizardRun == 0){
      this.accountInfo.wizardRun = 1;
      this.updateAccount();
    }
  }

  retrieveWizardRun() {
    return new Promise((resolve, reject) => {
      this.storage.get('THETIS_WIZARD_RUN')
        .then(
          result => {
            if (result !== null)
              resolve(result[this.accountNum]);
            else
              resolve(result);
          },
          err => reject(err)
        )
    })
  }

  /**** ACCOUNT METHODS ****/

  resetAccount() {
    console.log("Config: resetAccount", this.accountNum);
    return new Promise((resolve, reject) => {
      // get reference values from account 1
      let accountDoc = this.afs.doc<AccountConfig>('accounts/1');
      accountDoc.valueChanges().pipe(take(1)).subscribe(accountRef => {
        console.log("Got reference", accountRef);
        if (accountRef) {
          this.accountInfo = {
            allergies: accountRef.allergies,
            billingMaster: accountRef.billingMaster,
            comorbids: accountRef.comorbids,
            doctorPIN: this.pinPreset,
            doctorPRC: "",
            doctorPTR: "",
            doctorTIN: "",
            doctorName: "",
            doctorSpecialization: "",
            doctorAffiliation: [],
            doctorSuffix: "",
            doctorS2Num: "",
            doctorS2NumExpiry: "",
            formLookup: accountRef.formLookup,
            frequencyLookup: accountRef.frequencyLookup,
            medicineLookup:[
              {generic:'Ascorbic Acid 500mg', name:'Cee-Protect'},
              {generic:'Ascorbic Acid 500mg', name:'Nutricee'},
              {generic:'Paracetamol 500mg', name:'Biogesic'},
              {generic:'Paracetamol 500mg', name:'Zytamol'},
              {generic:'Amoxicillin 500mg', name:'Amoxil'},
            ],
            requiredVitals: ['Weight','Blood Pressure'],
            routeLookup: accountRef.routeLookup,
            uomLookup: accountRef.uomLookup,
            reportTemplates: []
          };
          this.resetReports();
          resolve(this.accountInfo);
        } else {
          reject({ result: 'error', message: 'could not get initial accountRef' });
        }

      }, err => {
        console.log("Config: error in getting the reference lookups");
        reject(err);
      });
    });

  }

  // set the account Info back to its initialized state
  blankAccount() {
    this.accountInfo = {
      allergies: [],
      billingMaster: [],
      comorbids: [],
      doctorPIN: "",
      doctorPRC: "",
      doctorPTR: "",
      doctorTIN: "",
      doctorName: "",
      doctorSpecialization: "",
      doctorAffiliation: [],
      doctorSuffix: "",
      doctorS2Num: "",
      doctorS2NumExpiry: "",
      formLookup: [],
      frequencyLookup: [],
      medicineLookup: [
        {generic:'Ascorbic Acid 500mg', name:'Cee-Protect'},
        {generic:'Ascorbic Acid 500mg', name:'Nutricee'},
        {generic:'Paracetamol 500mg', name:'Biogesic'},
        {generic:'Paracetamol 500mg', name:'Zytamol'},
        {generic:'Amoxicillin 500mg', name:'Amoxil'},
      ],
      requiredVitals: [],
      routeLookup: [],
      uomLookup: [],
      reportTemplates: []
    };

    this.clinic = {
      clinicID: "",
      clinicName: "",
      doctor: [],
      staff: [],
      clinicHours: [{
        businessHourID: "1569999133514",
        dayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        dow: ['1', '2', '3', '4', '5'],
        start: "08:00",
        end: "17:00"
      }],
      doctorHours: [],
      contactInfo: {
        line1: "",
        line2: "",
        barangay: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        cellphone: "",
        telephone: "",
        fax: "",
      },
      requiredVitals: ['Weight','Blood Pressure']
    };
    this.accountNum = "0";
  }
  // save the current config to database/Firebase

  saveAccount() {
    console.log("Saving Account")
    if (this.accountInfo == null) {
      this.addAccount(this.accountInfo);
    } else {
      this.updateAccount(this.accountInfo);
    }
  }

  // add a clinic

  addAccount(accountInfo: AccountConfig) {
    console.log("Config: addAccount()", accountInfo, this.accountNum);
    // create a blank account, but using the account reference lookups
    if (accountInfo == null) accountInfo = this.accountInfo;
    let accountDoc = this.afs.doc<AccountConfig>('accounts/' + this.accountNum);
    accountDoc.set(accountInfo).then(newAccount => {
      console.log("New Account", newAccount);
    }, err => {
      console.log("could not add new Account");
    });

  }

  // update the information of an account

  updateAccount(accountInfo: AccountConfig = null) {
    if (accountInfo == null) accountInfo = this.accountInfo;
    console.log("Config: updateAccount()", this.accountNum, accountInfo);
    let accountDoc = this.afs.doc<AccountConfig>('accounts/' + this.accountNum);
    return new Promise((resolve, reject) => {
      accountDoc.update(accountInfo).then(() => {
        console.log("Config: account updated", this.accountInfo, this.clinic);
        resolve("ok");
      }, err => {
        console.log("Config: could not update account");
        reject("no ok");
      });
    });

  }

  // modify fields on account
  mergeAccountInfo(params){
    if(this.accountNum != '1'){
      Object.assign(this.accountInfo, params);
    }
  }

  // select a clinic and set the form to this clinic

  selectAccount(accountNum) {
    console.log("Config: selectAccount()", accountNum);
    this.accountNum = accountNum;
    return new Promise((resolve, reject) => {
      let accountDoc = this.afs.doc<AccountConfig>('accounts/' + accountNum);
      accountDoc.valueChanges().subscribe(accountInfo => {
        console.log("Config: Account Info", accountInfo);
        if (accountInfo == undefined) {
          this.resetAccount().then(sameInfo => {
            this.addAccount(this.accountInfo);
            resolve(true);
          }, err => {
            console.log("Was unable to reset this account", accountNum);
          });
        } else {
          console.log("setting this.accountInfo");
          this.accountInfo = accountInfo;
          //let accountDoc = this.afs.doc<AccountConfig>('accounts/1');
          // accountDoc.valueChanges().pipe(take(1)).subscribe(accountRef=>{
          //   console.log("Got reference", accountRef);
          //   console.log("Merge medicineLookup", accountRef.medicineLookup);
          //   this.accountInfo.medicineLookup = this.accountInfo.medicineLookup.concat(accountRef.medicineLookup);
          //   //this.accountInfo.medicineLookup = accountRef.medicineLookup;
          //   console.log("Merged medicineLookup", this.accountInfo.medicineLookup);
          //   this.saveAccount();
          // }, err=>{
          //   console.log("Error while getting reference Account");
          // });
          if (this.accountInfo.reportTemplates == undefined) {
            this.resetReports();
          }
          resolve(true);
        }
      }, err => {
        console.log("Error in selecting account", err);
        reject(err);
      });

    });

  }

  // resetClinic to zeroDefaultValues

  resetClinic() {
    this.clinic = {
      clinicID: "",
      clinicName: "Your Clinic " + (this.myClinics.length + 1),
      doctor: [],
      staff: [],
      staffPermissions : ' manage schedule manage records manage emr scan update billing',
      clinicHours: [{
        businessHourID: "1569999133514",
        dayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        dow: ['1', '2', '3', '4', '5'],
        start: "08:00",
        end: "17:00"
      }],
      doctorHours: [],
      contactInfo: {
        line1: "",
        line2: "",
        barangay: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        cellphone: "",
        telephone: "",
        fax: ""
      },
      requiredVitals: ['Blood Pressure', 'Weight'],
      sequence: 100,
    }
  }

  // save the current config to database/Firebase

  saveConfig() {
    console.log("Saving Config", this.clinic.clinicID)
    if (this.clinic.clinicID == "") {
      this.addClinic(this.clinic);
    } else {
      this.updateClinic(this.clinic);
    }
  }

  // set the default clinic
  setDefaultClinic(clinicID = null) {
    console.log("Config: setDefaultClinic()", clinicID);
    return new Promise((resolve, reject) => {
      // see if we save a clinicID in storage
      this.storage.get("THETIS_DEFAULT_CLINIC").then(clinicID => {
        console.log("setDefaultClinic: Storage Clinic", clinicID);
        if (clinicID != null) {
          this.selectClinic(clinicID).then(result => {
            resolve(result);
          }, err => {
            reject(err);
          })
        } else {
          // just get the first available clinic
          // for doctors
          // for staff, get the clinic that they are assigned to
          if (this.myClinics.length == 0) {
            this.resetClinic();
            this.addClinic(this.clinic).then(result => {
              console.log("Created new clinic as default_clinic");
              this.events.publish('config:selectClinic', clinicID);
              resolve(result);
            }, err => {
              reject(err);
            });
          } else {
            this.clinic = this.myClinics[0];
            console.log("selected clinic", this.clinic, this.myClinics.length);
            this.events.publish('config:selectClinic', clinicID);
            resolve(true);
          }
          // this.clinicCollection = this.afs.collection<ClinicConfig>('accounts/' + this.accountNum + '/clinics', ref => ref.orderBy('sequence'));
          // myClinics = this.clinicCollection.valueChanges();
          // myClinics.pipe(take(1)).subscribe(listClinics => {
          //   console.log("setDefaultClinic: Got Clinics", listClinics);
          //   if (listClinics.length < 1) {
          //     this.resetClinic();
          //     this.addClinic(this.clinic).then(result => {
          //       resolve(result);
          //     }, err => {
          //       reject(err);
          //     });
          //   } else {
          //     if (this.clinic.clinicID == "")
          //       this.clinic = listClinics[0];
          //     console.log("selected clinic", this.clinic);
          //     resolve(true);
          //   }
          // }, err => {
          //   console.log("setDefaultClinic: could not get clinics");
          //   reject(false);
          // });
        }
      }, err => {
        console.log("setDefaultClinic: Could not get storage value", err);
        reject(err);
      })
    });
  }

  // add a clinic

  addClinic(clinicInfo: ClinicConfig = null) {
    console.log("Add Clinic Info", clinicInfo);
    if (clinicInfo == null) {
      this.resetClinic();
      clinicInfo = this.clinic;
    }
    console.log("Add Clinic Info", clinicInfo);
    return new Promise((resolve, reject) => {
      this.clinicCollection.add(clinicInfo).then(newClinic => {
        console.log("New Clinic", newClinic);
        this.clinic.clinicID = newClinic.id;
        this.updateClinic(this.clinic);
        resolve(clinicInfo);
      }, err => {
        console.log("could not add new Clinic");
        reject(err);
      })
    })

  }
  // update the information of a clinic
  updateClinic(clinicInfo = null) {
    if (clinicInfo == null) clinicInfo = this.clinic;
    if (clinicInfo.clinicID == "") return this.addClinic(clinicInfo);
    console.log("Update Clinic Info", clinicInfo)
    let clinicDoc = this.afs.doc<ClinicConfig>('accounts/' + this.accountNum + '/clinics/' + clinicInfo.clinicID);
    return new Promise((resolve, reject) => {
      clinicDoc.update(clinicInfo).then(() => {
        resolve("ok");
      }, err => {
        reject(err);
      })
    })
  }
  // update the information of a clinic
  saveClinic(clinicInfo = null) {
    if (clinicInfo == null) clinicInfo = this.clinic;
    if (clinicInfo.clinicID == "") return this.addClinic(clinicInfo);
    console.log("Update Clinic Info", clinicInfo)
    let clinicDoc = this.afs.doc<ClinicConfig>('accounts/' + this.accountNum + '/clinics/' + clinicInfo.clinicID);
    return new Promise((resolve, reject) => {
      clinicDoc.update(clinicInfo).then(() => {
        resolve("ok");
      }, err => {
        reject(err);
      })
    })
  }

  // update the information of a clinic
  deleteClinic(clinicInfo = null) {
    if (clinicInfo == null) clinicInfo = this.clinic;
    if (clinicInfo.clinicID == "") return;
    console.log("Delete Clinic Info", clinicInfo)
    let clinicPath = 'accounts/' + this.accountNum + '/clinics/' + clinicInfo.clinicID;
    let clinicDoc = this.afs.doc<ClinicConfig>('accounts/' + this.accountNum + '/clinics/' + clinicInfo.clinicID);
    let clinicRecordsCollection = this.afs.collection(clinicPath + '/records');
    let clinicBillingsCollection = this.afs.collection(clinicPath + '/billings');
    let clinicAppointmentsCollection = this.afs.collection(clinicPath + '/appointments');
    // clinicRecordsCollection.delete().then(_ => { console.log("records deleted") }, err => { console.log("error while deleting records") });
    // clinicBillingsCollection.delete().then(_ => { console.log("billings deleted") }, err => { console.log("error while deleting billings") });
    // clinicAppointmentsCollection.delete().then(_ => { console.log("appointments deleted") }, err => { console.log("error while deleting appointments") });
    this.storage.remove('THETIS_DEFAULT_CLINIC').then(_ => { console.log("removed THETIS_DEFAULT_CLINIC") }, err => { console.log("error while deleting THETIS_DEFAULT_CLINIC") });
    return new Promise((resolve, reject) => {
      clinicDoc.delete().then(() => {
        this.setDefaultClinic().then(_ => {
          console.log("setting default_clinic")
          resolve("ok");
        }, err => {
          console.log("error while setting default_clinic")
        });

      }, err => {
        reject(err);
      })
    })
  }

  // select a clinic and set the form to this clinic

  selectClinic(clinicID) {
    console.log("Config: selectClinic()", clinicID);
    return new Promise((resolve, reject) => {
      let clinicDoc = this.afs.doc<ClinicConfig>('accounts/' + this.accountNum + '/clinics/' + clinicID);
      clinicDoc.valueChanges().pipe(take(1)).subscribe(clinicInfo => {
        console.log("Clinic Info", clinicInfo);
        if (clinicInfo == undefined) {
          this.resetClinic();
        } else {
          this.clinic = clinicInfo;
          this.storage.set("THETIS_DEFAULT_CLINIC", clinicInfo.clinicID).then(clinicSet => {
            console.log("THETIS_DEFAULT_CLINIC was set", clinicInfo.clinicID);
          }, err => {
            console.log("Error while setting THETIS_DEFAULT_CLINIC");
          })
        }
        this.events.publish('config:selectClinic', clinicID);
        resolve(clinicInfo);
      }, err => {
        console.log("Could not find clinic", clinicID);
        reject(err);
      });
    });


  }


  // STAFF Management functions

  checkClinicPermission(policy){
    console.log("checkClinicPermission", policy)
    if(this.clinic.staffPermissions === undefined){
        this.clinic.staffPermissions = ' manage schedule manage records manage emr scan update billing';
    }
    if(this.clinic.staffPermissions.indexOf(policy) >=0 ){
      return true;
    }else{
      return false;
    }
  }

  blankStaff() {
    let newStaff = {
      name: "",
      username: "",
      password: "",
      userID: 0,
      role: "",
      userType: "staff",
      activeStatus: true
    }
    return newStaff;
  }

  // reportManagementFunctions
  resetReports() {
    console.log("initReports()")
    let initReports = [
      {
        reportID: 'medcert',
        header: 'Medical Certificate',
        displayType: 'text',
        sections: [
          {
            key: "impression",
            title: "Impression",
            display: "Impression",
            placeholder: "type here",
            value: "",
            rows: 2
          }, {
            key: "recommendation",
            title: "Recommendation",
            display: "Recommendation",
            placeholder: "type here",
            value: "",
            rows: 3
          },
          {
            key: "remarks",
            title: "Remarks",
            display: "Remarks",
            placeholder: "type here",
            value: "",
            rows: 3
          }
        ],
        standard: true,
        withClinicAddress: true,
        withClinics: false,
        withPatientInfo: true,
        withRx: false,
        withSignature: true,
        withHeaderLine: false,
        showSectionsInVisits: false
      }, {
        reportID: 'abstract',
        header: 'Medical Abstract',
        displayType: 'text',
        sections: [
          {
            key: "abstract",
            title: "Abstract",
            display: "",
            placeholder: "compose abstract here",
            value: "",
            rows: 10
          }
        ],
        standard: true,
        withClinicAddress: true,
        withClinics: false,
        withPatientInfo: true,
        withRx: false,
        withSignature: true,
        withHeaderLine: true,
        showSectionsInVisits: false
      }, {
        reportID: 'procedures',
        header: 'Ancilliary Procedures',
        displayType: 'text',
        sections: [
          {
            key: "procedures",
            title: "Procedures",
            display: "",
            placeholder: "list required procedures here",
            value: "",
            rows: 10
          }
        ],
        standard: true,
        withClinicAddress: true,
        withClinics: false,
        withPatientInfo: true,
        withRx: true,
        withSignature: true,
        withHeaderLine: true,
        showSectionsInVisits: true
      }, {
        reportID: 'labs',
        header: 'Lab Request',
        displayType: 'checkboxes',
        sections: [
          {
            key: "lab1",
            title: "CBC",
            display: "CBC",
            placeholder: "",
            value: false,
            rows: 1
          },
          {
            key: "lab2",
            title: "Ultrasound",
            display: "Ultrasound",
            placeholder: "",
            value: false,
            rows: 1
          },
          {
            key: "lab3",
            title: "Urinalysis",
            display: "Urinalysis",
            placeholder: "",
            value: false,
            rows: 1
          },
          {
            key: "lab4",
            title: "X-Ray",
            display: "X-Ray",
            placeholder: "",
            value: false,
            rows: 1
          }
        ],
        standard: true,
        withClinicAddress: true,
        withClinics: false,
        withPatientInfo: true,
        withRx: true,
        withSignature: true,
        withHeaderLine: true,
        showSectionsInVisits: true
      }, {
        reportID: 'referral',
        header: 'Doctor Referral',
        displayType: 'text',
        sections: [
          {
            key: "doctor",
            title: "Name of Doctor",
            display: "",
            placeholder: "name and address",
            value: "",
            rows: 2
          }, {
            key: "reason",
            title: "Reason",
            display: "Reason",
            placeholder: "reason for referral",
            value: "",
            rows: 1
          }, {
            key: "notes",
            title: "Notes",
            display: "Notes",
            placeholder: "additional notes",
            value: "",
            rows: 5
          }
        ],
        standard: true,
        withClinicAddress: true,
        withClinics: false,
        withPatientInfo: true,
        withRx: true,
        withSignature: true,
        withHeaderLine: true,
        showSectionsInVisits: true
      }
    ];
    this.accountInfo.reportTemplates = initReports;
    return initReports;
  }
  // firebase reference functions

  getPath() {
    // for testing
    return new Promise((resolve, reject) => {
      if (this.clinic.clinicID == '') {
        this.setDefaultClinic().then(result => {
          let afsPath = "accounts/" + this.accountNum + '/clinics/' + this.clinic.clinicID;
          resolve(afsPath);
        }, err => {
          reject("");
        });
      } else {
        let afsPath = "accounts/" + this.accountNum + '/clinics/' + this.clinic.clinicID;
        resolve(afsPath);
      }
    });
  }

  // return path for the account

  getAccountPath() {
    // for testing
    return new Promise((resolve, reject) => {
      if (this.clinic.clinicID == '') {
        this.setDefaultClinic().then(result => {
          let afsPath = "accounts/" + this.accountNum;
          resolve(afsPath);
        }, err => {
          reject("");
        });
      } else {
        let afsPath = "accounts/" + this.accountNum;
        resolve(afsPath);
      }
    });
  }


  getClinicPath() {
    console.log("Config: getClinicPath()", this.clinic)
    return new Promise((resolve, reject) => {
      if (this.clinic == undefined || this.clinic.clinicID == '') {
        console.log("getClinicPath(): setting default clinic path");
        this.setDefaultClinic().then(result => {
          let afsPath = "accounts/" + this.accountNum + '/clinics/' + this.clinic.clinicID;
          resolve(afsPath);
        }, err => {
          console.log("getClinicPath(): error", err);
          reject("");
        });
      } else {
        let afsPath = "accounts/" + this.accountNum + '/clinics/' + this.clinic.clinicID;
        console.log("getClinicPath(): setting with clinicID");
        resolve(afsPath);
      }
    });
  }

  // BILLINGS methods
  getChargeSlips(startdate, enddate, patientID = null): Promise<BillingInfo[]> {
    let billingCollection;
    console.log("getChargeSlips", startdate, enddate, patientID);
    return new Promise((resolve, reject) => {
      let afsPath = "accounts/" + this.accountNum + '/clinics/' + this.clinic.clinicID;
      billingCollection = this.afs.collection<BillingInfo>(afsPath + '/billings', ref => ref.where('billingID', '>=', startdate).where('billingID', '<=', enddate).orderBy('billingID'));

      // for some reason, querying with start and end date with patientID does not work so right now filtering of patientID is done in the frontend
      /*
      if (patientID == null)
        billingCollection = this.afs.collection<BillingInfo>(afsPath + '/billings', ref => ref.where('billingID', '>=', startdate).where('billingID', '<=', enddate).orderBy('billingID'));
      else
        billingCollection = this.afs.collection<BillingInfo>(afsPath + '/billings', ref => ref.where('billingID', '>=', startdate).where('billingID', '<=', enddate).where('patientID', '==', patientID).orderBy('billingID'));
      */

      billingCollection.valueChanges().pipe(take(1)).subscribe(billings => {
        resolve(billings);
      }, err => {
        reject([])
      })
    });
  }

  // SCHEDULE Methods

  addSchedule(hoursInfo) {
    this.clinic.clinicHours.push(hoursInfo);
    this.updateClinic();

  }

  deleteSchedule(hoursInfo, index) {

    let removedHours = this.clinic.clinicHours.splice(index, 1);
    this.updateClinic();
    return removedHours;

  }

  // PIN Methods

  directLoginDoctor() {
    return new Promise((resolve, reject) => {
      console.log("Config: directLoginDoctor", this.accountInfo.doctorPIN);
      this.checkPin(this.accountInfo.doctorPIN).then(result => {
        console.log("Doctor is logged In", result)
        resolve(result);
      }, err => {
        console.log("Doctor not logged In", err);
        reject(err);
      });
    });
  }

  setPIN(accountID = "", pin = "") {
    console.log("Config: setPIN()", accountID);
    return new Promise((resolve, reject) => {
      if (accountID == " ") {
        reject({ error: "no account ID" });
      } else {
        let accountDoc = this.afs.doc<AccountConfig>('accounts/' + accountID);
        let accountInfo = {
          doctorPIN: pin
        }
        accountDoc.update(accountInfo).then(() => {
          console.log("Config: account PIN set", accountID);
          resolve("ok");
        }, err => {
          console.log("Config: could not set PIN", err);
          reject("not ok");
        });
      }
    });

  }

  checkPin(pinCode) {
    // use current AccountInfo to check accountInfo
    let accountDoc = this.afs.doc<any>('accounts/' + this.accountNum);
    // use current clinic to check PIN
    //let clinicDoc = this.afs.doc<ClinicConfig>('accounts/'+ this.accountNum + '/clinics/' + this.clinic.clinicID);
    return new Promise((resolve, reject) => {
      accountDoc.valueChanges().pipe(take(1)).subscribe(account => {
        console.log("Checking vs ", account, this.accountNum, pinCode);
        if (account) {
          if (account.doctorPIN == pinCode) {
            this.pinLoggedIn = true;
            this.pinLoginUserType = 'doctor';
            this.pinLoginUser = { name: account.doctorName, role: 'Full User', userID: this.accountNum };
            this.storage.set('THETIS_LOGGED_IN_USER', JSON.stringify({
              pinLoggedIn: this.pinLoggedIn,
              pinLoginUserType: this.pinLoginUserType,
              pinLoginUser: this.pinLoginUser
            })).then(result => {
              this.events.publish('user:pinLogon', null);
              resolve(true);
            }, err => {
              console.log("Error  while saving LOGGED_IN_USER", err);
              reject(err);
            });
          } else {
            var i = 0;
            var notFound = true;
            var staff = null;
            if (this.clinic) {
              staff = this.clinic.staff;
              pinCode= '' + pinCode;
              while (notFound && i < staff.length) {
                console.log("Checking vs ", staff[i].staffPIN, pinCode);
                let comparePIN = "" + staff[i].staffPIN;
                if(staff[i].activeStatus == undefined) staff[i].activeStatus = true;
                if (staff[i].activeStatus == true && comparePIN == pinCode) {
                  notFound = false;
                  this.pinLoggedIn = true;
                  this.pinLoginUserType = 'staff';
                  this.pinLoginUser = { name: staff[i].name, role: staff[i].role, userID: staff[i].userID };
                  this.storage.set('THETIS_LOGGED_IN_USER', JSON.stringify({
                    pinLoggedIn: this.pinLoggedIn,
                    pinLoginUserType: this.pinLoginUserType,
                    pinLoginUser: this.pinLoginUser
                  })).then(result => {
                    console.log("saved LOGGED_IN_USER", result);
                    this.events.publish('user:pinLogon', null);
                    resolve(true);
                  }, err => {
                    console.log("Error  while saving LOGGED_IN_USER", err);
                    reject(err);
                  });
                }
                i++;
              };
              if(notFound){
                console.log("did not compare with any user", notFound, i);
                reject(false);
              }
            }
          }
        } else {
          reject(false);
        }
      }, err => {
        reject(err);
      });
      // check for Doctor PIN
      // if Doctor PIN matched then resolve
      // check for Staff PIN based on Clinic
      // if staff PIN matched then resolve
    });
  }

  pinLogoff() {
    this.pinLoggedIn = false;
    this.pinLoginUserType = "";
    this.pinLoginUser = { name: "", role: "", userID: "" };
    this.storage.set('THETIS_LOGGED_IN_USER', JSON.stringify({
      pinLoggedIn: this.pinLoggedIn,
      pinLoginUserType: this.pinLoginUserType,
      pinLoginUser: this.pinLoginUser
    })).then(result => {
      console.log("Blanked out LOGGED_IN_USER");
      this.events.publish('user:pinLogoff', null);
    }, err => {
      console.log("Error  while saving LOGGED_IN_USER", err);
    }
    );
  }

  reloadPINLoginStatus() {
    console.log("Config: reloadPINLoginStatus");
    return new Promise((resolve, reject) => {
      this.storage.get('THETIS_LOGGED_IN_USER').then(loginJSON => {
        if (loginJSON) {
          let logInfo = JSON.parse(loginJSON);
          console.log("Login Info", logInfo);
          this.pinLoggedIn = logInfo.pinLoggedIn;
          this.pinLoginUserType = logInfo.pinLoginUserType;
          this.pinLoginUser = logInfo.pinLoginUser;
          this.events.publish('user:pinLogon', null);
          resolve(true);
        } else {
          this.pinLoggedIn = false;
          this.pinLoginUserType = "";
          this.pinLoginUser = { name: "", role: "", userID: "" };
          resolve(true);
        }

      }, err => {
        this.pinLoggedIn = false;
        this.pinLoginUserType = "";
        this.pinLoginUser = { name: "", role: "", userID: "" };
        resolve(true);
      });
    })
  }

  signOut() {
    this.pinLogoff();
    // remove saved variables
    this.blankAccount();
    return new Promise((resolve, reject) => {
      this.storage.remove('THETIS_DEFAULT_CLINIC').then(result => {
        // result is undefined
        console.log("Cleared DEFAULT_CLINIC", this.accountInfo, this.clinic);
        resolve("OK");
      }, err => {
        console.log("Error in clearing DEFAULT_CLINIC", err);
        reject({ error: err, result: "could not clear default_clinic" })
      });
    });


  }



}
