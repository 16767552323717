import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.page.html',
  styleUrls: ['./content-modal.page.scss'],
})
export class ContentModalPage implements OnInit {
    @ViewChild('contentBox', {static:false}) contentBox;
    @Input() title:string;
    @Input() contentRef:any;

    constructor(
      public modalCtrl: ModalController) {
    }

    ngOnInit() {
      console.log('ContentModalPage', this.title, this.contentRef);
      setTimeout(() => {
        this.contentBox.setFocus();
      },500);

    }


    ionViewWillLeave(){
      // let data = {content:this.contentRef};
      // this.viewCtrl.dismiss(data);
    }

    saveContent(){
      let data = {content:this.contentRef};
      this.modalCtrl.dismiss(data);
    }
    cancelEdit(){
      let data = {content:null};
      this.modalCtrl.dismiss(data);
    }

  }
