import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.page.html',
  styleUrls: ['./record-list.page.scss'],
})

export class RecordListPage implements OnInit {

  listReference:Array<any>=[];
  listTitle:string="";
  listInstructions:string="";
  newEntry:any;
  didClose:boolean=false;
  entryType:string="single";

  constructor(
    public navParams: NavParams,
    private modalCtrl: ModalController) {
    this.listReference = this.navParams.get('listReference');
    this.listTitle = this.navParams.get('title');
    this.listInstructions = this.navParams.get('instructions');
    if(navParams.get('entryType')){
      this.entryType = navParams.get('entryType');
      switch(this.entryType){
        case 'referral':
          this.newEntry = {
            doctor:"",
            reason:""
          }
          break;
        default:
          this.newEntry = "";
      }
    }
  }


  ngOnInit() {

  }

  deleteEntry(i){
    this.listReference.splice(i, 1);
  }
  addEntry(){
    this.listReference.push(this.newEntry);
    switch(this.entryType){
      case 'referral':
        this.newEntry = {
          doctor:"",
          reason:""
        }
        break;
      default:
        this.newEntry = "";
    }
  }
  returnList(){
    this.didClose = true;
    this.modalCtrl.dismiss( {result:"Closed", listReference: this.listReference});
  }
}
