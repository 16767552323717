import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuPage } from './menu/menu.page';

const routes: Routes = [
  {
  path: '',
  redirectTo: 'landing',
  pathMatch: 'full'
  },
  { path: 'add-billing-detail', loadChildren: './add-billing-detail/add-billing-detail.module#AddBillingDetailPageModule' },
  { path: 'appointment', loadChildren: './appointment/appointment.module#AppointmentPageModule' },
  { path: 'billable-modal', loadChildren: './billable-modal/billable-modal.module#BillableModalPageModule' },
  { path: 'billing', loadChildren: './billing/billing.module#BillingPageModule' },
  { path: 'calendar', loadChildren: './calendar/calendar.module#CalendarPageModule' },
  { path: 'clinic', loadChildren: './clinic/clinic.module#ClinicPageModule' },
  { path: 'content-modal', loadChildren: './content-modal/content-modal.module#ContentModalPageModule' },
  { path: 'edit-billable', loadChildren: './edit-billable/edit-billable.module#EditBillablePageModule' },
  { path: 'edit-business-hours', loadChildren: './edit-business-hours/edit-business-hours.module#EditBusinessHoursPageModule' },
  { path: 'edit-event', loadChildren: './edit-event/edit-event.module#EditEventPageModule' },
  { path: 'encounter', loadChildren: './encounter/encounter.module#EncounterPageModule' },
  { path: 'generate-reports', loadChildren: './generate-reports/generate-reports.module#GenerateReportsPageModule' },
  { path: 'layout', loadChildren: './layout/layout.module#LayoutPageModule' },
  { path: 'loading', loadChildren: './loading/loading.module#LoadingPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'medical-certificate-modal', loadChildren: './medical-certificate-modal/medical-certificate-modal.module#MedicalCertificateModalPageModule' },
  { path: 'new-charge-slip', loadChildren: './new-charge-slip/new-charge-slip.module#NewChargeSlipPageModule' },
  { path: 'patient-modal', loadChildren: './patient-modal/patient-modal.module#PatientModalPageModule' },
  { path: 'prescription-form', loadChildren: './prescription-form/prescription-form.module#PrescriptionFormPageModule' },
  { path: 'record-form', loadChildren: './record-form/record-form.module#RecordFormPageModule' },
  { path: 'record-list', loadChildren: './record-list/record-list.module#RecordListPageModule' },
  { path: 'record-search', loadChildren: './record-search/record-search.module#RecordSearchPageModule' },
  { path: 'record-vitals', loadChildren: './record-vitals/record-vitals.module#RecordVitalsPageModule' },
  { path: 'records', loadChildren: './records/records.module#RecordsPageModule' },
  { path: 'reports', loadChildren: './reports/reports.module#ReportsPageModule' },
  { path: 'report-form', loadChildren: './report-form/report-form.module#ReportFormPageModule' },
  { path: 'report-modal', loadChildren: './report-modal/report-modal.module#ReportModalPageModule' },
  { path: 'scan-image', loadChildren: './scan-image/scan-image.module#ScanImagePageModule' },
  { path: 'scan-profile', loadChildren: './scan-profile/scan-profile.module#ScanProfilePageModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' },
  { path: 'settings-lookup', loadChildren: './settings-lookup/settings-lookup.module#SettingsLookupPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'view-billable', loadChildren: './view-billable/view-billable.module#ViewBillablePageModule' },
  { path: 'view-business-hours', loadChildren: './view-business-hours/view-business-hours.module#ViewBusinessHoursPageModule' },
  { path: 'view-event', loadChildren: './view-event/view-event.module#ViewEventPageModule' },
  { path: 'view-patient-queue', loadChildren: './view-patient-queue/view-patient-queue.module#ViewPatientQueuePageModule' },
  { path: 'wizard', loadChildren: './wizard/wizard.module#WizardPageModule' },
  { path: 'image-modal', loadChildren: './image-modal/image-modal.module#ImageModalPageModule' },
  { path: 'medicine-lookup-modal', loadChildren: './medicine-lookup-modal/medicine-lookup-modal.module#MedicineLookupModalPageModule' },  { path: 'tele-script', loadChildren: './tele-script/tele-script.module#TeleScriptPageModule' },
  { path: 'landing', loadChildren: './landing/landing.module#LandingPageModule' },
  { path: 'help-template', loadChildren: './help-template/help-template.module#HelpTemplatePageModule' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
