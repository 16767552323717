import { Inject, Injectable } from '@angular/core';
//import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { ConfigProvider } from "./config.service";
import { take, filter } from 'rxjs/operators';

import { Billable } from '../billable';

@Injectable({
  providedIn: 'root'
})
export class LookupServiceProvider {

  // Firestore info
  configInfo: any = {};

  ///////// lookup tables
  lookup: Array<any> = null;

  requiredVitals: Array<string> = null;
  // record lookup
  allergies: Array<string> = null;
  comorbids: Array<string> = null;

  // prescription
  medicineLookup: Array<any> = null;
  uomLookup: Array<string> = null;
  formLookup: Array<string> = null;
  frequencyLookup: Array<any> = null;
  routeLookup: Array<any> = null;

  /// billing lookup
  billingMaster: Array<Billable> = [];

  // updateCtr;
  changeCtr:number=0;

  constructor(
    private configApi: ConfigProvider) {
    console.log('Hello LookupServiceProvider Provider');
    this.changeCtr = 0;
  }


  // set the firestore path
  initializeLookups() {
    console.log("Lookup Service: Initialize");
    return new Promise((resolve, reject) => {
      this.getConfig().then(ok => {
        this.getAllergies();
        this.getBillingMaster();
        this.getComorbids();
        this.getForm();
        this.getFrequency();
        this.getMedicine();
        this.getVitals();
        this.getRoute();
        this.getUOM();
        resolve(true);
      }, err => {
        console.log("Could not get Config", err);
        reject(err);
      })
    });
  }

  getConfig() {
    console.log("LookupServiceProvider: Get Config", this.configApi.accountInfo);

    return new Promise((resolve, reject) => {
      if (this.configApi.accountNum != "1") {
        this.configInfo = this.configApi.accountInfo;
        resolve({ result: "ok", message: "got lookups from configApi" });
      } else {
        console.log("LookupServiceProvider: getConfig(): error cuz account is not initialized");
        reject({ result: "error", message: "account not initialized" });
      }


      // this.configDoc = this.afs.doc<any>(this.fsPath);
      // console.log('ConfigDoc', this.configDoc);
      // let currentConfig = this.configDoc.valueChanges();
      // currentConfig.pipe(take(1)).subscribe(configInfo=>{
      //   console.log("Got configInfo", configInfo);
      //   if(configInfo == null || configInfo == undefined){
      //     console.log("Cannot be no config");
      //     reject("No config info");
      //   }else{
      //     this.configInfo = configInfo;
      //     resolve(configInfo);
      //   }
      // }, err=>{
      //   console.log("Error in getting lookup", err);
      //   reject("No config info");
      // });
    });

  }

  saveConfig(lookupName = "all") {
    switch (lookupName) {
      case 'all':
        let lookups = {
          allergies: this.allergies,
          billingMaster: this.billingMaster,
          comorbids: this.comorbids,
          formLookup: this.formLookup,
          frequencyLookup: this.frequencyLookup,
          medicineLookup: this.medicineLookup,
          requiredVitals: this.requiredVitals,
          routeLookup: this.routeLookup,
          uomLookup: this.uomLookup,
        }
        this.configApi.mergeAccountInfo(lookups);
        break;
      case 'allergies':
        this.configApi.mergeAccountInfo({ allergies: this.allergies });
        break;
      case 'billingMaster':
        this.configApi.mergeAccountInfo({ billingMaster: this.billingMaster });
        break;
      case 'comorbids':
        this.configApi.mergeAccountInfo({ comorbids: this.comorbids });
        break;
      case 'formLookup':
        this.configApi.mergeAccountInfo({ formLookup: this.formLookup });
        break;
      case 'frequencyLookup':
        this.configApi.mergeAccountInfo({ frequencyLookup: this.frequencyLookup });
        break;
      case 'medicineLookup':
        this.configApi.mergeAccountInfo({ medicineLookup: this.medicineLookup });
        break;
      case 'requiredVitals':
        this.configApi.mergeAccountInfo({ requiredVitals: this.requiredVitals });
        break;
      case 'routeLookup':
        this.configApi.mergeAccountInfo({ routeLookup: this.routeLookup });
        break;
      case 'uomLookup':
        this.configApi.mergeAccountInfo({ uomLookup: this.uomLookup });
        break;
      default:
        break;
    }
    this.changeCtr++;

  }

  updateConfig(){
    if(this.changeCtr > 0){
      this.configApi.updateAccount().then(_=>console.log("saved lookups to config"), err=>console.log("error in saving lookups to config", err));
    }
  }

  public getEvents(): Observable<any> {
    const dateObj = new Date();
    const yearMonth = dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
    let data: any = [{
      title: 'All Day Event',
      start: yearMonth + '-01'
    },
    {
      title: 'Long Event',
      start: yearMonth + '-07',
      end: yearMonth + '-10'
    },
    {
      id: 999,
      title: 'Repeating Event',
      start: yearMonth + '-09T16:00:00'
    },
    {
      id: 999,
      title: 'Repeating Event',
      start: yearMonth + '-16T16:00:00'
    },
    {
      title: 'Conference',
      start: yearMonth + '-11',
      end: yearMonth + '-13'
    },
    {
      title: 'Meeting',
      start: yearMonth + '-12T10:30:00',
      end: yearMonth + '-12T12:30:00'
    },
    {
      title: 'Lunch',
      start: yearMonth + '-12T12:00:00'
    },
    {
      title: 'Meeting',
      start: yearMonth + '-12T14:30:00'
    },
    {
      title: 'Happy Hour',
      start: yearMonth + '-12T17:30:00'
    },
    {
      title: 'Dinner',
      start: yearMonth + '-12T20:00:00'
    },
    {
      title: 'Birthday Party',
      start: yearMonth + '-13T07:00:00'
    },
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: yearMonth + '-28'
    }];
    return of(data);
  }
  initLookups() {
    this.initBillingMaster();
  }

  ///////////// LOOKUPS //////////////
  // put all lookups here for now
  // consider moving them to the lookups-service provider later on
  // the methods should still work later on.

  // Lookups
  // 1. Allergies
  // 2. Comorbids
  // 3. Medicine
  // 4. Frequency
  // 5. Units
  // 6. Form
  // 7. Route
  // 8. Billing Master

  initLookup() { //
    this.lookup = this.configInfo.lookup = [];
    //this.saveLookup();
  }


  getLookup() {
    let lookupInfo = this.configInfo.lookup;
    if (lookupInfo == null) {
      this.initLookup();
    } else {
      this.lookup = lookupInfo;
    }

  }

  addLookup(entry, index) {
    this.lookup.splice(index, 0, entry);
    this.saveConfig('lookupname');
  }

  deleteLookup(index) {
    let removedEntry = this.lookup.splice(index, 1);
    this.saveConfig('lookupname');
    return removedEntry;
  }

  editLookup(entry, index) {
    this.lookup[index] = entry;
    this.saveConfig('lookupname');
  }

  ////// Allergies

  initVitals() { //
    this.configInfo.requiredVitals = ['Weight', 'Blood Pressure'];
    this.requiredVitals = this.configInfo.requiredVitals;
    this.saveConfig('requiredVitals');
  }


  getVitals() {
    if (this.configInfo.requiredVitals == null || this.configInfo.requiredVitals == undefined) {
      this.initVitals();
    } else {
      this.requiredVitals = this.configInfo.requiredVitals;
    }
  }

  addVitals(entry, index) {
    this.requiredVitals.splice(index, 0, entry);
    this.saveConfig('requiredVitals');
  }

  addInitialLookupValues(vitals, medicines) {
    this.requiredVitals = [...vitals, ...this.requiredVitals];
    this.medicineLookup = [...medicines, ...this.medicineLookup];
    this.saveConfig('requiredVitals');
    this.saveConfig('medicineLookup');
  }

  deleteVitals(index) {
    let removedEntry = this.requiredVitals.splice(index, 1);
    this.saveConfig('requiredVitals');
    return removedEntry;
  }

  editVitals(entry, index) {
    this.requiredVitals[index] = entry;
    this.saveConfig('requiredVitals');
  }

  ////// Allergies

  initAllergies() { //
    this.configInfo.allergies = [];
    this.allergies = this.configInfo.allergies;
  }

  getAllergies() {
    if (this.configInfo.allergies == null || this.configInfo.allergies == undefined) {
      this.initAllergies();
    } else {
      this.allergies = this.configInfo.allergies;
    }
  }

  addAllergies(entry, index) {
    this.allergies.splice(index, 0, entry);
    this.saveConfig('allergies');
  }
  deleteAllergies(index) {
    let removedEntry = this.allergies.splice(index, 1);
    this.saveConfig('allergies');
    return removedEntry;
  }
  editAllergies(entry, index) {
    this.allergies[index] = entry;
    this.saveConfig('allergies');
  }

  ////// Comorbids

  initComorbids() { //
    this.comorbids = this.configInfo.comorbids = [];
  }

  getComorbids() {
    if (this.configInfo.comorbids == null || this.configInfo.comorbids == undefined) {
      this.initComorbids();
    } else {
      this.comorbids = this.configInfo.comorbids;
    }
  }

  addComorbids(entry, index) {
    this.comorbids.splice(index, 0, entry);
    this.saveConfig('comorbids');
  }
  deleteComorbids(index) {
    let removedEntry = this.comorbids.splice(index, 1);
    this.saveConfig('comorbids');
    return removedEntry;
  }
  editComorbids(entry, index) {
    this.comorbids[index] = entry;
    this.saveConfig('comorbids');
  }

  ////// Medicine

  initMedicine() { //
    this.configInfo.medicineLookup = [
      {generic:'Ascorbic Acid 500mg', name:'Cee-Protect'},
      {generic:'Ascorbic Acid 500mg', name:'Nutricee'},
      {generic:'Paracetamol 500mg', name:'Biogesic'},
      {generic:'Paracetamol 500mg', name:'Zytamol'},
      {generic:'Amoxicillin 500mg', name:'Amoxil'},

    ];
    this.medicineLookup = this.configInfo.medicineLookup;
  }



  // added this to change duplicate entries in medicine lookup.
  checkDuplicates(){
    console.log("checkDuplicates():", this.configInfo.medicineLookup);
    this.configInfo.medicineLookup.sort((a,b) => {
        console.log(a,b);
        let aEntry = {name:"", generic:""};
        let bEntry = {name:"", generic:""};
        Object.assign(aEntry,a);
        Object.assign(bEntry,b);
        aEntry.generic = aEntry.generic.toUpperCase();
        bEntry.generic = bEntry.generic.toUpperCase();
        aEntry.name = aEntry.name.toUpperCase();
        bEntry.name = bEntry.name.toUpperCase();
        return (aEntry.generic > bEntry.generic) ? 1 : ((bEntry.generic > aEntry.generic) ? -1 : ((aEntry.name > bEntry.name) ? 1 : ((bEntry.name > aEntry.name) ? -1 : 0)));
    });
    console.log("checkDuplicates(): sorted", this.configInfo.medicineLookup);
    let past_entry = {generic:"uncomparable", name:"uncomparable"};
    let new_entry = {generic:"uncomparable", name:"uncomparable"};
    let forDeletion = [];
    this.configInfo.medicineLookup.forEach((entry, index)=>{
      //Object.assign(new_entry, entry);
      if(past_entry.generic.toUpperCase() == entry.generic.toUpperCase()){
        if(past_entry.name.toUpperCase() == entry.name.toUpperCase()){
          console.log("checkDuplicates(): Found duplicate", past_entry.name, entry.name);
          forDeletion.push(index);
        }
      }
      Object.assign(past_entry, entry);
    });
    if(forDeletion.length > 0){
      forDeletion.sort((a, b)=>{return b-a});
      forDeletion.forEach(idx =>{
        console.log("checkDuplicates(): splice", idx);
        //this.configInfo.medicineLookup.splice(idx,1);
      })
    }
  }

  getMedicine() {
    if (this.configInfo.medicineLookup == null || this.configInfo.medicineLookup == undefined) {
      console.log("Initializing");
      this.initMedicine();
    } else {
      console.log("Get Medicine", this.configInfo.medicineLookup.length);
      this.checkDuplicates();
      this.medicineLookup = this.configInfo.medicineLookup;
    }

  }

  addMedicine(entries) {
    // console.log("LookupServiceProvider: addMedicine()", entry, index);
    // this.medicineLookup.splice(index,0,entry);

    this.medicineLookup = [
      ...entries,
      ...this.medicineLookup
    ];
    this.saveConfig('medicineLookup');
  }

  deleteMedicine(index) {
    let removedEntry = this.medicineLookup.splice(index, 1);
    this.saveConfig('medicineLookup');
    return removedEntry;
  }

  editMedicine(matchingEntries, updatedEntries) {
    // this.medicineLookup[index] = entry;
    // console.log("Edit Medicine", entry, index, this.medicineLookup[index]);
    let newEntries = [];
    updatedEntries.forEach(({ index, entry }) => {
      if (index >= 0) {
        this.medicineLookup[index] = entry;
      } else {
        newEntries = [
          entry,
          ...newEntries
        ];
      }
    });

    const deleteEntries = matchingEntries.reduce((acc: any[], { index }) => {
      if (!updatedEntries.find(e => e.index === index)) return [...acc, index];
      return [...acc];
    }, []);

    if (deleteEntries.length) this.medicineLookup = this.medicineLookup.filter((m, i) => !deleteEntries.includes(i));

    if (newEntries.length) {
      this.medicineLookup = [
        ...newEntries,
        ...this.medicineLookup
      ];
    }
    this.saveConfig('medicineLookup');
  }

  ////// UOM

  initUOM() { //
    this.configInfo.uomLookup = [
      {
        description: 'amp - ampule',
        abbr: 'amp'
      },
      {
        description: 'c - cup',
        abbr: 'c'
      },
      {
        description: 'g - gram',
        abbr: 'g'
      },
      {
        description: 'gr - grain',
        abbr: 'gr'
      },
      {
        description: 'gtt - drop',
        abbr: 'gtt'
      },
      {
        description: 'iu - international unit',
        abbr: 'iu'
      },
      {
        description: 'kg - kilogram',
        abbr: 'kg'
      },
      {
        description: 'L - liter',
        abbr: 'L'
      },
      {
        description: 'mcg - microgram',
        abbr: 'mcg'
      },
      {
        description: 'mEq - miliequivalent',
        abbr: 'mEq'
      },
      {
        description: 'mg - miligram',
        abbr: 'mg'
      },
      {
        description: 'mL - mililiter',
        abbr: 'mL'
      },
      {
        description: 'oz - ounce',
        abbr: 'oz'
      },
      {
        description: 'pt - pint',
        abbr: 'pt'
      },
      {
        description: 'qt - quart',
        abbr: 'qt'
      },
      {
        description: 'tbsp - tablespoon',
        abbr: 'tbsp'
      },
      {
        description: 'tsp - teaspoon',
        abbr: 'tsp'
      },

    ];

    this.uomLookup = this.configInfo.uomLookup;
    //this.saveConfig();
  }

  getUOM() {
    if (this.configInfo.uomLookup == null || this.configInfo.uomLookup == undefined) {
      this.initUOM();
    } else {
      this.uomLookup = this.configInfo.uomLookup;
    }
  }


  addUOM(entry, index) {
    this.uomLookup.splice(index, 0, entry);
    this.saveConfig('uomLookup');
  }

  deleteUOM(index) {
    let removedEntry = this.uomLookup.splice(index, 1);
    this.saveConfig('uomLookup');
    return removedEntry;
  }

  editUOM(entry, index) {
    this.uomLookup[index] = entry;
    this.saveConfig('uomLookup');
  }

  ////// Form

  initForm() { //
    this.configInfo.formLookup = ["Ampule",
      "Bottle",
      "Capsule",
      "Cream",
      "Drops",
      "N/A",
      "Ointment",
      "Patches",
      "Pen",
      "Softgel Cap",
      "Suppository",
      "Suspension",
      "Tablet",
      "Vial",
    ];
    this.formLookup = this.configInfo.formLookup;
    //this.saveConfig();
  }

  getForm() {
    if (this.configInfo.formLookup == null || this.configInfo.formLookup == undefined) {
      this.initForm();
    } else {
      this.formLookup = this.configInfo.formLookup;
    }

  }
  observeForm(): Observable<any> {
    let data = this.formLookup;
    return of(data);
  }
  addForm(entry, index) {
    this.formLookup.splice(index, 0, entry);
    this.saveConfig('formLookup');
  }
  deleteForm(index) {
    let removedEntry = this.formLookup.splice(index, 1);
    this.saveConfig('formLookup');
    return removedEntry;
  }
  editForm(entry, index) {
    this.formLookup[index] = entry;
    this.saveConfig('formLookup');
  }

  ////// Frequency

  initFrequency() { //
    this.configInfo.frequencyLookup = [ // build from database using all the previous values in frequency
      { value: '5x - 5x/day', abbr: '5x' },
      { value: 'BID - 2x/day', abbr: 'BID' },
      { value: 'LD - Load', abbr: 'LD' },
      { value: 'OD - 1x/day', abbr: 'OD' },
      { value: 'PRN - as needed', abbr: 'PRN' },
      { value: 'Q1 - Every hour', abbr: 'Q1' },
      { value: 'Q12 - Every 12 hours', abbr: 'Q12' },
      { value: 'Q2 - Every 2 hours', abbr: 'Q2' },
      { value: 'Q4H - Every 4 hours', abbr: 'Q4H' },
      { value: 'Q6H - Every 6 hours', abbr: 'Q6H' },
      { value: 'Q8 - Every 8 hours', abbr: 'Q8' },
      { value: 'QID - 4x/day', abbr: 'QID' },
      { value: 'QOD - Every other day', abbr: 'QOD' },
      { value: 'TID - 3x/day', abbr: 'TID' },
    ];
    this.frequencyLookup = this.configInfo.frequencyLookup;
    //this.saveFrequency();
  }

  getFrequency() {
    if (this.configInfo.frequencyLookup == null || this.configInfo.frequencyLookup == undefined) {
      this.initFrequency();
    } else {
      this.frequencyLookup = this.configInfo.frequencyLookup;
    }
  }

  addFrequency(entry, index) {
    this.frequencyLookup.splice(index, 0, entry);
    this.saveConfig('frequencyLookup');
  }
  deleteFrequency(index) {
    let removedEntry = this.frequencyLookup.splice(index, 1);
    this.saveConfig('frequencyLookup');
    return removedEntry;
  }
  editFrequency(entry, index) {
    this.frequencyLookup[index] = entry;
    this.saveConfig('frequencyLookup');
  }

  ////// Route

  initRoute() { //
    this.routeLookup = this.configInfo.routeLookup = [
      {
        value: "PO (by mouth)",
        abbr: "PO"
      },
      {
        value: "PR (per rectum)",
        abbr: "PR"
      },
      {
        value: "IM (intramuscular)",
        abbr: "IM"
      },
      {
        value: "IV (intravenous)",
        abbr: "IV"
      },
      {
        value: "ID (intradermal)",
        abbr: "ID"
      },
      {
        value: "TP (topical)",
        abbr: "TP"
      },
      {
        value: "SL (sublingual)",
        abbr: "SL"
      },
      {
        value: "BUCC (buccal)",
        abbr: "BUCC"
      },
      {
        value: "IP (intraperitoneal)",
        abbr: "IP"
      },
    ];
    //this.saveConfig();
  }

  getRoute() {
    if (this.configInfo.routeLookup == null || this.configInfo.routeLookup == undefined) {
      this.initRoute();
    } else {
      this.routeLookup = this.configInfo.routeLookup;
    }

  }

  addRoute(entry, index) {
    this.routeLookup.splice(index, 0, entry);
    this.saveConfig('routeLookup');
  }
  deleteRoute(index) {
    let removedEntry = this.routeLookup.splice(index, 1);
    this.saveConfig('routeLookup');
    return removedEntry;
  }
  editRoute(entry, index) {
    this.routeLookup[index] = entry;
    this.saveConfig('routeLookup');
  }

  ////// BILLING MASTER

  initBillingMaster() {
    this.billingMaster = this.configInfo.billingMaster = [{
      description: "Consultation",
      unit: "visit",
      unitPrice: 1000
    }, {
      description: "Medical Clearance",
      unit: 'copy',
      unitPrice: 500
    },
    {
      description: "Medical Report",
      unit: 'copy',
      unitPrice: 500
    }];
    //this.saveConfig();
  }

  getBillingMaster() {
    if (this.configInfo.billingMaster == null || this.configInfo.billingMaster == undefined) {
      this.initBillingMaster();
    } else {
      this.billingMaster = this.configInfo.billingMaster;
    }
  }

  addBillingMasterEntry(entry: Billable, index) {
    console.log("Entry", entry);
    if (typeof entry.unitPrice == "string")
      entry.unitPrice = parseFloat('' + entry.unitPrice)
    console.log("Entry", entry)
    this.billingMaster.splice(index, 0, entry);
    this.saveConfig('billingMaster');
  }

  deleteBillingMasterEntry(index) {
    let removedEntry = this.billingMaster.splice(index, 1);
    this.saveConfig('billingMaster');
    return removedEntry;
  }

  editBillingMasterEntry(entry: Billable, index) {
    console.log("Entry", entry)
    if (typeof entry.unitPrice == "string")
      entry.unitPrice = parseFloat('' + entry.unitPrice)
    console.log("Entry", entry)
    this.billingMaster[index] = entry;
    this.saveConfig('billingMaster');
  }
}
