import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { CompleterService, CompleterData, CompleterItem, CompleterCmp } from 'ng2-completer';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// my pages providers and interfaces
import { PrescriptionInfo } from '../encounter-info';
import { LookupServiceProvider } from '../services/lookup.service';

// reference https://medicalschoolhq.net/prescription-writing-101/

@Component({
  selector: 'app-prescription-form',
  templateUrl: './prescription-form.page.html',
  styleUrls: ['./prescription-form.page.scss'],
})
export class PrescriptionFormPage implements OnInit {
  @ViewChild('genericInput', { static: false }) firstBox;
  @Input('mode') mode: string;
  @Input('prescriptionInfo') prescriptionInfo: any = {};
  prescriptionItem: PrescriptionInfo = null;
  didClose: boolean = false;
  rxCtrl = new FormControl();
  freqCtrl = new FormControl();
  // completer variables
  searchStr: string = "";
  brands: any = [];
  genericsList: any = null;
  options: string[] = ['One', 'Two', 'Three'];
  medOptions: string[]=[];
  freqOptions: string[]=[];
  filteredOptions: Observable<string[]>;
  filteredMedOptions: Observable<string[]>;
  filteredFreqOptions: Observable<string[]>;
  frequencyDataService: CompleterData;
  frequencyData = [ // build from database using all the previous values in frequency
    { value: 'daily', abbr: '' },
    { value: 'every other day', abbr: '' },
    { value: 'twice a day', abbr: 'BID' },
    { value: 'three times a day', abbr: 'TID' },
    { value: 'four times a day', abbr: 'QID' },
    { value: 'every bedtime', abbr: 'QHS' },
    { value: 'every 4 hours', abbr: 'Q4h' },
    { value: 'every 4-6 hours', abbr: 'Q4-6h' },
    { value: 'every week', abbr: 'QWK' }
  ];
  medicationDataService: CompleterData;
  medicationData = [
    { value: 'Tylenol' },
    { value: 'Medicol' },
    { value: 'Acetominophen' },
    { value: 'Glutathion' },
    { value: 'Aspirin' },
    { value: 'Vitamin C' },
    { value: 'Dolfenal' }
  ];
  selectOptions:any={
    cssClass:"brandSelection"
  }

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    private modalCtrl: ModalController,
    private completerService: CompleterService,
    private lookupApi: LookupServiceProvider) {
    this.blankPrescription();
    this.lookupApi.initializeLookups().then(ok => {
      this.setupMedication();
      this.initializePrescription();
      this.setupFrequency();
    }, err => {
      console.log("Error in initializing lookups");
    });
    this.frequencyDataService = completerService.local(this.frequencyData, 'value', 'value');
    //this.medicationDataService = completerService.local(this.medicationData, 'value','value');
  }

  ngOnInit() {
    console.log('ionViewDidLoad PrescriptionFormPage');
    setTimeout(() => {
      console.log("firstBox", this.firstBox)
      this.firstBox.nativeElement.focus();
    }, 500);
    // setup of medicine autocomplete
    this.filteredMedOptions = this.rxCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterMeds(value))
      );
    this.rxCtrl.valueChanges.subscribe(genericMed=>{
      console.log("rxCtl.valuChanges", genericMed);
      this.prescriptionItem.medication = genericMed;
    });
    // setup of frequency autocomplete
    this.filteredFreqOptions = this.freqCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterFreq(value))
      );
    this.freqCtrl.valueChanges.subscribe(frequency=>{
      console.log("freqCtl.valuChanges", frequency);
      this.prescriptionItem.frequency = frequency;
    });

  }

  _filterMeds(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.medOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  _filterFreq(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.freqOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  ionViewCanLeave() {
    return this.didClose;
  }

  setupMedication() {
    console.log("Medications", this.lookupApi.medicineLookup);
    let genericsList = {};
    this.lookupApi.medicineLookup.forEach(medicine => {
      let genericKey = medicine['generic'];
      if (genericsList[genericKey] == undefined) {
        genericsList[genericKey] = [];
      }
      let brandName = medicine['name'].toUpperCase();
      genericsList[genericKey].push(brandName);
    })
    let keys = Object.keys(genericsList);
    this.medicationData = [];
    this.medOptions = [];
    keys.forEach(generic => {
      this.medicationData.push({ value: generic });
      this.medOptions.push(generic);
    })
    this.medOptions.sort();
    this.genericsList = genericsList;
    console.log("Set medicines", this.medicationData, this.genericsList);

    this.medicationDataService = this.completerService.local(this.medicationData, 'value', 'value');
    //    this.medicationDataService = completerService.local(this.medicationData, 'value','value');

  }

  setupFrequency() {
    console.log("Frequency", this.lookupApi.frequencyLookup);
    this.frequencyDataService = this.completerService.local(this.lookupApi.frequencyLookup, "value", "value");
    this.freqOptions = [];
    this.lookupApi.frequencyLookup.forEach(freqOption=>{
      this.freqOptions.push(freqOption.value);
    })
  }

  initializePrescription() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   if (params['mode'] == 'edit') {
    //     let prescriptionInfo = params['prescriptionInfo'];
    //     console.log("initializing with ", prescriptionInfo);
    //     Object.assign(this.prescriptionItem, prescriptionInfo);
    //     console.log("initialized with ", this.prescriptionItem);
    //   }
    // });
    console.log(this.mode, this.prescriptionInfo);
    if (this.mode == 'edit') {
      console.log("initializing with ", this.prescriptionInfo);
      Object.assign(this.prescriptionItem, this.prescriptionInfo);
      this.rxCtrl.setValue(this.prescriptionInfo.medication);
      this.setBrands(false); // set brands, but do not reset current value
      this.freqCtrl.setValue(this.prescriptionInfo.frequency);
      console.log("initialized with ", this.prescriptionItem);
    }
  }

  blankPrescription() {
    this.prescriptionItem = {
      patientID: "",
      patientName: "",
      patientBirthDate: "",
      medication: "",
      brands: [],
      strength: "",
      amount: "",
      route: "",
      frequency: "",
      why: "",
      howMuch: "",
      refills: "0",
    }
  }

  setBrands(initBrands = true) {
    console.log("Chosen generic", this.prescriptionItem.medication);
    this.brands = this.genericsList[this.prescriptionItem.medication];
    if(initBrands)
      this.prescriptionItem.brands = [];
  }

  onSelected(item: CompleterItem) {
    this.prescriptionItem.frequency = item ? item.title : "";
  }

  savePrescription() {
    this.didClose = true;
    this.modalCtrl.dismiss({ result: "Saved", rx: this.prescriptionItem });
  }
  closeForm() {
    this.didClose = true;
    this.modalCtrl.dismiss({ result: "Closed", rx: null });
  }

  getBrandList(brands: any) {
    return brands.toString().replace(/,/gi, " or ");
  }

}
