import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Events, ModalController } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { BillingInfo } from '..//billing-info';
import { BillingDetails } from '../billing-details';
import { BillableModalPage } from '../billable-modal/billable-modal.page';
import { PatientModalPage } from '../patient-modal/patient-modal.page';

import { PatientProvider } from '../services/patient.service';
import { PasserService } from '../services/passer.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-new-charge-slip',
  templateUrl: './new-charge-slip.page.html',
  styleUrls: ['./new-charge-slip.page.scss'],
})
export class NewChargeSlipPage implements OnInit {
  isModal: boolean = true;
  billingInfo: BillingInfo;
  billingDetails: Array<BillingDetails> = [];
  date: string;
  patient: string;
  patientname: string;
  patientName: string;
  medcert = {
    patientName: '',
    address: '',
    examinedate: '',
    objective: '',
    assessment: '',
    recommendation: '',
    age: '47',
    birthdate: '',
    gender: ''
  }

  doctor = {
    fullname: "",
    suffix: "",
    specialization: "",
    licenseNum: "",
    ptrNum: "",
    tinNum: "",
    s2Num: "",
    s2NumExpiry: "",
    affiliations: [
    ]
  }
  pdf = null;
  pdfDoc: any = null;
  encounter: any = {
    chargeSlip: {
      billingInfo: null,
      pdfDoc: null,
      billingDetails: []
    }
  }
  billingMaster: any;
  patientModalOpen: boolean = false;
  bdModalOpen: boolean = false;
  amountDue: string = "0.00";
  clinic: any;
  clinics: Array<any> = [];
  isWebApp: boolean = true;
  chargeParams: any = {};
  numbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  fullEdit: boolean = false;

  constructor(public navCtrl: NavController,
    private platform: Platform,
    public modalCtrl: ModalController,
    public patientProvider: PatientProvider,
    public event: Events,
    public file: File,
    private fileOpener: FileOpener,
    private passer: PasserService) {

    this.chargeParams = this.passer.retrieve('forNewChargeSlip');

    this.readParams().then(ready => {

    })
    console.log("ChargeSlip: params", this.chargeParams);
    let timestamp1: any = new Date;
    let timestamp2: any = new Date;

    if (this.chargeParams['encounter']) {
      let patientInfo = this.chargeParams['patient'];
      this.patientName = `${patientInfo.firstname} ${patientInfo.lastname}`;
      this.encounter = this.chargeParams['encounter'];
      this.billingMaster = this.chargeParams['billingMaster'];
      if (this.encounter.billingInfo == undefined) {
        this.encounter.billingInfo = {
          billingID: timestamp1.getTime(),
          patientID: patientInfo.patientID,
          encounterID: this.encounter.encounterID,
          billingDate: timestamp1,
          discount: 0.00,
          subtotal: 0.00,
          amountDue: 0.00,
          status: 'Unpaid',
          paymentReference: null,
          paymentType: null,
          remarks: null
        }
      }
      if (this.encounter.pdfDoc == undefined) {
        this.encounter.pdfDoc = null
      }
      if (this.encounter.billingDetails == undefined) {
        this.encounter.billingDetails = [];
      }

      this.billingInfo = this.encounter.billingInfo;
      this.pdfDoc = this.encounter.pdfDoc;
      this.billingDetails = this.encounter.billingDetails;
    } else {
      let b: BillingInfo = {
        billingID: timestamp1.getTime(),
        patientID: null,
        encounterID: timestamp2.getTime(),
        billingDate: timestamp1,

        discount: 0.00,
        subtotal: 0.00,
        amountDue: 0.00,
        status: null,
        paymentReference: null,
        paymentType: null,
        remarks: null
      }
      this.billingInfo = b;
      this.billingDetails = [];
    }

    event.subscribe('billingDetailsArray:updated', message => {
      this.getSubtotal();
    });

    let temp: any[] = (timestamp1.toString()).split(" ", 2);
    this.date = temp[0] + ", " + temp[1] + " " + timestamp1.getDate() + ", " + timestamp1.getFullYear();
    this.isWebApp = (this.platform.is('desktop') || this.platform.is('mobileweb') || this.platform.is('pwa') || this.platform.is('electron'));
  }

  ngOnInit() {
    console.log('ionViewDidLoad NewChargeSlipPage');
  }

  readParams() {
    if (this.chargeParams['encounter']) {
      this.isModal = true;
      let encounter = this.chargeParams['encounter'];
      let examineDate = new Date();
      examineDate.setTime(encounter.createTime);
      this.medcert.examinedate = examineDate.toDateString();
      this.medcert.objective = encounter.objective;
      this.medcert.assessment = encounter.assessment;
    }
    if (this.chargeParams['patient']) {
      let patientInfo = this.chargeParams['patient'];
      console.log("Report: patient", patientInfo);
      this.medcert.patientName = patientInfo.firstname + ' ' + patientInfo.lastname;
      this.medcert.gender = patientInfo.gender;
      this.medcert.age = patientInfo.age;
      this.medcert.address = "";
      this.medcert.birthdate = patientInfo.birthdate;
      let comma = '';
      if (patientInfo.contactInfo.line1 > "") {
        this.medcert.address += comma + patientInfo.contactInfo.line1;
        comma = ", ";
      }
      if (patientInfo.contactInfo.line2 > "") {
        this.medcert.address += comma + patientInfo.contactInfo.line2;
        comma = ", ";
      }
      if (patientInfo.contactInfo.barangay > "") {
        this.medcert.address += comma + patientInfo.contactInfo.barangay;
        comma = ", ";
      }
      if (patientInfo.contactInfo.city > "") {
        this.medcert.address += comma + patientInfo.contactInfo.city;
        comma = ", ";
      }
      if (patientInfo.contactInfo.province > "") {
        this.medcert.address += comma + patientInfo.contactInfo.province;
        comma = ", ";
      }


    }
    return new Promise((resolve, reject) => {
      if (this.chargeParams['config']) {
        let config = this.chargeParams['config'];
        console.log("Report: Report: config", config);
        if(config.pinLoginUserType == 'doctor'){
          this.fullEdit = true;
        }else {
          this.fullEdit = false;
        }
        this.doctor.fullname = config.accountInfo.doctorName;
        this.doctor.licenseNum = config.accountInfo.doctorPRC;
        this.doctor.ptrNum = config.accountInfo.doctorPTR;
        this.doctor.tinNum = config.accountInfo.doctorTIN;
        this.doctor.affiliations = config.accountInfo.doctorAffiliation;
        this.doctor.suffix = config.accountInfo.doctorSuffix;
        this.doctor.specialization = config.accountInfo.doctorSpecialization;
        this.doctor.s2Num = config.accountInfo.doctorS2Num;
        this.doctor.s2NumExpiry = config.accountInfo.doctorS2NumExpiry;
        let clinic = config.clinic;
        this.clinic = clinic;
        this.clinics = [];
        this.clinics.push({
          line1: clinic.clinicName,
          city: clinic.contactInfo.city,
          province: clinic.contactInfo.province,
          barangay: clinic.contactInfo.barangay,
          cellphone: clinic.contactInfo.cellphone
        })
        resolve("OK");
      } else {
        resolve("OK")
      }
    })

  }

  async showSearch() {
    let patientModal = await this.modalCtrl.create({
      component: PatientModalPage
    });

    patientModal.present();
    let { data } = await patientModal.onDidDismiss();
    if (data) {
      let patient = data;
      this.billingInfo.patientID = data.patientID;
      this.patient = data.firstName + " " + data.lastName;
    }

    console.log(this.billingInfo);
  }

  async showSearchPatient() {
    let patientModal = await this.modalCtrl.create({
      component: PatientModalPage
    });

    if (this.patientModalOpen == false) {
      patientModal.present();
      this.patientModalOpen = true;

      let { data } = await patientModal.onDidDismiss();
      this.patientModalOpen = false;
      if (data) {
        this.billingInfo.patientID = data.patientID;
        this.patient = data.firstName + " " + data.lastName;
      }
      console.log(this.billingInfo);
    }


  }

  async showSearchBillingDetail() {
    let billableModal = await this.modalCtrl.create({
      component: BillableModalPage,
      componentProps: { billingMaster: this.billingMaster }
    });

    if (this.bdModalOpen == false) {
      billableModal.present();
      this.bdModalOpen = true;
      let { data } = await billableModal.onDidDismiss();
      let billable = data;
      this.bdModalOpen = false;
      if (billable) {
        const { description, unit, unitPrice } = billable;
        const exist = this.billingDetails.find(b => b.description === billable.description);

        this.billingDetails = exist ?
          this.billingDetails.map(b => billable.description === b.description ? { ...b, qty: b.qty + 1, amount: b.amount + billable.unitPrice } : b) : [
            ...this.billingDetails,
            {
              description,
              unit,
              unitPrice,
              qty: 1,
              amount: unitPrice,
            }
          ];

        this.getSubtotal();
      }
    }

  }

  getSubtotal() {
    let subtotal: number = 0;
    let billingDetails: BillingDetails[] = this.billingDetails;
    //console.log(billingDetails.length);

    for (let i = 0; i < billingDetails.length; i++) {
      subtotal += billingDetails[i].amount;
    }

    this.billingInfo.subtotal = subtotal;
    this.billingInfo.amountDue = subtotal - this.billingInfo.discount;
  }

  onDiscountInput() {
    this.billingInfo.amountDue = this.billingInfo.subtotal - this.billingInfo.discount;
    this.amountDue = `PHP ${this.billingInfo.amountDue}`;
  }

  onQtyInput(billingDetail: BillingDetails, index) {
    this.billingDetails[index].amount = this.billingDetails[index].qty * this.billingDetails[index].unitPrice;
    this.getSubtotal();
  }

  removeBillingItem(billingDetail: BillingDetails) {
    let details: BillingDetails[] = this.billingDetails;
    let index: number;

    for (let i = 0; i < details.length; i++) {
      if (details[i] == billingDetail) {
        index = i;
        i = details.length;
      }
    }

    this.billingDetails.splice(index, 1);

    this.getSubtotal();
  }


  cancelSlip() {
    let chargeSlip = {
      pdfDoc: this.pdfDoc,
      billingInfo: this.billingInfo,
      billingDetails: this.billingDetails
    }
    this.modalCtrl.dismiss(chargeSlip);
  }

  chargeSlipForm() {
    console.log(this.billingInfo);
    this.createPdf().then(buffer => {
      console.log('buffer', buffer);
      let chargeSlip = {
        pdfDoc: this.pdfDoc,
        billingInfo: this.billingInfo,
        billingDetails: this.billingDetails
      }
      this.modalCtrl.dismiss(chargeSlip);
    })
  }

  createPdf() {
    return new Promise((resolve, reject) => {
      let data = [];

      let header = [];
      header.push({ text: 'PARTICULARS', style: 'tableHeader' }, { text: 'AMOUNT', style: 'tableHeader', alignment: 'right' });
      data.push(header);
      let dataRow = [];
      dataRow = [{ text: '', colSpan: '2', style: 'lineBreak' }];
      data.push(dataRow);

      for (let i = 0; i < this.billingDetails.length; i++) {
        console.log(this.billingDetails[i]);
        dataRow = [{
          text: this.billingDetails[i].description + ' x ' + this.billingDetails[i].qty + '\n' +
            this.billingDetails[i].unitPrice.toFixed(2) + "/" + this.billingDetails[i].unit, rowSpan: 2, style: 'billing'
        },
        { text: this.billingDetails[i].amount.toFixed(2), rowSpan: 2, style: 'amount', alignment: 'right' }];
        data.push(dataRow);
        dataRow = ['', ''];
        data.push(dataRow);
      }


      let doctorName = this.doctor.fullname + ", " + this.doctor.suffix;
      let doctorSpecialty = this.doctor.specialization;

      var docDefinition = {
        pageSize: {
          width: 419.53,
          height: 595.28
        },
        content: [
          { text: doctorName.toUpperCase(), style: 'header', alignment: 'center' },
          { text: doctorSpecialty, style: 'subheader', alignment: 'center' },
          { text: " ", style: 'space' },
          { text: 'CHARGE SLIP', style: 'title' },
          { text: " ", style: 'space' },
          {
            table: {
              widths: [70, 100, 50, 100],
              body: [
                [{ text: this.billingInfo.paymentType, style: 'tableHeader', alignment: 'right' }, { text: this.billingInfo.paymentReference, style: 'billing' }, { text: 'DATE:', style: 'tableHeader', alignment: 'right' }, { text: this.date, style: 'billing' }],
                [{ text: 'NAME:', style: 'tableHeader', alignment: 'right' }, { text: this.medcert.patientName, style: 'billing' }, { text: 'AGE:', style: 'tableHeader', alignment: 'right' }, { text: this.medcert.age, style: 'billing' }],
                [{ text: 'B-DATE:', style: 'tableHeader', alignment: 'right' }, { text: this.medcert.birthdate, style: 'billing' }, { text: 'SEX:', style: 'tableHeader', alignment: 'right' }, { text: this.medcert.gender, style: 'billing' }]
              ]
            },
            layout: 'noBorders'
          },
          { text: " ", style: 'space' },

          {
            table: {
              headerRows: 1,
              widths: [220, 105],
              body: data,
            },
            layout: 'headerLineOnly'
          },

          { text: ' ', style: 'space' },

          {
            table: {
              widths: [240, 93],
              body: [
                [{ text: 'Subtotal', style: 'billing', alignment: 'right' }, { text: this.billingInfo.subtotal.toFixed(2), style: 'billing', alignment: 'right' }],
                [{ text: 'Discount', style: 'billing', alignment: 'right' }, { text: this.billingInfo.discount.toFixed(2), style: 'billing', alignment: 'right' }],
                [{ text: 'AMOUNT DUE', style: 'tableHeader', alignment: 'right' }, { text: 'PHP ' + this.billingInfo.amountDue.toFixed(2), style: 'tableHeader', alignment: 'right' }]
              ]
            },
            layout: 'noBorders'
          },

          { text: " ", style: 'space' },
          { text: " ", style: 'space' },
          { text: " ", style: 'space' },
          { text: doctorName, style: 'subheader', alignment: 'right' },


          /*{ text: 'Payment', style: 'subheader' },
          { text: 'Type: ' + this.billingInfo.paymentType, style: 'billing'},
          { text: 'Status: ' + this.billingInfo.status, style: 'billing'},
          { text: 'Reference No.: ' + this.billingInfo.paymentReference, style: 'billing'},

          { text: 'Remarks', style: 'subheader' },
          { text: this.billingInfo.remarks, style: 'billing'},*/
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          title: {
            fontSize: 16,
            bold: true,
          },
          subheader: {
            fontSize: 12,
            bold: true
          },
          space: {
            margin: [0, 5, 0, 0]
          },
          lineBreak: {
            margin: [0, 2, 0, 0]
          },
          tableHeader: {
            fontSize: 11,
            bold: true,
          },
          billing: {
            fontSize: 10,
            margin: [0, 0, 0, 0]
          },
          amount: {
            fontSize: 10,
            margin: [0, 5, 0, 0]
          }
        }
      }

      this.pdfDoc = JSON.stringify(docDefinition);
      this.pdf = pdfMake.createPdf(docDefinition);
      this.pdf.getBuffer(buffer => {
        if (buffer) {
          resolve(buffer);
        } else {
          reject({ error: "did not generate a pdf document buffer" })
        }
      }, err => {
        console.log("error while buffering PDF doc");
        reject(err);
      });
    })


  }

  downloadPdf(filename = "chargeslip.pdf") {
    if (this.pdf == null) {
      this.createPdf();
    }

    if (this.isWebApp) {
      // On a browser simply use download!
      this.pdf.download(filename);
    } else {
      this.pdf.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });
        console.log("Report: Writing file", this.file.dataDirectory, filename);
        // Save the PDF to the data Directory of our App
        this.file.writeFile(this.file.dataDirectory, filename, blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          console.log("Report: File Entry", fileEntry, this.file.dataDirectory, filename);

          this.fileOpener.open(this.file.dataDirectory + filename, 'application/pdf')
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
        }, err => {
          console.log("Report: Error in writing file", err);
        })
      });
    }

  }


}
