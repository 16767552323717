import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { RecordsApiProvider } from '../services/records-api.service';
import { ModalController, Platform, IonSlides } from '@ionic/angular';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { take, filter } from 'rxjs/operators';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  @Input('encounters') allEncounters: any = null;
  @Input('showActions') showActions: boolean = false;
  @Input('showCopy') showCopy: boolean = false;
  @Input('exclude') excludeEncounter: string="";
  @Input('displaying') displaying: boolean = false;
  @Output() copyRxClick: any = new EventEmitter();
  @Output() copyRxsClick: any = new EventEmitter();
  @Output() copyReportClick: any = new EventEmitter();
  @Output() openEncounterClick: any = new EventEmitter();
  @Output() deleteEncounterClick: any = new EventEmitter();
  @Output() viewImagesClick: any = new EventEmitter();
  sliderOpts:any= {slidesPerView:3};
  emrIdx: any = {};
  @ViewChildren('docSlides') slidersRef: QueryList<IonSlides>;

  constructor( private recordsApi: RecordsApiProvider,
               private modalCtrl: ModalController,
              private platform:Platform) {
    console.log('Hello Visits Component');
    console.log("showActions", this.showActions);
    console.log("encounters", this.allEncounters);
    // this.setSlidePerView();
  }

  ngOnInit() {
    Object.assign(this.sliderOpts,{
      slidesPerView: 5,
      freeMode: true,
      zoom: false,
      //centeredSlides: true,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }
      // updateOnWindowResize:true
    });

  }

  ngAfterViewInit() {
    this.slidersRef.changes.subscribe(slides => {
      slides.forEach((s, i) => {
        s.options = this.sliderOpts;
      });
    });
  }

  ngOnChanges(changes) {
    if(this.allEncounters && this.displaying) {
      this.allEncounters.pipe(take(1)).subscribe(encounters => {
        const patientEMRS = encounters.reduce((acc: string[], {emrImages}) => {
          return [
            ...acc,
            ...emrImages
          ];
        }, []);

        this.recordsApi.selectEMRs(patientEMRS).then(ctr => {
          this.recordsApi.emrImages.forEach((emr, i) => {
            this.emrIdx[emr.imageID] = i;
            this.recordsApi.setImageSource(emr, i);
          });
        });
      });
    }
    // this.setSlidePerView();
  }

  setSlidePerView(){
    console.log("Setting Slides PerView", this.platform, this.sliderOpts);
    if(this.platform.ready()){
      let screenWidth = this.platform.width();
      if(screenWidth < 480){
        Object.assign(this.sliderOpts, {slidesPerView : 2});
      }else{
        Object.assign(this.sliderOpts, {slidesPerView : 3});
      }
    }
  }

  encounterDate(timestamp) {
    let myDate = new Date(timestamp);

    //return myDate.toString().substr(0,15);
    // renders as Day Month date, Year ( years, months, weeks)
    let days = ["Sun",]
    let dateStr = "";

    // parse the date
    dateStr += myDate.toDateString();
    // add a comma
    dateStr = dateStr.substr(0, 10) + ',' + dateStr.substr(10);
    // calculate days in between
    let todate = new Date();
    let timebetween = todate.getTime() - myDate.getTime();
    let diffDays = Math.floor(timebetween / (1000 * 3600 * 24));
    let weeksAgo = Math.floor((timebetween / (1000 * 3600 * 24)) / 7);
    let daysAgo = diffDays % 7;
    if (weeksAgo > 0 || daysAgo > 0) {
      dateStr += " (";
      if (weeksAgo > 0) {
        dateStr += weeksAgo + " weeks";
        if (daysAgo > 0)
          dateStr += ", " + daysAgo + " days";
      } else {
        dateStr += daysAgo + " days";
      }
      dateStr += " ago)";
    }

    //console.log("encounterDate", dateStr);

    return dateStr;
  }
  getBrandList(brands: any) {
    return brands.toString().replace(/,/gi, " or ");
  }

  copyRxs(prescriptions = []) {
    console.log("Visits copyRxs", prescriptions);
    this.copyRxsClick.emit({ prescriptions: prescriptions });
  }

  copyRx(prescription = {}) {
    console.log("Visits copyRx", prescription);
    this.copyRxClick.emit({ prescription: prescription });
  }

  openEncounter(encounter) {
    console.log("Visits openEncounter", encounter);
    this.openEncounterClick.emit({ encounter: encounter });
  }

  deleteEncounter(encounter) {
    console.log("Visits openEncounter", encounter);
    this.deleteEncounterClick.emit({ encounter: encounter });
  }
  viewEMR(encounter) {
    console.log("Visits openEncounter", encounter);
    this.viewImagesClick.emit({ encounter: encounter });
  }

  renderReport(report) {
    var buffer = "";
    switch (report.type) {
      case 'Prescription':
      case 'DrReferral':
        break;
      default:
        buffer += report.type;
        break;
    }
    if (report.isTemplate) {
      let template = report.template;
      if (template.showSectionsInVisits) {
        let sections = template.sections;
        if (template.displayType == 'text') {
          sections.forEach(section => {
            buffer += "\n";
            if (section.display.trim() > "") buffer += section.display + ": ";
            buffer += section.value;
          })
        }
        if (template.displayType == 'checkboxes') {
          sections.forEach(section => {
            if (section.value === true) {
              buffer += "\n";
              if (section.display.trim() > "") buffer += "--" + section.display;
            }

          })
        }
      }
    }
    console.log("renderReport()", report, buffer);
    return buffer;
  }

  copyReport(report = {}) {
    console.log("Visits copyReport", report);
    this.copyReportClick.emit({ report: report });
  }

  openPreview(sourceIndex) {
    this.modalCtrl.create({
      component: ImageModalPage,
      componentProps: {
        sourceIndex
      }
    }).then(modal => modal.present());
  }
}
