import { Component, OnInit } from '@angular/core';
import { Events, ModalController, AlertController } from "@ionic/angular";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { Appointment } from "../appointment";
import { PatientInfo } from "../patient-info";
import { ClinicConfig } from "../config";
import { CalendarPage } from "../calendar/calendar.page";
import { ClinicPage } from '../clinic/clinic.page';
import { RecordsPage } from "../records/records.page";
import { PatientModalPage } from "../patient-modal/patient-modal.page";
import { TeleScriptPage } from "../tele-script/tele-script.page";
import { EventProvider } from "../services/event.service";
import { PatientProvider } from "../services/patient.service";
import { ConfigProvider } from "../services/config.service";
import { RecordsApiProvider } from "../services/records-api.service";
import { PasserService } from "../services/passer.service";

import shortid from 'shortid';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.page.html',
  styleUrls: ['./edit-event.page.scss'],
})
export class EditEventPage {
  //max: any = new Date().toISOString(); min: any = (new Date()).getFullYear() + 2;
  date: any;
  time: any = '08:00';
  patientDisabled: boolean;
  scheduleDisabled: boolean;
  apptTypeDisabled: boolean;
  statusDisabled: boolean;
  complaintDisabled: boolean;
  appointment: Appointment={
    appointmentID: "",
    start: "",
    patientID: "",
    appointmentType:"Tele-consult",
    status: "",
    chiefComplaint: "",
    queueID:"",
    patientInfo:"",
    videoChatLink:"",
    meetingID:"",
    password:"",
    withConsent:"",
    consentID:"",
    clinicEmail:"",
    patientEmail:"",
  };
  patientName: string;
  modalOpen: boolean = false;
  unscheduled: boolean = false;
  queueDisabled: boolean;
  showForm: boolean = false;
  returnModal: boolean = false;
  maxDate: number = 2022;
  minDate: number = 2019;
  todate: any;
  minTime: string="06:00";
  prevPage: string = null;
  queueNo: string;
  clinicInfo:ClinicConfig;
  patientInfo:PatientInfo;

  constructor(
    public eventProvider: EventProvider,
    public patientProvider: PatientProvider,
    public recordApi: RecordsApiProvider,
    public configApi: ConfigProvider,
    public events: Events,
    private modal: ModalController,
    private alert: AlertController,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private passer: PasserService
  ) {
    this.todate = new Date();
    this.minTime = this.todate.getHours() + ':' + this.todate.getMinutes().toString().padStart(2, '0');
    console.log("minTime", this.minTime);
    this.time = this.minTime;
    this.setDate();
    //alert(this.addDisabled + " " + this.updateDisabled);
    let data = this.passer.retrieve('forEditEvent');

    if(data['clinicInfo']){
      console.log("clinicInfo from data", data['clinicInfo']);
      this.clinicInfo=Object.assign({}, data['clinicInfo']);
    }else{
      console.log("clinicInfo from service", this.clinicInfo);
      this.clinicInfo=Object.assign({}, this.configApi.clinic);
    }

    console.log("edit-event params", data);
    if (data) {
      if (data['prevPage']) {
        this.prevPage = data['prevPage'];
      }
      // check for returnModal
      if (data['returnModal']) {
        this.returnModal = data['returnModal'];
      }
      // check for appointment info
      if (data["appointment"]) {
        this.patientDisabled = true;
        this.scheduleDisabled = true;
        this.queueDisabled = true;
        this.apptTypeDisabled = true;
        this.queueDisabled = true;
        this.statusDisabled = true;
        this.complaintDisabled = true;

        console.log("got data appointment", data['appointment']);

        this.appointment =Object.assign({},data["appointment"]);

        this.queueNo = `${this.appointment.queueNo}`;

        let split = this.appointment.start.split(" ", 2);
        let date = split[0].split("/", 3);
        this.date = `${date[0]}-${date[1]}-${date[2]}`;
        if (split[1] == null) {
          this.time = '08:00';
          this.unscheduled = true;
        } else {
          this.time = split[1];
        }
        if (data["patient"]) {
          let patient: PatientInfo = data["patient"];
          this.patientName = `${patient.firstname} ${patient.lastname}`;
          this.patientInfo = Object.assign({},patient);
        }
      } else {
        console.log("*********************************");

        this.patientDisabled = false;
        this.scheduleDisabled = false;
        this.apptTypeDisabled = false;
        this.statusDisabled = true;
        this.complaintDisabled = false;

        let timestamp = new Date();
        let appt: Appointment = {
          appointmentID: timestamp.getTime().toString(),
          start: null,
          patientID: null,
          appointmentType: "Walk-in",
          status: "Open"
        };
        this.appointment = appt;

        // check if there are preset values
        if (data["patientID"]) {
          this.appointment.patientID = data["patientID"];
          // hmm why is this here.
          this.appointment.appointmentType = "Follow-up";
        }
        if(data['patientName']){
          this.patientName = data["patientName"];
        }
      }
    }

  }

  ionViewDidEnter(){
    console.log("EditEventPage: DidEnter", this.appointment);
    // somehow the tele-consult fields were not populated because they were hidden, re-populate
    if(this.appointment.appointmentType=='Tele-consult'){
      console.log("re-populating");
      Object.assign(this.appointment, this.appointment);
    }
  }

  setDate() {
    let timestamp = new Date();

    let mon: string = (timestamp.getMonth() + 1).toString();
    if (mon.length == 1) {
      mon = "0" + mon;
    }

    let date: string = timestamp.getDate().toString();
    if (date.length == 1) {
      date = "0" + date;
    }

    this.date = timestamp.getFullYear() + "-" + mon + "-" + date;
    this.maxDate = timestamp.getFullYear() + 2;
    this.minDate = timestamp.getFullYear();
  }

  setDateTime(event=null){
    var dateStr="";
    console.log("setDateTime",this.date);
    if(this.date.length <= 10){
      dateStr = this.date.substring(0, 10);
      dateStr = dateStr.replace(/-/gi, "/");
    }else{
      let backToDate = new Date(this.date);
      dateStr = backToDate.getFullYear() + "/" + ('' + (backToDate.getMonth()+1)).padStart(2,'0') + "/" + ('' + backToDate.getDate()).padStart(2,'0');
    }
    console.log("setDateTime", dateStr);
    let timestamp: string;

    if (this.unscheduled == true) {
      timestamp = dateStr;
    } else {
      this.checkMinTime(dateStr, this.time); // check for valid time
      // then finalize the timestamp
      timestamp = dateStr + " " + this.time;
    }
    console.log("setDateTime timestamp", timestamp);
    if (this.appointment.patientID > "")
      this.eventProvider.updateEvent(this.appointment, timestamp);
  }

  checkMinTime(dateStr = "", time = ""){
    console.log("checkMinTime()",dateStr + ' ' + time);
    let endOfTodate = new Date();
    endOfTodate.setHours(23,59,59);
    let checkDate = new Date(dateStr + ' ' + time);
    console.log("Compare ", checkDate.getTime(), checkDate.toString());
    if(endOfTodate.getTime() > checkDate.getTime()){
      if(this.todate.getTime() > checkDate.getTime()){
        // check if date is todate
          // if date is todate check if time is < current time
          // if time is < current time, then set time = current time
          // show a toast to let them know you are changing the time
        this.showPastTimeAlert();
      }
    }
  }

  async showPastTimeAlert() {
    let promptAlert = await this.alert.create({
      header: "Appt time has passed",
      message: "You set an appointment time that has already passed?",
      buttons: [
        {
          text: "OK",
          handler: ()=> {
            this.time = this.minTime;
          }
        }
      ]
    });
    promptAlert.present();
  }

  changedType(event){
    if(this.appointment.appointmentType=='Tele-consult'){
      this.queueNo="";
      this.unscheduled = false;
      this.queueDisabled = true;
      this.generateVideoChatLink();
    }
  }

  apptForm(saveonly=false) {

    this.appointment.queueNo = this.queueNo ? parseInt(this.queueNo, 10) : null;

    if(!saveonly){
      // then save and close
      if (this.returnModal) {
        this.modal.dismiss(this.appointment);
      } else {
        this.location.back();
      }
    }

    var dateStr="";
    console.log("setDateTime",this.date);
    if(this.date.length <= 10){
      dateStr = this.date.substring(0, 10);
      dateStr = dateStr.replace(/-/gi, "/");
    }else{
      let backToDate = new Date(this.date);
      dateStr = backToDate.getFullYear() + "/" + ('' + (backToDate.getMonth()+1)).padStart(2,'0') + "/" + ('' + backToDate.getDate()).padStart(2,'0');
    }
    console.log("setDateTime", dateStr);
    let timestamp: string;

    if (this.unscheduled == true) {
      timestamp = dateStr;
    } else {
      timestamp = dateStr + " " + this.time;
    }
    console.log("setDateTime", timestamp);
    if (this.appointment.patientID > "")
      this.eventProvider.updateEvent(this.appointment, timestamp);

  }

  async showSearch() {
    let patientModal = await this.modal.create({
      component: PatientModalPage,
      componentProps: {
        recordApi: this.recordApi
      }
    });

    if (this.modalOpen == false) {
      patientModal.present();
      this.modalOpen = true;
      let { data } = await patientModal.onDidDismiss();
      console.log("patient search returned ", data);
      this.modalOpen = false;
      if (data != undefined) {
        let patient = data;
        this.appointment['patientID'] = patient['patientID'];
        this.patientName = patient['firstname'] + " " + patient['lastname'];
        this.patientInfo = Object.assign({},patient);
      }
    }

  }

  update() {
    this.patientDisabled = true;
    this.scheduleDisabled = false;
    this.apptTypeDisabled = true;

    this.statusDisabled = false;
    this.complaintDisabled = false;

    if (this.unscheduled === true) {
      this.queueDisabled = false;
    }

    this.prevPage = 'updateEvent';
  }

  queueNoToString() {
    return `${this.appointment.queueNo}`;
  }

  toggleQueueNoField() {
    this.queueDisabled = !this.unscheduled;
    this.appointment.queueNo = null;
  }

  async showConfirmAlert() {
    let confirmAlert = await this.alert.create({
      header: "Delete Appointment",
      message: "Are you sure want to delete this appointment?",
      buttons: [
        {
          text: "No",
          role: "cancel"
        },
        {
          text: "Yes",
          handler: () => {
            this.remove();
          }
        }
      ]
    });
    confirmAlert.present();
  }

  remove() {
    this.eventProvider.removeEvent(this.appointment);
    if (this.returnModal) {
      this.modal.dismiss();
    } else {
      this.location.back()
    }

  }

  cancel() {
    if (this.returnModal) {
      this.modal.dismiss(this.appointment);
    } else {
      if (this.statusDisabled) {
        this.location.back();
      } else {
        this.prevPage = 'viewEvent';
        this.patientDisabled = true;
        this.scheduleDisabled = true;
        this.queueDisabled = true;
        this.apptTypeDisabled = true;
        this.statusDisabled = true;
        this.complaintDisabled = true;
      }
    }
  }

  async showConflictAlert() {
    let conflictAlert = await this.alert.create({
      header: "Conflicting Appointment",
      subHeader: "An appointment with the same schedule exists.",
      buttons: ["Dismiss"]
    });
    conflictAlert.present();
  }

  /////////////// Creating a new patient functions ////////////////
  showNewPatientForm() {
    this.showForm = true;
    this.recordApi.resetRecord();
  }

  clearForms() {
    this.showForm = false;
  }

  addNewPatientRecord() {
    this.recordApi.addRecord().then(
      patient => {
        this.showForm = false;
        this.appointment.patientID = patient.patientID;
        this.patientName = patient["firstname"] + " " + patient["lastname"];
        this.patientInfo = Object.assign({},patient);
      },
      err => {
        console.log("Could not add new patient", err);
        this.showForm = false;
      }
    );
  }

  sendPreEmails(){
    // need to get clinic Email
    let clinicEmail="";
    console.log("sendPreEmails", this.clinicInfo);
    if(this.appointment.clinicEmail !== undefined && this.appointment.clinicEmail > ""){
      clinicEmail = this.appointment.clinicEmail;
    }else if(this.clinicInfo !== undefined && this.clinicInfo.contactInfo.email != ""){
      clinicEmail=this.clinicInfo.clinicName + '<' + this.clinicInfo.contactInfo.email + '>';
      this.appointment.clinicEmail = clinicEmail;
    }else{
      // show toast that clinic email is not set
      this.showAlertMessage('Clinic email is not set', 'Cannot send')
      return;
    }
    // need to get email of patient
    let patientEmail = "";
    console.log("sendPreEmails", this.patientInfo);
    if(this.appointment.patientEmail !== undefined && this.appointment.patientEmail > ""){
      patientEmail = this.appointment.patientEmail;
    }else if(this.patientInfo !== undefined && this.patientInfo.contactInfo.email != ""){
      patientEmail = this.patientInfo.contactInfo.email;
      this.appointment.patientEmail = patientEmail;
    }else {
      // show toast that patient email is not set
      this.showAlertMessage("Patient email is not set. Select patient and set email", 'Cannot send');
      return;
    }
    // need to set date and time of appointment
    if(this.appointment.start == null){
      this.setDateTime();
    }


    // mailer templates are stored in the firestore database under mailer_templates
    //   - Advice
    //   - instructions
    //   - consent
    // should set the header to clinicEmail and patientEmail
    //, jaybee.reyes@gmail.com,jdomino@gmail.com.a.ravago@gmail.com
    if(this.appointment.consentID === undefined || this.appointment.consentID==""){
      shortid.seed(2020);
      let consentID = shortid.generate();
      this.appointment.consentID = consentID;
      if (this.appointment.patientID > "")
        this.setDateTime();
    }
    let header = {
      to:patientEmail,
      replyTo:clinicEmail,
      from:clinicEmail,
      template:{}
    }
    let params = {
      clinicEmail:clinicEmail,
      patientEmail:patientEmail,
      date:this.date,
      time:this.time,
      datetime:this.appointment.start,
      consentLink:"https://tinyurl.com/s3bv63c/submitConsent?cid="+this.appointment.consentID
    }
    console.log("sendPreEmails: Appointment info:", this.appointment);
    this.recordApi.sendMailer(header, {template: { name:'advice', data:params}}); // send advice
    this.recordApi.sendMailer(header, {template: { name:'consent', data:params}}); // send consent
    this.showAlertMessage("Two (2) emails have been sent from " + clinicEmail + " to " + patientEmail,"Sent pre consult emails");
  }

  generateVideoChatLink(){
    let videoChatID = 'TeleConsult' + (shortid.generate() + shortid.generate()).substring(0,10);  // + shortid.generate();
    this.appointment.videoChatLink = 'https://meet.jit.si/' + videoChatID;
    this.appointment.meetingID = videoChatID;
    this.appointment.password = 'none';

  }
  openVideoChat(){
    window.open(this.appointment.videoChatLink,'_system', 'location=yes');
  }

  sendVideoChatLink(){
    // need to get clinic Email
    let clinicEmail="";
    console.log("sendVideoChatLink", this.clinicInfo);
    if(this.appointment.clinicEmail !== undefined && this.appointment.clinicEmail > ""){
      clinicEmail = this.appointment.clinicEmail;
    }else if(this.clinicInfo !== undefined && this.clinicInfo.contactInfo.email != ""){
      clinicEmail=this.clinicInfo.clinicName + '<' + this.clinicInfo.contactInfo.email + '>';
      this.appointment.clinicEmail = clinicEmail;
    }else{
      // show toast that clinic email is not set
      this.showAlertMessage('Clinic email is not set', 'Cannot send')
      return;
    }
    // need to get email of patient
    let patientEmail = "";
    console.log("sendVideoChatLink", this.patientInfo);
    if(this.appointment.patientEmail !== undefined && this.appointment.patientEmail > ""){
      patientEmail = this.appointment.patientEmail;
    }else if(this.patientInfo !== undefined && this.patientInfo.contactInfo.email != ""){
      patientEmail = this.patientInfo.contactInfo.email;
      this.appointment.patientEmail = patientEmail;
    }else {
      // show toast that patient email is not set
      this.showAlertMessage("Patient email is not set. Select patient and set email", 'Cannot send');
      return;
    }
    // need to set date and time of appointment
    if(this.appointment.start == null){
      this.setDateTime();
    }


    // mailer templates are stored in the firestore database under mailer_templates
    //   - Advice
    //   - instructions
    //   - consent
    // should set the header to clinicEmail and patientEmail
    //, jaybee.reyes@gmail.com,jdomino@gmail.com.a.ravago@gmail.com
    let header = {
      to:patientEmail,
      replyTo:clinicEmail,
      from:clinicEmail,
      template:{}
    }
    let params = {
      clinicEmail:clinicEmail,
      patientEmail:patientEmail,
      date:this.date,
      time:this.time,
      datetime:this.appointment.start,
      meetingID:this.appointment.meetingID,
      password:this.appointment.password,
      videoChatLink:this.appointment.videoChatLink
    }
    console.log("sendPreEmails: Appointment info:", this.appointment);
    this.recordApi.sendMailer(header, {template: { name:'instructions', data:params}}); // send instructions
    this.showAlertMessage("One (1) email has been sent from " + clinicEmail + " to " + patientEmail,"Sent video chat link");
  }

  async showVideoLinkInstructions(){
    let telescriptModal = await this.modal.create({
      component: TeleScriptPage,
      componentProps:{mode:"video link"},
      backdropDismiss: false
    });

    telescriptModal.present();
  }

  async showAlertMessage(message, header="Error"){
    let msgAlert = await this.alert.create({
      header: header,
      message: message,
      buttons: [
        {
          text: "OK"
        }
      ]
    });
    msgAlert.present();
  }
}
