import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasserService {
  public forRecords:any = {
    mode:"",
    patient:null
  }
  public forEditEvent:any = {};
  public forEncounter:any = {};
  public forRecordForm:any={};
  public forViewPatientQueue:any={};
  public forNewChargeSlip:any={};
  
  constructor() { }

  assign(fieldname=null, value=null){
    if(fieldname != null){
      this.reset(fieldname);
      Object.assign(this[fieldname], value);
    }
  }

  // reset a value
  reset(fieldname){
    if(fieldname != null)
      this[fieldname]={};
  }

  // retrieve and reset a value
  retrieve(fieldname){
    let value = Object.assign({}, this[fieldname]);
    this.reset(fieldname);
    return value;
  }
}
