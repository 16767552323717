import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
//import { Transfer, TransferObject } from '@ionic-native/transfer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { EncounterInfo, EMRImage } from '../encounter-info';
import { PatientInfo } from '../patient-info';
import { BillingInfo, ChargeSlip } from '../billing-info';
import { Md5 } from 'ts-md5/dist/md5';
import uuid from 'uuid';
import FlexSearch from 'flexsearch';

export interface EncounterHistory {
  patientID: string,
  encounterID: string,
  timestamp: number
}

@Injectable({
  providedIn: 'root'
})
export class RecordsApiProvider {
  afsPath: string = "";
  patient: PatientInfo = null;
  encounter: EncounterInfo = null;
  // afs variables
  private recordsCollection: AngularFirestoreCollection<PatientInfo>;
  private encountersCollection: AngularFirestoreCollection<EncounterInfo>;
  myRecords: Observable<PatientInfo[]>;
  searchResults: any = [];
  searchRecords: any = [];
  uploadPercent: Observable<number>;
  dataDirectory: string = "";
  emrImages: Array<EMRImage>;
  imageSources: any[] = [];
  recordsPoller: any = null;
  win: any = window;
  isApp: boolean = false;
  isAppEvaluate: string = "";
  recordsIndex = FlexSearch.create({
    doc: {
      id: 'patientID',
      field: [
        'firstname',
        'lastname',
        'middlename',
        'patientID',
        'age',
        'gender',
        'contactInfo',
        'lastUpdate'
      ]
    }
  });

  constructor(
    private storage: Storage,
    private afs: AngularFirestore,
    private firestorage: AngularFireStorage,
    private transfer: FileTransfer,
    private file: File,
    private platform: Platform,
    private sanitizer: DomSanitizer) {
    console.log('Records Api Provider');
    platform.ready().then(() => {
      //this.isApp = (!document.URL.startsWith('http') || document.URL.startsWith('http://localhost:8080'));
      if (document.URL.startsWith('http') && document.URL.indexOf('localhost') < 0) {
        this.isApp = false;
        console.log("isApp: is served from a browser");
      } else if (this.platform.is('electron')) {
        this.isApp = false;
        console.log("isApp: is served from an electron browser");
      } else if (this.platform.is('cordova') && this.platform.is('ios')) {
        this.dataDirectory = file.dataDirectory;
        this.isApp = false;
      } else if (this.platform.is('cordova') && this.platform.is('android')) {
        this.dataDirectory = file.dataDirectory;
        this.isApp = true;
      }
      console.log("isApp:",
        'cordova:', this.platform.is('cordova'),
        'desktop:', this.platform.is('desktop'),
        'mobileweb:', this.platform.is('mobileweb'),
        'pwa:', this.platform.is('pwa'),
        'electron:', this.platform.is('electron'),
      );
      if (document.URL.startsWith('http') && document.URL.indexOf('localhost') < 0) this.isAppEvaluate += " is served from a browser";
      if (this.platform.is('electron')) this.isAppEvaluate += " is electron :";
      if (this.platform.is('cordova')) this.isAppEvaluate += " is cordova :";
      if (this.platform.is('ios')) this.isAppEvaluate += " is ios :";
      if (this.platform.is('android')) this.isAppEvaluate += " is android :";
      if (this.platform.is('desktop')) this.isAppEvaluate += " is desktop :";
      if (this.platform.is('mobileweb')) this.isAppEvaluate += " is mobileweb :";
      if (this.platform.is('pwa')) this.isAppEvaluate += " is pwa :";
    });
    this.resetRecord();
  }

  // Each records collection is attached to a clinic. Set the path to the clinic

  async setPath(clinicPath) {
    console.log("RecordsAPI: setPath(): start", clinicPath);
    if (clinicPath == '') return;
    if (clinicPath.substr(clinicPath.length - 1) == '/') {
      console.log("RecordsAPI: setPath(): invalid clinic id", clinicPath);
      return;
    }



    if (this.afsPath != clinicPath) {
      if (this.afsPath != '') {
        // call clearLocalData() only if we are changing clinics
        // not if we are loading for the first time
        console.log("RecordsAPI: setPath(): call clearLocalData()");
        await this.clearLocalData();
        console.log("RecordsAPI: setPath(): done clearLocalData()");
      }
      this.afsPath = clinicPath;
      console.log("RecordsAPI: setPath(): clinicPath", this.afsPath, "/records");
      this.recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
      this.myRecords = this.recordsCollection.valueChanges();
      // cleanup and seed searchRecords
      //this.searchRecords = [];
      if (this.recordsPoller)
        this.recordsPoller.unsubscribe();
      // clear the recordsIndex();
      let oldIndex = this.recordsIndex;
      oldIndex.destroy();
      this.recordsIndex = FlexSearch.create({
        doc: {
          id: 'patientID',
          field: [
            'firstname',
            'lastname',
            'middlename',
            'patientID',
            'age',
            'gender',
            'contactInfo',
            'lastUpdate'
          ]
        }
      });
      // console.log("Records Index Cleared", this.recordsIndex.info());
      //this.seedSearchRecords().then(seeded => { // convert this to syncRecords
      this.fixRecords().then(fixed => { // convert this to syncRecords
        console.log("RecordsAPI: setPath(): fixed records");
      }, err => {
        console.log("RecordsAPI: setPath(): error while fixing records", err);
      })

      this.seedRecordsIndex();
      // this.syncRecords() is called by seedRecordsIndex();

      this.resetRecord();
    }
  }

  async clearLocalData() {
    // clear stored patients
    console.log("RecordsAPI: clearLocalData(): clearing patients")
    await this.clearPatientData();
    console.log("RecordsAPI: clearLocalData(): cleared patients")
    // clear stored images
    console.log("RecordsAPI: clearLocalData(): clearing EMR")
    await this.clearEMRData();
    console.log("RecordsAPI: clearLocalData(): cleared EMR")
  }

  async clearPatientData() {
    console.log("RecordsAPI: clearPatientData(): starting")
    try {
      let result = await this.getAllPatientKeys();
      console.log("RecordsAPI: clearPatientData(): got ", result);
      if (result['length'] > 0) {
        let patientKeys = result['patientKeys'];
        var i = 0;
        while (i < result['length']) {
          await this.deletePatientData(patientKeys[i]);
          i++;
        }
      }
      console.log("RecordsAPI: clearPatientData(): done")
    } catch (err) {
      console.log("RecordsAPI: clearPatientData(): error", err); // TypeError: failed to fetch
    }

  }

  async clearEMRData() {
    console.log("RecordsAPI: clearEMRData(): starting")
    try {
      let result = await this.getAllEMRKeys();
      let patientKeys = result['patientKeys'];
      console.log("RecordsAPI: clearEMRData(): got ", patientKeys);
      if (result['length'] > 0) {
        var i = 0;
        while (i < result['length']) {
          await this.deleteEMRData(patientKeys[i]);
          i++;
        }
      }
      console.log("RecordsAPI: clearEMRData(): done")
    } catch (err) {
      console.log("RecordsAPI: clearEMRData(): error", err); // TypeError: failed to fetch
    }

  }

  resetRecord() {
    let patientUUID = uuid.v4();
    this.patient = {
      patientID: patientUUID,
      firstname: "",
      middlename: "",
      lastname: "",
      nickname: "",
      gender: "",
      birthdate: "",
      age: "",
      placeOfBirth: "",
      civilStatus: "",
      religion: "",
      affiliation: "",
      employed: false,
      employerName: "",
      occupation: "",
      philhealth: "",
      sss: "",
      gsis: "",
      contactInfo: {
        line1: "",
        line2: "",
        barangay: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        cellphone: "",
        telephone: "",
        fax: ""
      },
      nearestRelative: "",
      relation: "",
      relativeContactInfo: {
        line1: "",
        line2: "",
        barangay: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        cellphone: "",
        telephone: "",
        fax: ""
      },
      profilePic: null,
      allergies: [],
      comorbids: [],
      medcondition: [],
      medhistory: [],
      vitalsTaken: [{
        vitals: [],
        timestamp: 0
      }]
    }
  }

  getBirthdate(){
    if (this.patient.birthdate === undefined || this.patient.birthdate == '') {
      return "";
    } else {
      var birth_date = new Date();
      if(typeof(this.patient.birthdate) == 'string'){
        birth_date = new Date(this.patient.birthdate);
      }else {
        let fireDate:Timestamp = this.patient.birthdate;
        console.log("Type of birthdate 1",typeof(this.patient.birthdate), typeof(fireDate));
        birth_date = fireDate.toDate();
      }
      return birth_date.toDateString();
    }
  }

  computeAge(birthdate = "none set") {
    if (birthdate == "none set") {
      var current_date = new Date();

      if (this.patient.birthdate === undefined || this.patient.birthdate == '') {
        this.patient.age = '';
        return;
      } else {
        var birth_date = new Date();
        console.log("patient.birthDate", this.patient.birthdate);
        if(typeof(this.patient.birthdate) == 'string'){
          birth_date = new Date(this.patient.birthdate);
        }else {
          let fireDate:Timestamp = this.patient.birthdate;
          console.log("Type of birthdate 2",typeof(this.patient.birthdate), typeof(fireDate));
          birth_date = fireDate.toDate();
        }
        let yrs_old = current_date.getFullYear() - birth_date.getFullYear();
        let mos_old = current_date.getMonth() - birth_date.getMonth();
        console.log("RecordsAPI: Computing age", this.patient.birthdate, birth_date.toString());
        if (mos_old < 0) {
          yrs_old -= 1;
          mos_old = 12 + mos_old;
        }
        console.log("RecordsAPI:  Yrs old as ", yrs_old, mos_old);
        this.patient.age = yrs_old + " yrs";
        if (mos_old > 0) {
          this.patient.age += " and " + mos_old + " mos";
        }
        this.patient.age += ' old';
        console.log("RecordsAPI: Age ", this.patient.age);
      }
    } else {
      var current_date = new Date();
      if (birthdate === undefined || birthdate == '' || birthdate === null) {
        return "missing birthdate";
      } else {

        let birth_date = new Date(birthdate);
        let yrs_old = current_date.getFullYear() - birth_date.getFullYear();
        let mos_old = current_date.getMonth() - birth_date.getMonth();
        //console.log("RecordsAPI: Computing age", birthdate, birth_date.toString());
        if (mos_old < 0) {
          yrs_old -= 1;
          mos_old = 12 + mos_old;
        }
        //console.log("RecordsAPI:  Yrs old as ", yrs_old, mos_old);
        let age = yrs_old + " yrs";
        if (mos_old > 0) {
          age += " and " + mos_old + " mos";
        }
        age += ' old';
        //console.log("RecordsAPI: Age ", age);
        return age;
      }
    }
  }

  addRecord(record: PatientInfo = null): Promise<PatientInfo> {
    if (record === null)
      record = this.patient;
    console.log("RecordsAPI: Add Patient Info", record);
    let toDate = new Date();
    Object.assign(record, { createDate: toDate.getTime() });
    return new Promise((resolve, reject) => {
      this.recordsCollection.add(record).then(newRecord => {
        console.log("RecordsAPI: New Record", newRecord);
        this.patient.patientID = newRecord.id;
        this.blankEncounter(); // make an encounter for this patient
        this.updateRecord(this.patient);
        resolve(this.patient);
      }, err => {
        reject(err);
        console.log("RecordsAPI: could not add new Patient Info");
      })
    })

  }

  // update the information of a clinic
  updateRecord(record: PatientInfo = null) {
    if (record === null) record = this.patient;
    console.log("RecordsAPI: Update Patient Info", record);
    let toDate = new Date();
    Object.assign(record, { lastUpdate: toDate.getTime() });
    if (record.createDate === undefined) {
      Object.assign(record, { createDate: toDate.getTime() });
    }
    return new Promise((resolve, reject) => {
      if (record.patientID == "") {
        console.log("RecordsAPI: Nothing to do not yet", record);
        resolve("nothing to be done"); // no need to update because not yet saved
      } else {
        console.log("RecordsAPI: saving", record);
        let recordDoc = this.afs.doc<PatientInfo>(this.afsPath + '/records/' + record.patientID);
        recordDoc.set(record).then(all_set => {
          //this.encountersCollection = this.afs.collection<EncounterInfo>(this.afsPath + '/records/' + record.patientID +'/encounters');
          resolve("done");
        }, err => {
          console.log("Error in setting record")
          reject("err");
        });
      }
    })

  }

  // update the information of a clinic
  deleteRecord(record: PatientInfo = null) {
    if (record === null) record = this.patient;
    console.log("RecordsAPI: Delete Patient Info", record);
    return new Promise((resolve, reject) => {
      if (record.patientID == "") {
        console.log("RecordsAPI: Nothing to do not yet", record);
        resolve("nothing to be done"); // no need to update because not yet saved
      } else {
        console.log("RecordsAPI: deleting", record);
        let recordDoc = this.afs.doc<PatientInfo>(this.afsPath + '/records/' + record.patientID);
        recordDoc.delete().then(all_done => {
          console.log("deleted record", record)
          resolve({ result: "done" });
        }, err => {
          console.log("Error in deleting record", err)
          reject(err);
        });
      }
    })

  }

  // select a clinic and set the form to this clinic
  selectRecord(patientID): Promise<PatientInfo> {
    console.log('Record: selectRecord()', patientID);
    return new Promise((resolve, reject) => {
      let recordDoc = this.afs.doc<PatientInfo>(this.afsPath + '/records/' + patientID);
      recordDoc.valueChanges().pipe(take(1)).subscribe(record => {
        console.log("RecordsAPI: Patient Info", record);
        //clinicDoc.unsubscribe();
        if (record) {
          this.patient = record;
          this.computeAge();
          console.log("RecordsAPI: Patient", this.patient);
          this.encountersCollection = this.afs.collection<EncounterInfo>(this.afsPath + '/records/' + record.patientID + '/encounters', ref => ref.orderBy('createTime', 'desc'));
          resolve(record);
        } else {
          console.log("Could not load record: undefined", patientID);
          reject({ result: "could not load record", patientID: patientID })
        }
      }, err => {
        reject({ result: "could not load record", error: err })
      });
    })

  }
  // select a clinic and set the form to this clinic
  retrieveRecord(patientID): Promise<PatientInfo> {
    console.log('Record: retrieveRecord()', patientID);
    return new Promise((resolve, reject) => {
      let recordDoc = this.afs.doc<PatientInfo>(this.afsPath + '/records/' + patientID);
      recordDoc.valueChanges().pipe(take(1)).subscribe(record => {
        console.log("RecordsAPI: Retrieved Patient Info", record);
        if (record) {
          if (record['firstname'] == "" && record['lastname'] == "") {
            // its a blank record, consider deleting
            console.log("Record: retrieveRecord(), got a blank, consider deleting", patientID);
            reject({ result: "its a blank record, do not process", patientID: patientID })
          } else {
            if (record['birthdate'] != "") this.computeAge();
            // retrieve encounters  ==> retrieve images
            this.profilePic(record); // check if profilePic is set , this is for old records
            resolve(record);
          }
        } else {
          console.log("Could not retrieve record: undefined", patientID);
          reject({ result: "could not retrieve record", patientID: patientID })
        }
      }, err => {
        reject({ result: "could not load record", error: err })
      });
    })

  }

  // set the profile pic of the patient
  profilePic(patientInfo = null, profilePic = null) {
    if (patientInfo === null)
      patientInfo = this.patient;
    if (profilePic != null || patientInfo['profilePic'] === undefined) {
      let currentProfilePic = Object.assign({}, { patientID: patientInfo.patientID, lastname: patientInfo.lastname, profilePic: patientInfo.profilePic });
      console.log("profilePic(): updating the record", profilePic, currentProfilePic);
      Object.assign(patientInfo,
        { profilePic: profilePic }
      );
      this.updateRecord(patientInfo);
    } else {
      profilePic = patientInfo.profilePic;
      if (profilePic != null) {
        this.checkEMRScan(profilePic).then(gotPic => {
          console.log("profilePic(): Loaded the PIC", patientInfo.lastname);
        }, err => {
          console.log("profilePic(): error loading the PIC", patientInfo.lastname)
        })
      }
    }
  }

  // determine a proper profilePic src path for the pages
  profilePicPath(profilePic = null) {
    if (profilePic === null)
      profilePic = this.patient.profilePic;
    console.log("profilePicPath()", profilePic);
    return new Promise((resolve, reject) => {
      if (profilePic != null) {
        if (this.isApp) {
          let url = this.file.dataDirectory + profilePic.filename;
          console.log("profilePicPath(): on device, sending path=", url);
          resolve({ result: 'ok', picPath: this.win.Ionic.WebView.convertFileSrc(url) });
        } else {
          // we are on a browser
          // first try the local Cache
          this.storage.get(profilePic.storageName).then(dataURL => {
            var picPath: any;
            if (dataURL) {
              console.log("profilePicPath(): assigning image from storage: ", profilePic.storageName);
              picPath = this.sanitizer.bypassSecurityTrustUrl(dataURL);
              //picPath = dataURL;
              resolve({ result: 'ok', picPath: picPath });
            } else {
              // next try direct URL
              console.log("profilePicPath(): No dataURL sending filename: ", profilePic.filename);
              picPath = this.sanitizer.bypassSecurityTrustUrl(this.file.dataDirectory + profilePic.filename);
              //picPath = profilePic.filename;
              reject({ result: "error", message: "no profile pic defined" });
              //resolve({ result: 'ok', picPath: this.sanitizer.bypassSecurityTrustUrl(profilePic.filename) });
            }
          },
            err => {
              console.log("profilePicPath(): No such image from storage, sending filename: ", profilePic.filename);
              reject({ result: "error", message: "no profile pic defined" });
              //resolve({ result: 'ok', picPath: this.sanitizer.bypassSecurityTrustUrl(profilePic.filename) });
            }
          );  // this.storage.get
        }
      } else {
        reject({ result: "error", message: "no profile pic defined" });
      }
    });

  }

  modifySearchRecord(patientID, patientRecord, changeType) {
    switch (changeType) {
      case "added":
        this.recordsIndex.add(patientRecord);
        //console.log("RecordsAPI: syncRecords(): added", patientRecord,this.recordsIndex.info());
        break;
      case "removed":
        this.recordsIndex.remove(patientID);
        break;
      default:
        this.recordsIndex.remove(patientID);
        this.recordsIndex.add(patientRecord);
        break;
    }
  }



  fixRecords(lastRefDate = 0) { // specify a blank lastRefDate for full sync
    console.log("RecordsAPI: fixRecords()", this.afsPath);
    return new Promise((resolve, reject) => {
      let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
      if (recordsCollection === undefined) {
        console.log("RecordsAPI: fixRecords(): nothing to fix");
        reject({ error: "nothing to seed" });
      } else {
        console.log("fixing clinic records");
        let poller = recordsCollection.valueChanges().subscribe((records) => {
          console.log("RecordsAPI: fixRecords(): records", records.length);
          let recordHeaders = []
          records.forEach(record => {
            if (record.lastUpdate === undefined) {
              console.log("RecordsAPI: fixRecords(): Update Record", record);
              this.updateRecord(record).then(ok => { console.log("updated") }, err => { console.log("not updated") });
            } else {
              //console.log("RecordsAPI: fixRecords(): Record OK", record);
            }
          });
          poller.unsubscribe();
          resolve({ result: 'done', fixed: records.length });
        }, err => {
          console.log("RecordsAPI: fixRecords(): error while fixing", err);
          reject({ error: "could not fix" });
        });
      }
    });
  }

  seedRecordsIndex() {
    this.getAllPatientKeys().then(result => {
      let patientKeys = result['patientKeys'];
      //console.log("RecordsAPI: seedRecordsIndex()", patientKeys);
      let maxUpdate = 0;
      patientKeys.forEach(async (key, index) => {
        let patientInfo = await this.getPatientData(key);
        //console.log("RecordsAPI: seedRecordsIndex() got ", patientInfo, key)
        this.modifySearchRecord(patientInfo.patientID, patientInfo, "added");
        if (maxUpdate < patientInfo.lastUpdate) {
          maxUpdate = patientInfo.lastUpdate;
        }
        if (index == (result['length'] - 1)) {
          this.syncRecords(maxUpdate).then(seeded => { // incremental sync
            console.log("RecordsAPI: seedRecordsIndex(): seeded search records");
          }, err => {
            console.log("RecordsAPI: seedRecordsIndex(): error while seeding search records", err);
          });
        }
      });
    }, err => {
      console.log("RecordsAPI: seedRecordsIndex() : No stored patients");
      this.syncRecords().then(seeded => { // full sync
        console.log("RecordsAPI: seedRecordsIndex(): seeded search records");
      }, err => {
        console.log("RecordsAPI: seedRecordsIndex(): error while seeding search records", err);
      });
    });
  }

  async storePatientData(patientInfo) {
    let prefix = "VCPT_";
    let key = prefix + patientInfo.patientID;
    const res = await this.storage.set(key, patientInfo);
    // console.log("RecordsAPI: storePatientData():",res);
  }

  async deletePatientData(patientID) {
    let prefix = "VCPT_";
    let key = prefix + patientID;
    const res = await this.storage.remove(key);
    console.log("RecordsAPI: deletePatientData():",patientID);
  }

  async getPatientData(patientID) {
    let prefix = "VCPT_";
    let key = prefix + patientID;
    const keyVal = await this.storage.get(key);
    //console.log('Value is', keyVal);
    return keyVal;
  }

  getAllPatientKeys() {
    // get all the storage keys
    let prefix = "VCPT_";
    // find the ones similar to patient
    return new Promise((resolve, reject) => {
      this.storage.keys().then(allKeys => {
        let patientKeys = [];
        let prefixLength = prefix.length;
        allKeys.forEach(akey => {
          if (akey.startsWith(prefix)) {
            patientKeys.push(akey.substr(prefixLength))
          }
        })
        //console.log("RecordsAPI: getAllPatientKeys() return", patientKeys);
        if (patientKeys.length > 0) {
          resolve({patientKeys:patientKeys,length:patientKeys.length});
        } else {
          reject({patientKeys:patientKeys,length:patientKeys.length});
        }
      });
    })
  }

  syncRecords(lastRefDate = 0) { // specify a blank lastRefDate for full sync
    console.log("RecordsAPI: syncRecords()", lastRefDate);
    return new Promise((resolve, reject) => {
      let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records', ref => ref.where("lastUpdate", ">=", lastRefDate));
      if (recordsCollection === undefined) {
        console.log("syncRecords(): nothing to seed");
        reject({ error: "nothing to seed" });
      } else {
        console.log("syncing clinic records");
        this.recordsPoller = recordsCollection.stateChanges().subscribe((records) => {
          console.log("RecordsAPI: syncRecords(): records", records.length);
          let recordHeaders = []
          records.forEach(record => {
            // store record in storage
            const patient = record.payload.doc.data();
            const patientID = record.payload.doc.id;
            //console.log("RecordsAPI: syncRecords(): Record", record.payload, patient, patientID);
            if (patient.firstname === undefined) {
              console.log("RecordsAPI: syncRecords(): Got erring patient record", patient, record);
            }
            else {
              if ((patient.firstname == "" && patient.lastname == "")) {
                console.log("RecordsAPI: syncRecords(): caught a blank", patientID, patient.firstname, patient.lastname);
                this.deleteRecord(patient);
              } else {
                //console.log("RecordsAPI: syncRecords(): Patient", patient);
                let patientRecord = {
                  firstname: patient.firstname.toLowerCase(),
                  lastname: patient.lastname.toLowerCase(),
                  middlename: patient.middlename.toLowerCase(),
                  patientID: patientID,
                  age: this.computeAge(patient.birthdate),
                  gender: patient.gender,
                  contactInfo: patient.contactInfo,
                  lastUpdate: patient.lastUpdate
                }
                this.modifySearchRecord(patientID, patientRecord, record.type);
                this.storePatientData(patientRecord); // for local sync & backup
              }
            }
          });
          console.log("RecordsAPI: syncRecords(): seeded", this.recordsIndex.info());
          resolve(recordHeaders);
        }, err => {
          console.log("RecordsAPI: syncRecords(): error while seeding", err);
          reject({ error: "could not subscribe to seed" });
        });
      }
    });
  }

  seedSearchRecords() {
    console.log("RecordsAPI: seedSearchRecords()", this.afsPath);
    return new Promise((resolve, reject) => {
      let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
      if (recordsCollection === undefined) {
        console.log("seedSearchRecords(): nothing to seed");
        reject({ error: "noting to seed" });
      } else {
        console.log("seeding via clinicRecords");
        this.recordsPoller = recordsCollection.stateChanges().subscribe((records) => {
          console.log("seedSearchRecords(): records", records.length);
          let recordHeaders = []
          records.forEach(record => {
            const patient = record.payload.doc.data();
            const patientID = record.payload.doc.id;
            //console.log("Record", record.payload, patient, patientID);
            if (patient.firstname === undefined) {
              console.log("Got erring patient record", patient, record.payload.doc.id);
            }
            else {
              if ((patient.firstname == "" && patient.lastname == "")) {
                console.log("seedSearchRecords(): caught a blank", patientID, patient.firstname, patient.lastname);
                this.deleteRecord(patient);
              } else {
                //console.log("Patient", patient);
                let patientRecord = {
                  firstname: patient.firstname.toLowerCase(),
                  lastname: patient.lastname.toLowerCase(),
                  middlename: patient.middlename.toLowerCase(),
                  patientID: patientID,
                  age: this.computeAge(patient.birthdate),
                  gender: patient.gender,
                  contactInfo: patient.contactInfo
                }
                switch (record.type) {
                  case "added":
                    this.recordsIndex.add(patientRecord);
                    break;
                  default:
                    this.modifySearchRecord(patientID, patientRecord, record.type);
                    break;
                }
              }
            }
          });
          console.log("seedSearchRecords(): seeded", this.searchRecords.length);
          resolve(recordHeaders);
        }, err => {
          console.log("seedSearchRecords(): error while seeding", err);
          reject({ error: "could not subscribe to seed" });
        });
      }
    });
  }

  maintainSearchRecords() {
    console.log("RecordsAPI: Maintain search records", this.afsPath);
    let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
    if (recordsCollection === undefined) {
      console.log("nothing to maintain");
    } else {
      console.log("maintaining via clinicRecords");
      this.recordsPoller = recordsCollection.stateChanges();
      this.recordsPoller.subscribe((records) => {
        console.log("maintainSearchRecords: records", records);
        // let recordHeaders = []
        // records.forEach(record =>{
        //   const patient = record.payload.doc.data();
        //   const patientID = record.payload.doc.id;
        //   //console.log("Record", record.payload, patient, patientID);
        //   let patientRecord = {
        //     firstname:patient.firstname.toLowerCase(),
        //     lastname:patient.lastname.toLowerCase(),
        //     middlename:patient.middlename.toLowerCase(),
        //     patientID:patientID,
        //     age:this.computeAge(patient.birthdate)
        //   }
        //   recordHeaders.push(patientRecord);
        // })
      }, err => {
        console.log("error while maintaining", err)

      });
    }

  }

  searchRecordsByName(name = "lahat") {
    name = name.toLowerCase();
    console.log("RecordsAPI: Search records by ", name, this.afsPath);

    return new Promise((resolve, reject) => {
      let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
      if (recordsCollection === undefined) {
        reject("nothing to report");
      } else {

        console.log("searching via searchRecords");

        this.searchResults = this.recordsIndex.where(record => {
          return record['firstname'].indexOf(name) >= 0 || record['lastname'].indexOf(name) >= 0 || name === 'lahat';
        });

        /* this.searchResults = []
          this.searchRecords.forEach(patient => {
            let patientID = patient.patientID;
            //console.log("Record", record.payload, patient, patientID);

            if (patient.firstname.indexOf(name) >= 0 || patient.lastname.indexOf(name) >= 0 || name == 'lahat') {
              // check for valid patientID
              this.searchResults.push(patient);
              //console.log("RecordsAPI: Adding", this.patient.firstname, this.patient.lastname);
            } else {
              // do not add
            }
          })*/
        resolve(this.searchResults);

      }
    });
  }
  // searchRecordsByName(name="lahat"){
  //   name = name.toLowerCase();
  //   console.log("RecordsAPI: Search records by ", name, this.afsPath);
  //
  //   return new Promise((resolve, reject)=>{
  //     let recordsCollection = this.afs.collection<PatientInfo>(this.afsPath + '/records');
  //     if(recordsCollection === undefined) {
  //       reject("nothing to report");
  //     }else{
  //       this.searchResults = [];
  //       console.log("searching via clinicRecords");
  //       let clinicRecords = recordsCollection.snapshotChanges();
  //       clinicRecords.pipe(take(1)).subscribe((records)=>{
  //         console.log("searchRecordsByName: records", records);
  //         this.searchResults=[];
  //         records.forEach(record =>{
  //           const patient = record.payload.doc.data();
  //           const patientID = record.payload.doc.id;
  //           //console.log("Record", record.payload, patient, patientID);
  //           let small_firstname = patient.firstname.toLowerCase();
  //           let small_lastname = patient.lastname.toLowerCase();
  //           if(small_firstname.indexOf(name) >=0 || small_lastname.indexOf(name) >=0 || name == 'lahat'){
  //             // check for valid patientID
  //             if(patient.patientID == ""){
  //               console.log("Updating patientID", patient, patientID);
  //               patient.patientID = patientID;
  //               //this.updateRecord(patient);
  //             }
  //             this.computeAge();
  //             this.searchResults.push(patient);
  //             //console.log("RecordsAPI: Adding", this.patient.firstname, this.patient.lastname);
  //           }else{
  //             // check for valid patientID
  //             if(patient.patientID == ""){
  //               console.log("Updating patientID", patient, patientID);
  //               patient.patientID = patientID;
  //               this.updateRecord(patient);
  //             }
  //           }
  //         })
  //         resolve(this.searchResults);
  //       }, err=>{
  //         console.log("error while searching", err)
  //         reject(err);
  //       });
  //     }
  //   });
  //
  //
  // }

  ///////////////functions to manage patient encounters

  blankEncounter() {
    let encounterUUID = uuid.v4();
    console.log('encounterID', encounterUUID);
    this.encounter = {
      encounterID: encounterUUID,
      patientID: this.patient.patientID,
      subjective: "",
      objective: "",
      assessment: "",
      plan: {
        prescriptions: [],
        procedures: [],
        referrals: [],
        labs: []
      },
      reports: [],
      emrImages: [],
      startTime: 0,
      endTime: 0,
      updateTime: 0,
      createTime: 0,
      billingInfo: null,
      billingDetails: null,
      encounterDate: "",
      pdfDoc: null,
      keypoints: null
    };

    // set the date as date today
    let todate = new Date();
    this.encounter.encounterDate = "" + todate.getFullYear() + '-' + ((todate.getMonth() < 9) ? '0' : '') + (todate.getMonth() + 1) + '-' + ((todate.getDate() < 10) ? '0' : '') + todate.getDate();
    return this.encounter;
  }

  addEncounter(encounter: EncounterInfo) {
    console.log("RecordsAPI: Add Encounter", encounter);
    // tag the time of creation
    this.encounter.createTime = new Date().getTime();
    // add the encounter to the list
    return new Promise((resolve, reject) => {
      this.encountersCollection.add(encounter).then(newEncounter => {
        console.log("RecordsAPI: New Encounter", newEncounter);
        this.encounter.encounterID = newEncounter.id;
        this.saveEncounter(this.encounter).then(ok => {
          resolve("OK");
        }, err => {
          reject(err);
        });
      }, err => {
        console.log("RecordsAPI: could not add new Encounter Info");
        reject(err);
      })
    });
  }

  // save an encounter completely

  saveEncounter(encounter: EncounterInfo = null) {
    if (encounter === null) {
      encounter = this.encounter;
    } else {
      //this.encounter = encounter;
    }
    if (encounter.createTime == 0)
      encounter.createTime = new Date().getTime();
    encounter.updateTime = new Date().getTime(); // update the updateTime
    console.log("RecordsAPI: Save Encounter Info", encounter);
    return new Promise((resolve, reject) => {
      let encounterDoc = this.afs.doc<EncounterInfo>(this.afsPath + '/records/' + encounter.patientID + '/encounters/' + encounter.encounterID);
      encounterDoc.set(encounter).then(() => {
        this.encounter = encounter;
        resolve("ok");
      }, err => {
        console.log("RecordAPI: error in updating encounter");
        reject("err");
      });
    })
  }

  // update encounter fields only
  updateEncounter(params = null, patientID = null, encounterID = null) {
    // default the patientID and encounterID to the currently loaded encounter, for simplicity sake
    if (patientID === null) patientID = this.encounter.patientID;
    if (encounterID === null) encounterID = this.encounter.encounterID;
    console.log("RecordsAPI: Update Encounter Info", patientID, encounterID, params);
    return new Promise((resolve, reject) => {
      if (params === null) {
        reject({ error: "incomplete parameters", message: "incomplete parameters" });
      } else {
        params['updateTime'] = new Date().getTime(); // update the updateTime
        let encounterDoc = this.afs.doc<EncounterInfo>(this.afsPath + '/records/' + patientID + '/encounters/' + encounterID);
        encounterDoc.update(params).then(() => {
          Object.assign(this.encounter, params);
          console.log("Encounter fields saved", params);
          resolve({ result: 'ok', params: params });
        }, err => {
          console.log("RecordAPI: error in updating encounter");
          reject("err");
        }).finally(() => {
          // save the billing if it exists
          if (this.encounter['billingInfo'] != null && this.encounter['billingInfo'].billingID > "") {
            let chargeSlip: ChargeSlip = {
              billingInfo: this.encounter['billingInfo'],
              billingDetails: this.encounter['billingDetails'],
              pdfDoc: this.encounter['pdfDoc']
            };
            if (chargeSlip.billingInfo.billingID > "") {
              this.saveChargeSlip(chargeSlip);
            }
          }
        });
      }

    })
  }

  // select a clinic and set the form to this clinic

  selectEncounter(encounterID, patientID = null) {
    if (patientID === null) patientID = this.patient.patientID;
    return new Promise((resolve, reject) => {
      let encounterDoc = this.afs.doc<EncounterInfo>(this.afsPath + '/records/' + patientID + '/encounters/' + encounterID);
      encounterDoc.valueChanges().pipe(take(1)).subscribe(encounter => {
        console.log("RecordsAPI: Encounter Info", encounter);
        //clinicDoc.unsubscribe();
        this.encounter = encounter;
        this.selectEMRs(encounter.emrImages).then(ok => {
          console.log("selectedEMR's");
        }, err => {
          console.log("error while selecting EMR's", err);
        });
        resolve(encounter);
      }, err => {
        console.log("RecordsAPI: Error in selecting encounter");
        reject(err);
      });
    })

  }

  deleteEncounter(encounterID = null, patientID = null) {
    console.log("RecordsApi: deleteEncounter", encounterID, patientID);
    return new Promise((resolve, reject) => {
      if (encounterID === null || patientID === null) {
        reject("need patient ID and encounter ID")
      } else {
        let encounterDoc = this.afs.doc<EncounterInfo>(this.afsPath + '/records/' + patientID + '/encounters/' + encounterID);
        encounterDoc.delete().then(() => {
          resolve("ok");
        }, err => {
          console.log("RecordAPI: error in deleting encounter", err);
          reject(err);
        });
      }
    })
  }
  //////////// functions to manage charge slips ////////////////
  // update the information of a clinic

  saveChargeSlip(chargeSlip: ChargeSlip = null) {
    return new Promise((resolve, reject) => {
      let billingInfo = chargeSlip.billingInfo
      if (billingInfo.billingID > "") { // can only save if there is a billing ID
        billingInfo.details = chargeSlip.billingDetails;
        billingInfo.pdfDoc = chargeSlip.pdfDoc;
        let billingDoc = this.afs.doc<BillingInfo>(this.afsPath + '/billings/' + billingInfo.billingID);
        billingDoc.set(billingInfo).then(() => {
          console.log("Saved Billing Info", billingInfo.billingID);
          resolve("ok");
        }, err => {
          console.log("RecordAPI: error in updating encounter");
          reject("err");
        });
      } else {
        reject({ error: "no billing ID" })
      }

    })
  }
  //////////// functions to manage  encounter history
  // encounter history is just  a listing of patients and encounters in chronological order
  // this is useful for browsing through encounters as they happened,
  // usually in reverse chronological order
  // this structure is made to make it easier to go through encounters scattered across patient records

  addEncounterHistory(patientID, encounterID) {
    let encounter = {
      patientID: patientID,
      encounterID: encounterID,
      timestamp: new Date().getTime()
    }
    console.log("RecordsAPI: Add Encounter History", patientID, encounterID);
    let encountersHistoryDoc = this.afs.doc<EncounterHistory>(this.afsPath + '/encounters_history/' + encounter.timestamp);
    encountersHistoryDoc.set(encounter);
  }

  // select an encounter from a patient based on timestamp ID

  selectEncounterHistory(patientID, encounterTimestamp) {
    let recordDoc = this.afs.doc<EncounterHistory>(this.afsPath + '/encounters_history/' + encounterTimestamp);
    recordDoc.valueChanges().pipe(take(1)).subscribe(encounter => {
      console.log("RecordsAPI: Encounter History", encounter);
      //clinicDoc.unsubscribe();
      this.selectRecord(encounter.patientID);
      this.selectEncounter(encounter.encounterID);
    });
  }

  getEncounters() {
    console.log("RecordsAPI: Encounters Collection", this.encountersCollection);

    return this.encountersCollection.valueChanges();

  }
  lastEncounterDate() {


  }

  ////////////////////////////
  ////// EMR Scans to Firebase Storage
  ////////////////////////////

  /*
  ** For security, EMR Scans will be uploaded via this path
  ** /accountNum/clinicNum/patientID/EMRScanID
  ** EMRSCAN ID will be a timestamp so that it is predetermined
  ** locally we should consider md5ing the accountNum and clinicNum and patientID so as not to give it away
  */

  // local EMR data management
  async deleteEMRData(emrID) {
    let prefix = "EMR_";
    let key = prefix + emrID;
    const res = await this.storage.remove(key);
    console.log("RecordsAPI: deleteEMRData():",emrID);
  }

  getAllEMRKeys() {
    // get all the storage keys
    let prefix = "EMR_";
    // find the ones similar to emr
    return new Promise((resolve, reject) => {
      this.storage.keys().then(allKeys => {
        let emrKeys = [];
        let prefixLength = prefix.length;
        allKeys.forEach(akey => {
          if (akey.startsWith(prefix)) {
            emrKeys.push(akey.substr(prefixLength))
          }
        })
        console.log("RecordsAPI: getAllEMRKeys() return", emrKeys);
        if (emrKeys.length > 0) {
          resolve({emrKeys:emrKeys, length:emrKeys.length});
        } else {
          reject({emrKeys:emrKeys, length:emrKeys.length});
        }
      });
    })
  }
  /*
  ** Add a new EMRScan to the Blob storage
  */

  addEMRScan(emrInfo) {
    console.log("RecordsAPI:addEMRScan()", emrInfo);
    let emrDoc = this.afs.doc<EMRImage>(this.afsPath + '/records/' + this.patient.patientID + '/emr/' + emrInfo.imageID);

    // add the encounter to the list
    return new Promise((resolve, reject) => {
      emrDoc.set(emrInfo).then(() => {
        console.log("Saved the EMR");
        this.storeEMRScan(emrInfo);
        resolve("ok");
      }, err => {
        console.log("error in saving the EMR");
        reject(err);
      })
    });
  }

  // updateEMRScan() will only update the metadata, not the image and image filenames
  updateEMRScan(emrInfo) {
    console.log("RecordsAPI:updateEMRScan()", emrInfo);
    let emrDoc = this.afs.doc<EMRImage>(this.afsPath + '/records/' + this.patient.patientID + '/emr/' + emrInfo.imageID);
    // add the encounter to the list
    return new Promise((resolve, reject) => {
      emrDoc.set(emrInfo).then(() => { // can further limit this to a few fields by using an update
        console.log("Updated the EMR");
        resolve("ok");
      }, err => {
        console.log("error in saving the EMR");
        reject(err);
      })
    });
  }

  selectEMRs(emrList) {
    this.emrImages = [];
    let ctr = 0;
    let lastCount = emrList.length
    return new Promise((resolve, reject) => {
      if (lastCount == 0) reject({ ctr: lastCount });
      else {
        emrList.forEach(emrID => {
          let emrDoc = this.afs.doc<EMRImage>(this.afsPath + '/records/' + this.patient.patientID + '/emr/' + emrID);
          emrDoc.valueChanges().pipe(take(1)).subscribe(emrScan => {
            if (emrScan) {
              this.emrImages.push(emrScan);
              this.checkEMRScan(emrScan).then(ok => {
                console.log("EMR is available");
              }, err => {
                console.log("EMR is not available");
              });
            } else {
              // don't push it to the list.
            }
            ctr++; // count for every attempt at subcribe
            if (ctr == lastCount) {
              resolve({ ctr: ctr })
            }
          }, err => {
            console.log("Could not find doc", err);
            ctr++;
            if (ctr == lastCount) {
              resolve({ ctr: ctr })
            }
          })
        })
      }
    })
  }

  setImageSource(emrInfo, index) {
    console.log("setImageSource()", emrInfo, index);
    if (this.platform.is("cordova") && this.isApp) {
      console.log("Platform is cordova", this.isApp);
      if (emrInfo.filetype == "application/pdf") {
        //this.imageSources[index] = this.sanitizer.bypassSecurityTrustResourceUrl(this.recordApi.dataDirectory + emrInfo.filename);
        //this.imageSources[index] = this.recordApi.dataDirectory + emrInfo.filename;
        let path =
          this.dataDirectory +
          emrInfo.filename.substr(0, emrInfo.filename.lastIndexOf("/") + 1);
        let filename = emrInfo.filename.substring(
          emrInfo.filename.lastIndexOf("/") + 1
        );
        console.log("reading", path, filename);
        this.file.readAsDataURL(path, filename).then(
          buffer => {
            console.log("Read buffer");
            if (buffer) {
              console.log("Assigning buffer", index);
              this.imageSources[index] = buffer;
              console.log("Image Source", index, this.imageSources[index]);
            } else {
              console.log("Error, buffer is ", buffer);
            }
          },
          err => {
            console.log("did not read file properly", err);
          }
        );
      } else {
        console.log("using the WebView function");
        let url = this.dataDirectory + emrInfo.filename;
        this.imageSources[index] = this.win.Ionic.WebView.convertFileSrc(url);
      }
      console.log("Image Source", index, this.imageSources[index]);
    } else {
      // we are on a browser

      // first try the local Cache
      this.storage.get(emrInfo.storageName).then(
        dataURL => {
          if (dataURL) {
            console.log(
              "assigning image from storage",
              index,
              emrInfo.storageName
            );
            if (emrInfo.filetype == "application/pdf") {
              //console.log("Got PDF DataURL", dataURL);
              this.imageSources[index] = dataURL;
              // @ViewChild('pdfViewer1') pdfViewer;
              // pdfViewer.pdfSrc = this.dataURItoBlob(dataURL, 'application/pdf'); // pdfSrc can be Blob or Uint8Array
              // pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
            } else {
              this.imageSources[index] = this.sanitizer.bypassSecurityTrustUrl(
                dataURL
              );
            }
            console.log("Image Source", index, this.imageSources[index]);
          } else {
            // next try direct URL
            console.log("No dataURL", index, emrInfo.filename, dataURL);
            let source = " - " + emrInfo.filename;
            console.log("Set Image Source", index, source);
            this.imageSources[index] = this.sanitizer.bypassSecurityTrustUrl(
              emrInfo.filename
            );
          }
        },
        err => {
          console.log("No such image from storage", index, emrInfo.filename);
          let source = " - " + emrInfo.filename;
          console.log("Set Image Source", index, source);
          this.imageSources[index] = this.sanitizer.bypassSecurityTrustUrl(
            emrInfo.filename
          );
        }
      );
    }
  }

  dataURItoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  storeEMRScan(emrInfo) {
    console.log("Record: addEMRScan()", emrInfo);

    const filestoragePath = emrInfo.firestorageName;
    // leave a marker in storage
    let emrMarker = 'THETIS-EMR-' + Md5.hashStr(filestoragePath);
    this.storage.set(emrMarker, JSON.stringify(emrInfo)).then(ok => {
      console.log('Marker saved', emrMarker);
    });

    if (this.isApp) {
      // we are on a device, use cordova
      const filename = this.file.dataDirectory + emrInfo.filename;

      let imagePath = filename.substr(0, filename.lastIndexOf('/') + 1);
      let imageName = filename.substring(filename.lastIndexOf('/') + 1);
      console.log("Reading file into buffer", imagePath, imageName);

      this.file.readAsArrayBuffer(imagePath, imageName).then(buffer => {
        console.log("Read file into buffer", imagePath, imageName);
        let blob = new Blob([buffer], { type: "text/plain" });
        console.log("Blob  created", blob);
        const fileRef = this.firestorage.ref(filestoragePath);
        console.log("fileRef", fileRef);
        const task = fileRef.put(blob).then(result => {
          console.log("Put", result);
          if (result) {
            if (result.state == 'success') {
              this.storage.remove(emrMarker).then(ok => {
                console.log("Marker removed", emrMarker);
              })
            }
          }
        }, err => {
          console.log("Could not put", err);
        });
        //const task = this.firestorage.upload(filestoragePath, buffer);
        console.log("Task created", task);
        // // observe percentage changes
        // this.uploadPercent = task.percentageChanges();
        // // get notified when the download URL is available
        // task.snapshotChanges().pipe(
        //     finalize(() => downloadURL = fileRef.getDownloadURL() )
        //   ).subscribe(ok=>{
        //     console.log("finalizing", this.uploadPercent);
        //   });
      }, err => {
        console.log("Could not read file to string", err);
      });
    }
    else {
      // we are on a browser, no cordova
      // convert the rest of this code to a node.js call to store the image
      this.storage.get(emrInfo.storageName).then(imageURLData => {
        console.log("Read image from storage", emrInfo.storageName);
        fetch(imageURLData).then(res => res.blob()).then(blob => {
          console.log("Blob  created", blob);
          const fileRef = this.firestorage.ref(filestoragePath);
          console.log("fileRef", fileRef);
          fileRef.put(blob).then(result => {
            console.log("Put", result);
            // remove the marker to indicate that we no longer have to sync it
            this.storage.remove(emrMarker).then(ok => {
              console.log("Marker removed", emrMarker);
            })
          }, err => {
            console.log("Could not put", err);
          });
        })
        // var reader = new FileReader();
        // reader.onload = ()=>{
        //   var arrayBuffer = reader.result;
        //
        //   console.log(arrayBuffer.byteLength);
        // };
        // reader.readAsDataURL(imageURLData);
      })

    }


  }

  /*
  ** delete an EMRScan from the firestorage
  */

  deleteEMRScan(emrID) {
    return new Promise((resolve, reject) => {
      let emrDoc = this.afs.doc<EMRImage>(this.afsPath + '/records/' + this.patient.patientID + '/emr/' + emrID);

      emrDoc.valueChanges().pipe(take(1)).subscribe(emrScan => {
        if (emrScan) {
          console.log("Removing from firebase storage", emrScan.firestorageName)
          const fileRef = this.firestorage.ref(emrScan.firestorageName);
          fileRef.delete().subscribe(() => {
            console.log("emrScan deleted from firebase storage", emrScan.firestorageName);
          })
        }
        emrDoc.delete().then(() => {
          console.log("emrScan delete from firestore");
          resolve("ok");
        }, err => {
          console.log("something went wrong while deleting from firestore", err);
          reject(err);

        })
      })
    })
  }

  /*
  ** download an emrImage from firebase storage
  */

  // https://gist.github.com/robnyman/1875344  get the file as a blob, read via FileReader save to local storage

  downloadEMRScan(emrInfo) {
    console.log("Record: downloadEMRScan", emrInfo.firestorageName)
    const filestoragePath = emrInfo.firestorageName;
    const fileRef = this.firestorage.ref(filestoragePath);
    let downloadRef = fileRef.getDownloadURL();
    return new Promise((resolve, reject) => {
      downloadRef.subscribe(downloadURL => {
        console.log("Downloading", filestoragePath, downloadURL);
        const fileToTransfer: FileTransferObject = this.transfer.create();
        this.platform.ready().then(() => {
          if (this.isApp) {
            const filename = this.file.dataDirectory + emrInfo.filename;
            fileToTransfer.download(downloadURL, filename).then((entry) => {
              console.log('download complete: ' + entry.toURL());
              resolve(entry);
            }, (error) => {
              // handle error
              console.log("was not able to download");
              reject(error);
            })
          } else {
            // we are on a browser so do a storage save
            // recode  this to an nodeJS request to store the image from firebasestorage
            console.log("We are on a browser", downloadURL);
            const xhr = new XMLHttpRequest();
            xhr.open("GET", downloadURL);
            xhr.responseType = "blob";
            xhr.onload = () => {
              console.log("got it", xhr.response, xhr.statusText, xhr.status);

              // convert blob into a dataURL
              var reader = new FileReader();
              reader.onloadend = () => {
                //console.log('reader result',reader.result);
                this.storage.set(emrInfo.storageName, reader.result);
              }
              reader.readAsDataURL(xhr.response);
              resolve(xhr.response)
            };
            xhr.onerror = () => {
              console.log("did not get it", xhr.statusText);
              reject(xhr.statusText)
            };
            xhr.send();
          }
        }, err_platform => {
          console.log("Platform not ready", err_platform)
          reject(err_platform);
        });

      }, err => {
        console.log("Could not get download URL", err);
        reject(err);
      }) // subscribe
    }) // Promise


  }
  /*
  ** go through a list of emrImages or scans
  */

  preCheckScans(scans) {
    var ctr = 0;
    var checkedIn = 0;
    return new Promise((resolve, reject) => {
      scans.forEach(scan => {
        this.checkEMRScan(scan).then(result => {
          ctr++;
          if (result == "ok") checkedIn++;
          if (ctr == scans.length) {
            resolve({ ctr: ctr, checkedIn: checkedIn });
          }
        }, error => {
          ctr++;
          if (ctr == scans.length) {
            resolve({ ctr: ctr, checkedIn: checkedIn });
          }
        });
      });
    })
  }

  /*
  ** Check if an EMR Scan is available locally, if not, then download to local
  */
  checkEMRScan(emrInfo) {
    return new Promise((resolve, reject) => {
      console.log("Checking if file is available", emrInfo);
      if (emrInfo === undefined) {
        reject({ error: "undefined" });
      } else {
        if (this.isApp) {
          // we are on a device
          this.dataDirectory = this.file.dataDirectory;
          const filename = this.file.dataDirectory + emrInfo.filename;
          let imagePath = filename.substr(0, filename.lastIndexOf('/') + 1);
          let imageName = filename.substring(filename.lastIndexOf('/') + 1);
          this.file.checkFile(imagePath, imageName).then(fileEntry => {
            if (fileEntry) {
              // then file is available
              console.log("File available");
              resolve("ok");
            } else {
              // file not available
              console.log("File not available");
              this.downloadEMRScan(emrInfo).then(result => {
                console.log("EMR Scan Checked in", result);
                resolve("ok")
              }, err => {
                console.log("EMR Scan Not Checked in", err);
                reject(err);
              })
            }
          }, err => {
            console.log("Something went wrong with checking the file.", err);
            // file not available
            this.downloadEMRScan(emrInfo).then(result2 => {
              console.log("EMR Scan Checked in", result2);
              resolve("ok")
            }, error => {
              console.log("EMR Scan Not Checked in", err);
              reject(error);
            })
          }) // file.checkFile
        } else {
          // we are on a browser
          console.log("We are on a browser", emrInfo.storageName);
          const ref = this.firestorage.ref(emrInfo.firestorageName);
          console.log("Downloading URL from firestorage", emrInfo.firestorageName)
          ref.getDownloadURL().subscribe(fireURL => {
            console.log("Got the downloadURL()", fireURL);
            //emrInfo.filename = this.sanitizer.bypassSecurityTrustUrl(fireURL);
            emrInfo.filename = fireURL;
            // do a secondary attempt to get it from storage
            resolve("ok");
          }, err => {
            console.log("Did not get the downloadURL", err)
            // do a secondary attempt to get it from storage
            reject(err);
          });
          // do a secondary attempt to get it from storage
          this.storage.get(emrInfo.storageName).then(storeData => {
            if (storeData) {
              console.log("File is available on storage, just in case", emrInfo.storageName)
            } else {
              // file is not available in localStorage
              console.log("File is not available on storage, attempting to get it");
              this.downloadEMRScan(emrInfo).then(result => {
                console.log("EMR Scan Checked in", result);
                resolve("ok")
              }, err => {
                console.log("EMR Scan Not Checked in", err);
                reject(err);
              })
            }
          })


        }
      }; // else

    }); // Promise
  }

  checkLocalImage(name) {
    const filename = this.file.dataDirectory + name;
    let imagePath = filename.substr(0, filename.lastIndexOf('/') + 1);
    let imageName = filename.substring(filename.lastIndexOf('/') + 1);
    return new Promise((resolve, reject) => {
      console.log("checking for local image", name);
      this.file.checkFile(imagePath, imageName).then(fileEntry => {
        resolve(filename);
      }, err => {
        reject('assets/imgs/missing_emr.png');
      })
    })
  }


  ////////////// tele-consult functions ////////////////////

  sendMailer(header = {}, template = {}) {
    //console.log("sendMailer: mailerInfo", header, message);
    let defaultHeader = {
      to: 'reuben@versaclinic.app',
    }
    let mailer = Object.assign({}, defaultHeader, header);
    Object.assign(mailer, template)
    console.log("sendMailer: sending ", mailer);
    const mailCollection = this.afs.collection('c2pmail');
    mailCollection.add(mailer).then(result => {
      console.log("added mailer", result)
    }, err => {
      console.log("Error while adding mailer", err)
    });
  }

}
