import { Component, OnInit} from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PatientInfo, VitalsTaken } from "../patient-info";
import { CanDeactivate } from '@angular/router';
@Component({
  selector: 'app-record-vitals',
  templateUrl: './record-vitals.page.html',
  styleUrls: ['./record-vitals.page.scss'],
})
export class RecordVitalsPage implements OnInit {

  patientInfo:PatientInfo;
  requiredVitals:any=[];
  vitalsTaken: VitalsTaken = {
    vitals:[],
    timestamp:0
  }
  didClose:boolean=false;
  showHistory: boolean = false;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController) {
    this.patientInfo = navParams.get('patientInfo');
    this.requiredVitals = navParams.get('requiredVitals');
    console.log('xyz patient info', this.patientInfo);

    console.log(navParams, this.patientInfo, this.requiredVitals);
    this.vitalsTaken.timestamp = new Date().getTime();
    this.requiredVitals.forEach((vital)=>{
      this.vitalsTaken.vitals.push({
        measure:vital,
        measurement:"",
        uom:""
      });
    });
    console.log("RecordVitalsPage", this.patientInfo.vitalsTaken);
  }

  ngOnInit() {
  }

  canDeactivate(){
    return this.didClose;
  }

  getDateString(dateInt) {
    return (new Date(parseInt(dateInt)).toString()).substring(4, 15);
  }

  checkUOM(index){
    var measurement = this.vitalsTaken.vitals[index].measurement;
    var number = measurement.match(/([0-9]|,|\.)+/)
    var uom = "";
    console.log("number", number, measurement);
    if(number!= null){
      measurement = number[0];
      uom = number.input.substr(number[0].length);
      console.log("Measurement", measurement, uom)
    }
    // if(measurment.indexOf(' ') > 0){
    //
    //   this.vitalsTaken.measurement = measurement.substr(0,measurement, indexOf(' '))
    // }
  }

  saveVitals(){
    console.log("Saving vitals", this.patientInfo, this.vitalsTaken);
    this.patientInfo.vitalsTaken.splice(0,0,this.vitalsTaken);
    this.didClose=true;
    this.modalCtrl.dismiss( {result:"Closed"});
  }

  deleteVitals(timestamp){
    console.log('xyz delete', timestamp);
    this.patientInfo.vitalsTaken = this.patientInfo.vitalsTaken.filter(v => v.timestamp != timestamp);
  }

  cancelVitals(){
    this.didClose=true;
    this.modalCtrl.dismiss( {result:"Closed"});
  }
}
