import { Component, Input, OnInit } from '@angular/core';
import { NavController,Platform, ModalController} from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { File } from '@ionic-native/file/ngx';
import { Storage } from '@ionic/storage';

import { Md5 } from 'ts-md5/dist/md5';
import { EMRImage } from '../encounter-info';


@Component({
  selector: 'app-scan-image',
  templateUrl: './scan-image.page.html',
  styleUrls: ['./scan-image.page.scss'],
})
export class ScanImagePage implements OnInit {
    @Input() mode:string="single";
    @Input() emr:any;
    @Input() patientID:any;
    @Input() accountID:any;
    @Input() clinicID:any;

    base64Image:string="";
    emrFile={
      imageID:"",
      reportType:"",
      datetime:"",
      filename:"",
      filetype:"",
      firestorageName:"",
      storageName:"",
      createTime:0
    }
    didClose:boolean=false;
    emrDirPath:string="";
    emrFirePath:string="";
    emrStoragePath:string="";
    instructions:string="Enter medical record information then take a photo or upload from file folder";
    title:string="Scan Medical Record";
    isWebApp:boolean=false;

    constructor(public navCtrl: NavController,
      private modalCtrl: ModalController,
      private file: File,
      private camera:Camera,
      private chooser: Chooser,
      private platform: Platform,
      private storage: Storage) {

    }

    ngOnInit() {
      console.log('ScanImagePage');
      this.platform.ready().then(()=>{
        this.isWebApp = (this.platform.is('desktop') || this.platform.is('mobileweb') || this.platform.is('pwa') || this.platform.is('electron'));
        if(this.mode == 'edit'){
          this.emrFile = this.emr;
          this.instructions = "Edit medical record information";
          this.title = "Edit Medical Record Info"
        }else{
          if(!this.isWebApp){
            this.mode = 'dual';
          }
          this.setDirPath();
          // pre-set the datetime
          let todate = new Date();
          //D-MMM-YYYY
          let datestring = todate.toDateString();
          this.emrFile.datetime = '' + todate.getFullYear();
          this.emrFile.datetime += '-' + ((todate.getMonth() < 9) ? 0 : '') + (todate.getMonth() + 1);
          this.emrFile.datetime += '-' + ((todate.getDate() < 9) ? 0 : '') + (todate.getDate());
          console.log(this.emrFile.datetime);
        }
        console.log("Platform Ready: isWebApp", this.isWebApp)
      });
    }

    ionViewCanLeave(){
      return this.didClose;
    }


    setDirPath(){

      let patientID = Md5.hashStr(this.patientID);
      let clinicID = Md5.hashStr(this.clinicID);
      let accountID = Md5.hashStr(this.accountID);
      this.emrFile.imageID = '' + new Date().getTime();
      this.emrDirPath =  accountID + '/' + clinicID + '/' + patientID + '/';
      this.emrFirePath = 'emr/' + this.accountID + '/' + this.clinicID + '/' + this.patientID;
      this.emrStoragePath = 'EMR_' + this.patientID + '_' + this.emrFile.imageID;
      if(!this.isWebApp){
        this.checkAndCreateDir(this.file.dataDirectory,  accountID).then(dirEntry=>{
          this.checkAndCreateDir(this.file.dataDirectory + accountID + '/', clinicID).then(dirEntry2=>{
            this.checkAndCreateDir(this.file.dataDirectory +  accountID + '/' + clinicID+ '/', patientID).then(dirEntry3 =>{
              console.log("emrDirPath is ready", this.emrDirPath);
            }, err=>{
              console.log("Could not create patient directory", err);
            })
          }, err=>{
            console.log("Could not create clinic directory", err);
          })
        }, err=>{
            console.log("cannot create account directory", err);
        })
      }

    }

    checkAndCreateDir(path, dirName){
      this.file.listDir(path, '.').then(
        entries=>{console.log("Checking directory", entries, path, dirName)},
        err=>{console.log({error:"Error listing directory", err: err,path: path, dirName:dirName});}
      );
      return new Promise((resolve, reject)=>{
        this.file.checkDir(path, dirName).then( result => {
          console.log("Checked the directory", result, path, dirName);
          resolve("ok");
        },err=>{
          console.log("Error in checking dir", err, path, dirName);
          this.file.createDir(path, dirName, false).then( dirEntry=>{
            console.log("Created Directory", path, dirName, dirEntry);
            resolve(dirEntry);
          }, error=>{
            console.log("Could not create directory", path, dirName, error);
            reject(error);
          });
        });
      });
    }

    resetEMR(){
      this.emrFile = {
        reportType:"",
        datetime:"",
        filename:"",
        filetype:"",
        firestorageName:"",
        storageName:"",
        createTime:0,
        imageID:"",
      };
      this.emrFile.datetime = new Date().toISOString();
    }

    saveEMR(){
      this.didClose = true;
      this.emrFile.createTime = new Date().getTime();
      this.modalCtrl.dismiss( {result:"Saved", emr:this.emrFile });

    }

    scanImage(){
      var doClear = true;
      let options: CameraOptions = {
        quality: 70,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        sourceType: this.camera.PictureSourceType.CAMERA,
        correctOrientation:true,
      }
      this.camera.getPicture(options).then((fileURI) => {
       // imageData is either a base64 encoded string or a file URI
       // If it's base64 (DATA_URL):
       //let base64Image = 'data:image/jpeg;base64,' + imageData;
       //this.base64Image = imageData;
       console.log("Got Image", fileURI, options);
       let imagePath = fileURI.substr(0,fileURI.lastIndexOf('/') + 1);
       let imageName = fileURI.substr(fileURI.lastIndexOf('/') + 1, fileURI.length);
       let filestamp = '' + new Date().getTime();
       let filename = filestamp + '.png';
       console.log("Saving file", imagePath, imageName, filename);
       this.clearThenCopy(imagePath, imageName, filename, doClear);

      }, (err) => {
       // Handle error
       console.log("Error in camera", err);
      });
    }

    chooseFile(){
      this.chooser.getFile('image/*,application/pdf').then(file => {
        console.log(file ? file.name : 'canceled')
        if(file && file.name){
          console.log("Got file", file)
          let filePath = file.uri.substr(0,file.uri.lastIndexOf('/') + 1);
          let filestamp = '' + new Date().getTime();
          let filename = filestamp + file.name.substring(file.name.lastIndexOf('.'));
          console.log("Saving file", filePath, file.name, filename);
          this.clearThenCopy(file, file.name, filename, false);
        }
      },err=>{
          console.log("Error in choosing file")
      })

    }




    // to ensure a proper copy of the file. There must be no existing file.

    clearThenCopy(namePath, currentName, newFileName, doClear=true){
      this.file.checkFile(this.file.dataDirectory + this.emrDirPath, newFileName).then (result=>{
        if(result){
          console.log("uh-oh, remove file first");
          this.file.removeFile(this.file.dataDirectory + this.emrDirPath, newFileName).then(removed=>{
            this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
          }, err=>{
            console.log("Could  not remove file", this.emrDirPath, newFileName, err);
          })
        }else{
          this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
        }
      }, err=>{
        if(err.code == 1){
          console.log("right, there is no file", this.file.dataDirectory +this.emrDirPath, newFileName, err);
          this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
        }else{
          console.log("No further actions", err);
        }
      })

    }

    // Copy the image to a local folder
    copyFileToLocalDir(fileRef, currentName, newFileName, doClear) {

      if(doClear){
        console.log("copyFileToLocalDir()", fileRef, currentName, newFileName);
        this.file.copyFile(fileRef, currentName, this.file.dataDirectory + this.emrDirPath, newFileName).then(success => {
          this.emrFile.filename = this.emrDirPath + newFileName;
          this.emrFile.firestorageName = this.emrFirePath + '/' + newFileName;
          this.emrFile.storageName = this.emrStoragePath;
          this.emrFile.filetype = 'image/png';
          console.log("copied file", success, this.emrFile.filename);
          if(doClear){
            this.file.removeFile(fileRef, currentName).then(removed=>{
              console.log("removed cached file", currentName);
            }, err=>{
              console.log("Could  not remove file", fileRef, currentName, err);
            })
          }
          this.saveEMR();
        }, error => {
          console.log("error while storing file", error, fileRef, currentName, newFileName);
        });
      }else{
        console.log("copyFileToLocalDir() Writing a file from dataURI", fileRef.dataURI.length , currentName, newFileName);
        let blob = this.dataURItoBlob(fileRef.dataURI);
        this.file.writeFile(this.file.dataDirectory + this.emrDirPath, newFileName, blob).then(success => {
          this.emrFile.filename = this.emrDirPath + newFileName;
          this.emrFile.firestorageName = this.emrFirePath + '/' + newFileName;
          this.emrFile.storageName = this.emrStoragePath;
          this.emrFile.filetype = fileRef.mediaType;
          console.log("wrote file", success, this.emrFile.filename);
          this.saveEMR();
        }, error => {
          console.log("error while storing file", error, fileRef, currentName, newFileName);
        });
      }
    }

    dataURItoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }
    // used for browser based interfaces
    uploadFile($event) : void {
      let reader = new FileReader();
      var filename = '' + this.emrFile.imageID;
      if(filename == undefined){
        console.log("Filename was undefined")
        filename = '' + new Date().getTime();
      }
      reader.onload = () => {
        //console.log('Loaded: ', reader.result);
        this.storage.set(this.emrStoragePath, reader.result).then(ok=>{
          if(this.emrFile.firestorageName==""){
            if($event.target.files[0].type=='image/png')
            switch($event.target.files[0].type){
              case 'image/png':
                filename += '.png';
                break;
              case 'image/jpg':
              case 'image/jpeg':
                filename += '.jpg';
                break;
              case 'image/gif':
                filename += '.gif';
                break;
              default:
                let uploadedFilename = $event.target.files[0].name;
                let suffix = uploadedFilename.substring(uploadedFilename.indexOf('.'))
                filename += suffix;
                break;
            }
            console.log("ScanImage : uploadFile()", this.emrFile, filename);
            this.emrFile.filename = this.emrDirPath + filename;
            this.emrFile.filetype = $event.target.files[0].type;
            this.emrFile.firestorageName = this.emrFirePath + '/' + filename;
          }
          this.emrFile.storageName = this.emrStoragePath;
          this.saveEMR();
        },err=>{
          console.log("Failed to save EMR to storage", err);
          this.modalCtrl.dismiss();
        })

      };
      console.log(reader);
      let imageFile = $event.target.files[0];
      console.log(imageFile);
      reader.readAsDataURL(imageFile);
      this.mode="done";
    }

    cancelScan(){
        this.didClose = true;
        this.modalCtrl.dismiss( {result:"Closed", emr:null});
    }

    saveEdits(){
        this.didClose = true;
        this.modalCtrl.dismiss( {result:"Saved", emr:this.emrFile });
    }


  }
