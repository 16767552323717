import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Billable } from '../billable'
import { BillingMasterProvider } from '../services/billing-master.service';
import { LookupServiceProvider } from '../services/lookup.service';


@Component({
  selector: 'app-billable-modal',
  templateUrl: './billable-modal.page.html',
  styleUrls: ['./billable-modal.page.scss'],
})
export class BillableModalPage implements OnInit {

  @Input() billingMaster: Billable[];
  filtered: Billable[];
  input: string;
  shouldShowCancel:boolean=true;

  constructor(

    public billingMasterProvider: BillingMasterProvider,
    public modal: ModalController,
    private lookupApi: LookupServiceProvider
  ) {

  }

  ngOnInit() {
    console.log('BillableModalPage');
    if (this.billingMaster) {
      this.filtered = this.billingMaster;
      console.log("Got Billing Master", this.billingMaster);
    } else {
      this.billingMaster = this.lookupApi.billingMaster;
      this.filtered = this.billingMaster;
    }
  }

  closeModal(event=null) {
    this.modal.dismiss();
  }

  itemSelected(billable: Billable) {
    this.modal.dismiss(billable);
  }

  onInput(event: any) {
    this.filtered = [];

    for (let i = 0; i < this.billingMaster.length; i++) {
      let description = (this.billingMaster[i].description).toLowerCase();
      let unit = (this.billingMaster[i].unit).toLowerCase();

      let check1 = description.includes((this.input).toLowerCase());
      let check2 = unit.includes((this.input).toLowerCase());

      if (check1 || check2) {
        this.filtered.push(this.billingMaster[i]);
      }
    }
  }

}
