import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-appointment-list-setting',
  templateUrl: './appointment-list-setting.component.html',
  styleUrls: ['./appointment-list-setting.component.scss'],
})
export class AppointmentListSettingComponent implements OnInit {
  displayAll = 'All appointments';
  displayFiltered = 'Hide finished appointments';
  listDisplay: string;

  constructor(
    private popoverController: PopoverController, 
    private navParams: NavParams,
  ) { 
    this.listDisplay = this.navParams.get('display');
  }

  ngOnInit() {}

  setDisplay(option) {
    this.listDisplay = option;
    this.popoverController.dismiss({ display: option});
  }

}
