import { Component, OnInit, Input , ViewChild} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { File } from '@ionic-native/file/ngx';
import { Storage } from '@ionic/storage';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-scan-profile',
  templateUrl: './scan-profile.page.html',
  styleUrls: ['./scan-profile.page.scss'],
})
export class ScanProfilePage implements OnInit {
  @ViewChild('stepper',{static:false}) stepper;
  @Input() accountID: string;
  @Input() clinicID: string;
  @Input() patientID: string;
  base64Image: string = "";
  imagePath: string = "";
  didClose: boolean = false;
  emrDirPath: string = "";
  emrFirePath: string = "";
  emrStoragePath: string = "";
  emrFile = {
    imageID: "",
    reportType: "profile pic",
    datetime: "",
    filename: "",
    filetype: "",
    firestorageName: "",
    storageName: "",
    createTime: 0
  }
  win: any = window;
  justScanned: boolean = false;
  readyToSave: string = "no";
  isWebApp: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    private file: File,
    private camera: Camera,
    private chooser: Chooser,
    private crop: Crop,
    private platform: Platform,
    private storage: Storage) {
    this.platform.ready().then(() => {
      this.isWebApp = (this.platform.is('desktop') || this.platform.is('mobileweb') || this.platform.is('pwa') || this.platform.is('electron'));
      let accountID = this.accountID;
      let clinicID = this.clinicID;
      let patientID = this.patientID;
      this.setDirPath(accountID, clinicID, patientID);
      if (!this.isWebApp) {
        this.scanImage();
      } else {
        // make an option to open a file instead of the camera
        this.modalCtrl.dismiss();
      }
    })
  }
  ngOnInit() {
    console.log('ScanProfilePage');
  }

  ionViewCanLeave() {
    return this.didClose;
  }

  dismiss() {
    let data = { profilePicPath: this.imagePath };
    this.modalCtrl.dismiss(data);
  }

  cancelScan() {
    this.didClose = true;
    this.modalCtrl.dismiss({ result: "Closed", emr: null });
  }

  saveEMR() {
    this.didClose = true;
    this.emrFile.createTime = new Date().getTime();
    this.modalCtrl.dismiss({ result: "Saved", emr: this.emrFile, profilePicPath: this.imagePath });

  }

  ////////////////////// IMAGE management functions

  scanImage() {
    var doClear = true;
    let options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
    }
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      if (options.destinationType == this.camera.DestinationType.DATA_URL) {
        // If it's base64 (DATA_URL):
        this.imagePath = 'data:image/jpeg;base64,' + imageData;
        console.log("Got Image", imageData.substring(256), options);
      } else {
        // If it's a file_URI
        let fileURI = imageData;
        this.imagePath = imageData;
        let imagePath = fileURI.substr(0, fileURI.lastIndexOf('/') + 1);
        let imageName = fileURI.substr(fileURI.lastIndexOf('/') + 1, fileURI.length);
        let filename = 'tempfile.png';
        console.log("Saving file", imagePath, imageName, filename);
        this.justScanned = true;
        this.readyToSave = "no";
        this.clearThenCopy(imagePath, imageName, filename, doClear);
        this.stepper.next();
      }
    }, (err) => {
      // Handle error
      console.log("Error in camera", err);
    });
  }

  chooseFile() {
    this.chooser.getFile('image/*,application/pdf').then(file => {
      console.log(file ? file.name : 'canceled')
      if (file && file.name) {
        console.log("Got file", file)
        let filePath = file.uri.substr(0, file.uri.lastIndexOf('/') + 1);
        let filestamp = '' + new Date().getTime();
        let filename = filestamp + file.name.substring(file.name.lastIndexOf('.'));
        console.log("Saving file", filePath, file.name, filename);
        this.justScanned = true;
        this.readyToSave = "no";
        this.clearThenCopy(file, file.name, filename, false);
      }
    }, err => {
      console.log("Error in choosing file")
    })

  }

  // used for browser based interfaces
  uploadFile($event) : void {
    let reader = new FileReader();
    var filename = '' + this.emrFile.imageID;
    if(filename == undefined){
      console.log("Filename was undefined")
      filename = '' + new Date().getTime();
    }
    reader.onload = () => {
      //console.log('Loaded: ', reader.result);
      this.storage.set(this.emrStoragePath, reader.result).then(ok=>{
        if(this.emrFile.firestorageName==""){
          if($event.target.files[0].type=='image/png')
          switch($event.target.files[0].type){
            case 'image/png':
              filename += '.png';
              break;
            case 'image/jpg':
            case 'image/jpeg':
              filename += '.jpg';
              break;
            case 'image/gif':
              filename += '.gif';
              break;
            default:
              let uploadedFilename = $event.target.files[0].name;
              let suffix = uploadedFilename.substring(uploadedFilename.indexOf('.'))
              filename += suffix;
              break;
          }
          console.log("ScanImage : uploadFile()", this.emrFile, filename);
          this.emrFile.filename = this.emrDirPath + filename;
          this.emrFile.filetype = $event.target.files[0].type;
          this.emrFile.firestorageName = this.emrFirePath + '/' + filename;
        }
        this.emrFile.storageName = this.emrStoragePath;
        this.saveEMR();
      },err=>{
        console.log("Failed to save EMR to storage", err);
        this.modalCtrl.dismiss();
      })

    };
    console.log(reader);
    let imageFile = $event.target.files[0];
    console.log(imageFile);
    reader.readAsDataURL(imageFile);
    this.justScanned = true;
    this.readyToSave = "no";
  }

  cropProfilePic() {
    let refFilePath = this.file.dataDirectory + this.emrFile.filename;
    console.log("Crop file", refFilePath);
    this.crop.crop(refFilePath, { quality: 100 }).then(
      newImage => {
        console.log('new image path is: ' + newImage)
        let fileURI = newImage.substr(0, newImage.lastIndexOf('?'));
        let imagePath = fileURI.substr(0, fileURI.lastIndexOf('/') + 1);
        let imageName = fileURI.substr(fileURI.lastIndexOf('/') + 1, fileURI.length);
        let currentFilename = this.emrFile.filename;
        let oldFilename = currentFilename.substr(currentFilename.lastIndexOf('/') + 1, currentFilename.length);
        let filename = this.emrFile.imageID + '.png';
        console.log("Saving cropped file", imagePath, imageName, filename);
        this.justScanned = false;
        this.readyToSave = "just about";
        this.clearThenCopy(imagePath, imageName, filename, true);
        //remove previous file
        this.file.removeFile(this.file.dataDirectory + this.emrDirPath, oldFilename).then(removed => {
          console.log("removed old file", oldFilename);
        }, err => {
          console.log("Could  not remove file", oldFilename, err);
        });
        this.stepper.next();
      },
      error => console.error('Error cropping image', error)
    );
  }
  //////////////// FILE FUNCTIONS /////////////////////

  // set various save paths based on //account/clinic/patient
  setDirPath(accountID, clinicID, patientID) {
    this.emrFile.imageID = '' + new Date().getTime();
    this.emrDirPath = accountID + '/' + clinicID + '/' + patientID + '/';
    this.emrFirePath = 'profile_pic/' + accountID + '/' + clinicID + '/' + patientID;
    this.emrStoragePath = 'PROFILE_PIC_' + patientID + '_' + this.emrFile.imageID;
    if (!this.isWebApp) {
      this.checkAndCreateDir(this.file.dataDirectory, accountID).then(dirEntry => {
        this.checkAndCreateDir(this.file.dataDirectory + accountID + '/', clinicID).then(dirEntry2 => {
          this.checkAndCreateDir(this.file.dataDirectory + accountID + '/' + clinicID + '/', patientID).then(dirEntry3 => {
            console.log("emrDirPath is ready", this.emrDirPath);
          }, err => {
            console.log("Could not create patient directory", err);
          })
        }, err => {
          console.log("Could not create clinic directory", err);
        })
      }, err => {
        console.log("cannot create account directory", err);
      })
    }

  }


  checkAndCreateDir(path, dirName) {
    this.file.listDir(path, '.').then(
      entries => { console.log("Checking directory", entries, path, dirName) },
      err => { console.log({ error: "Error listing directory", err: err, path: path, dirName: dirName }); }
    );
    return new Promise((resolve, reject) => {
      this.file.checkDir(path, dirName).then(result => {
        console.log("Checked the directory", result, path, dirName);
        resolve("ok");
      }, err => {
        console.log("Error in checking dir", err, path, dirName);
        this.file.createDir(path, dirName, false).then(dirEntry => {
          console.log("Created Directory", path, dirName, dirEntry);
          resolve(dirEntry);
        }, error => {
          console.log("Could not create directory", path, dirName, error);
          reject(error);
        });
      });
    });
  }


  // to ensure a proper copy of the file. There must be no existing file.

  clearThenCopy(namePath, currentName, newFileName, doClear = true) {
    this.file.checkFile(this.file.dataDirectory + this.emrDirPath, newFileName).then(result => {
      if (result) {
        console.log("uh-oh, remove file first");
        this.file.removeFile(this.file.dataDirectory + this.emrDirPath, newFileName).then(removed => {
          this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
        }, err => {
          console.log("Could  not remove file", this.emrDirPath, newFileName, err);
        })
      } else {
        this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
      }
    }, err => {
      if (err.code == 1) {
        console.log("right, there is no file", this.file.dataDirectory + this.emrDirPath, newFileName, err);
        this.copyFileToLocalDir(namePath, currentName, newFileName, doClear);
      } else {
        console.log("No further actions", err);
      }
    })

  }


  // Copy the image to a local folder
  copyFileToLocalDir(fileRef, currentName, newFileName, doClear) {

    if (doClear) {
      console.log("copyFileToLocalDir()", fileRef, currentName, newFileName);
      this.file.copyFile(fileRef, currentName, this.file.dataDirectory + this.emrDirPath, newFileName).then(success => {
        this.emrFile.filename = this.emrDirPath + newFileName;
        this.emrFile.firestorageName = this.emrFirePath + '/' + newFileName;
        this.emrFile.storageName = this.emrStoragePath;
        this.emrFile.filetype = 'image/jpg';
        this.imagePath = this.win.Ionic.WebView.convertFileSrc(this.file.dataDirectory + this.emrFile.filename);
        console.log("copied file", success, this.emrFile.filename, this.imagePath);
        if (this.justScanned) {
          this.justScanned = false;
          this.cropProfilePic();
        }
        if (this.readyToSave == "just about") {
          this.readyToSave = "ready";
        }
        if (doClear) {
          this.file.removeFile(fileRef, currentName).then(removed => {
            console.log("removed cached file", currentName);
          }, err => {
            console.log("Could  not remove file", fileRef, currentName, err);
          })
        }
      }, error => {
        console.log("error while storing file", error, fileRef, currentName, newFileName);
      });
    } else {
      console.log("copyFileToLocalDir() Writing a file from dataURI", fileRef.dataURI.length, currentName, newFileName);
      let blob = this.dataURItoBlob(fileRef.dataURI);
      this.file.writeFile(this.file.dataDirectory + this.emrDirPath, newFileName, blob).then(success => {
        this.emrFile.filename = this.emrDirPath + newFileName;
        this.emrFile.firestorageName = this.emrFirePath + '/' + newFileName;
        this.emrFile.storageName = this.emrStoragePath;
        this.emrFile.filetype = fileRef.mediaType;
        console.log("wrote file", success, this.emrFile.filename);
      }, error => {
        console.log("error while storing file", error, fileRef, currentName, newFileName);
      });
    }
  }

  dataURItoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }



}
