import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientInfo } from '../patient-info';
import { PatientProvider } from '../services/patient.service';
import { RecordsApiProvider } from '../services/records-api.service';

@Component({
  selector: 'app-patient-modal',
  templateUrl: './patient-modal.page.html',
  styleUrls: ['./patient-modal.page.scss'],
})
export class PatientModalPage implements OnInit {
    @Input('recordApi') recordApiPassed: any;

    patients: PatientInfo[];
    input: string="";
    shouldShowCancel:boolean=true;
    searchResults;

    constructor(
      private modalCtrl: ModalController,
      public patientProvider: PatientProvider,
      public recordApi: RecordsApiProvider,
      private activatedRoute: ActivatedRoute
    ) {
      this.recordApi.searchRecordsByName('lahat').then(data => {
        if (data) {
          this.searchResults = data;
        }
      });
    }
    ngOnInit() {
    }

    closeModal(event=null) {
      this.modalCtrl.dismiss();
    }

    itemSelected(patient: PatientInfo) {
      this.modalCtrl.dismiss(patient);
    }

    // my modified search
    onInputSearch(){
      let name = this.input || 'lahat';
      if(name.length >= 2){
        this.recordApi.searchRecordsByName(name).then(data => {
            if(data) {
              this.searchResults = data;
            }
          }, err => console.log(err));
      }
    }


  }
