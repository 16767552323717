import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { VisitsComponent } from "./visits/visits.component";

@NgModule({
  declarations: [
    VisitsComponent,
	],
	imports: [
    IonicModule,
		CommonModule,
	],
	exports: [
		VisitsComponent,
	]
})
export class ComponentsModule {}
