import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { RecordsApiProvider } from '../services/records-api.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {
  imageSource: any;
  sliderOpts: any;
  @ViewChild('slider', { read: ElementRef, static: false })slider: ElementRef;

  constructor(private navParams: NavParams, private modalCtrl: ModalController, public recordApi: RecordsApiProvider,) { }

  ngOnInit() {
    const index = this.navParams.get('sourceIndex');
    this.imageSource = this.recordApi.imageSources[index];
    this.sliderOpts = {
      zoom: {
        maxRatio: 3
      }
    }
  }

  zoom(zoomIn: boolean) {
    let zoom = this.slider.nativeElement.swiper.zoom;

    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
