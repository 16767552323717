import { Injectable } from "@angular/core";
import { PatientProvider } from "./patient.service";
import { RecordsApiProvider } from "./records-api.service";
import { ClinicsApiProvider } from "./clinics-api.service";
import { Appointment } from "../appointment";
import { PatientInfo } from "../patient-info";
import { BusinessHour } from "../business-hour";
import { Observable } from "rxjs";
import { of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventProvider {
  //eventSources: any[];
  businessHours: BusinessHour[];
  appointments: any;

  constructor(
    public patientProvider: PatientProvider,
    private recordApi: RecordsApiProvider,
    private clinicApi: ClinicsApiProvider
  ) {
    console.log("Hello EventProvider Provider");

    this.loadBusinessHours();
    //this.loadAppointments();
  }

  getEventsArray() {
    console.log("Events: getEventsArray()");
    this.loadBusinessHours();
    return new Promise((resolve, reject) => {
      let todate = new Date();
      let cutoff = todate.setDate(todate.getDate() - 14);
      var doneCtr = 0;
      this.clinicApi.getQueue().subscribe(appointments => {
        let events = [];
        console.log("Got appointments", appointments);
        if (appointments.length > 0) {
          appointments.forEach(appointment => {
            console.log("Appointment", appointment);
            if (appointment.start != undefined) {
              const { appointmentID, appointmentType, start, queueNo } = appointment;
              const color = appointmentType === 'Scheduled' ? '#ff9800' : appointmentType === 'Walk-in' ? '#64b5f6' : '#66bb6a';
              const allDay = start.length === 10 ? true : false;

              let e = {
                appointmentID,
                start: new Date(start),
                status: (appointment.status).toUpperCase(),
                title: '',
                backgroundColor: color,
                borderColor: color,
                allDay,
                queueNo: parseInt(queueNo + '') || 0
              };

              if (parseInt(appointment.appointmentID) > cutoff) {
                this.recordApi.retrieveRecord(appointment.patientID).then(record => {
                  let title = record['firstname'] + " " + record['lastname'];
                  //let title = record['firstname'] + " "  + record['lastname'] + " · " + (appointment.status).toUpperCase();
                  e.title = title;
                  console.log("Pushing", e);
                  events.push(e);
                  doneCtr++;
                  if (doneCtr == appointments.length) {
                    resolve({ events: events, businessHours: this.businessHours });
                  }
                }, err => {
                  console.log("Error, matching with a patientID", err);
                  let title = 'unknown patient';
                  //let title = 'unknown patient' + " · " + (appointment.status).toUpperCase();
                  e.title = title;
                  console.log("Pushing", e);
                  events.push(e);
                  doneCtr++;
                  if (doneCtr == appointments.length) {
                    resolve({ events: events, businessHours: this.businessHours });
                  }
                });
              } else {
                doneCtr++;
              }
            } // only those with a start value
          }); // forEach
        } else {
          resolve({ events: events, businessHours: this.businessHours });
        }
      }); // getQueue
    }); // new Promise
  }

  // loadAppointments() {
  //   this.appointments = {};
  //   let dateStr: string = "20190121";
  //   this.appointments[dateStr] = [];
  //   console.log(this.appointments);
  // }

  // get an event from the appointments collection using appointment ID
  // use clinicApi getAppointmentByID(appointmentID)
  // no need for timestamp or dateID
  getEvent(appointmentID: string) {
    return new Promise((resolve, reject) => {
      // get the appointment
      let appointment: Appointment;
      let patient: PatientInfo;
      this.clinicApi
        .getAppointmentByID(appointmentID)
        .subscribe(appointmentInfo => {
          appointment = appointmentInfo;
          if (appointment != undefined) {
            this.recordApi.retrieveRecord(appointmentInfo.patientID).then(
              record => {
                patient = record;
                resolve({ appointment: appointment, patient: patient });
              },
              err => {
                console.log("error in getting patient record");
                patient = null;
                resolve({ appointment: appointment, patient: patient });
              }
            );
          } else {
            reject({ appointment: null, patient: null });
          }
        });
    });
  }

  getDateID(timestamp: any) {
    let mon: string = (timestamp.getMonth() + 1).toString();
    if (mon.length == 1) {
      mon = "0" + mon;
    }

    let date: string = timestamp.getDate().toString();
    if (date.length == 1) {
      date = "0" + date;
    }

    let dateID: string = timestamp.getFullYear() + mon + date;

    return dateID;
  }

  updateEvent(appointment: Appointment, timestamp: string) {
    appointment.start = timestamp;
    appointment.queueID = this.getDateID(new Date(appointment.start));

    console.log("updateEvent()", appointment);
    this.clinicApi.updateAppointment(appointment);
  }

  removeEvent(appointment: Appointment) {
    this.clinicApi.deleteAppointment(appointment);
  }

  /******checkConflictEvent(date: any): Observable <number> {

    let check: number = 0;

    for (let i = 0; i < this.appointments.length; i++) {
      let temp: any = new Date(this.appointments[i].start);
      console.log(date.getTime() + " " + temp.getTime());
      /*if (temp.start.getTime() === date.getTime()) {
        check = temp.id;
      }
    }

    return of(check);
  }*/

  loadBusinessHours() {
    this.businessHours = this.clinicApi.clinicInfo.clinicHours;
    console.log("Load BusinessHour", this.businessHours);
    // let timestamp = new Date();
    //
    // this.businessHours = [{
    //   businessHourID: (timestamp.getTime() - 1).toString(),
    //   dow: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
    //   start: '13:00', // 8am
    //   end: '15:00' // 6pm
    // },
    // {
    //   businessHourID: '1',
    //   dow: [ 4, 5 ], // Thursday, Friday
    //   start: '09:00', // 10am
    //   end: '11:00' // 4pm
    // }];
  }

  getBusinessHoursArray(): Observable<BusinessHour[]> {
    let data: BusinessHour[] = this.businessHours;

    return of(data);
  }

  updateBusinessHours(businessHour: BusinessHour) {
    let index: number = -1;

    for (let i = 0; i < this.businessHours.length; i++) {
      if (this.businessHours[i].businessHourID == businessHour.businessHourID) {
        index = i;
      }
    }

    if (index == -1) {
      this.businessHours.unshift(businessHour);
    } else {
      this.businessHours.splice(index, 1);
      this.businessHours.unshift(businessHour);
    }
  }

  removeBusinessHour(businessHour: BusinessHour) {
    let index: number;

    for (let i = 0; i < this.businessHours.length; i++) {
      if (this.businessHours[i] == businessHour) {
        index = i;
        i = this.businessHours.length;
      }
    }

    this.businessHours.splice(index, 1);
  }
}
