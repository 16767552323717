import { Component, OnInit , Input} from '@angular/core';
import { NavController,  ModalController } from '@ionic/angular';

import faker from "faker";

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.page.html',
  styleUrls: ['./report-modal.page.scss'],
})
export class ReportModalPage implements OnInit {
    @Input() reportTemplate:any={};
    constructor(
      public navCtrl: NavController,
      public modalCtrl: ModalController) {
        // start of controller

    }

    ngOnInit() {
      console.log('ReportModalPage');

    }

    saveValue(fieldName){

    }

    autoFillTemplate(){
      this.reportTemplate.sections.forEach((section, index)=>{
        section.value = faker.lorem.lines(section.rows);
      })
    }

    submitReportTemplate(){
      let data={
        reportTemplate:this.reportTemplate,
        status:"success"
      }
      this.modalCtrl.dismiss(data);
    }

    cancelEditing(){
      let data = {
        status:'cancelled'
      }
      this.modalCtrl.dismiss(data);
    }

    close(){
      this.modalCtrl.dismiss({status:'cancelled'});
    }

  }
