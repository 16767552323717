import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { MenuController, Platform, AlertController, LoadingController, Events } from '@ionic/angular';

// firebase
import { AngularFireAuth } from '@angular/fire/auth';

import { ConfigProvider } from '../services/config.service';
import { ClinicsApiProvider } from '../services/clinics-api.service';
import { RecordsApiProvider } from '../services/records-api.service';
import { AuthApi } from '../services/auth.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  firstRun: boolean = true;
  menuEnabled: boolean = true;
  clinic: {
    name: string;
    icon: string;
    link: string;
  }[] = [
      // {
      //   name: 'Clinic',
      //   icon: 'menuclinic',
      //   link: '/clinic',
      // },
      // {
      //   name: 'Calendar',
      //   icon: 'menucalendar',
      //   link: '/calendar',
      // },
      // {
      //   name: 'Records',
      //   icon: 'menurecords',
      //   link: '/record-search',
      // },
      // {
      //   name: 'Help',
      //   icon: 'menuhelp',
      //   link: '/loading',
      // },
      // {
      //   name: 'Settings',
      //   icon: 'menusettings',
      //   link: '/settings',
      // },
      //
      // {
      //   name: 'Logout',
      //   icon: 'menulogout',
      //   link: '/login',
      // },
    ];
  selectedPath: string = '';
  constructor(private router: Router,
    public platform: Platform,
    private afAuth: AngularFireAuth,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    private configApi: ConfigProvider,
    private authApi: AuthApi,
    private clinicApi: ClinicsApiProvider,
    private recordApi: RecordsApiProvider,
    public events: Events,
    private menu: MenuController) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.selectedPath = event.url;
    });

    events.subscribe('user:login', (user) => {
      this.initForUser(user);
    });
    events.subscribe('user:pinLogon', (user) => {
      this.initSections();
    })
    events.subscribe('user:pinLogoff', (user) => {
      this.initSections();
    })

    // the next event is published after calling selectClinic
    events.subscribe('config:selectClinic', (clinicID) => {
      this.setPaths().then(ok => { // set paths for recordApi and clinicApi
        this.initSections();
      }, err => {
        console.log("Error while getting Clinic", err);
      });

    })
    events.subscribe('login:enableMenu', (status) => {
      this.menuEnabled = status;
      this.menu.enable(status, 'leftMenu');
      console.log("MenuPage: menuEnabled", status);
      if(status){

      }else{
        console.log("MenuPage: Close the menu");
        this.menu.close('leftMenu');
      }
    })
  }

  ngOnInit() {
    console.log("APP: ngOnInit");
  }
  ionViewWillEnter(){
    console.log("Init: MenuPage will enter");
  }

  //
  // /**
  // **    The observer will be triggered in the following scenarios:
  // **
  // **   When auth().onAuthStateChanged() is first called. It will trigger with the initial Auth state. If the user is returning from an auth().signInWithRedirect() operation, the observer will wait for that operation to resolve before initially triggering.
  // **   When a new user signs.
  // **   When an already signed in user signs out.
  // */
  //
  ngAfterViewInit() {
    console.log("AuthTrack APP: ngAfterViewInit", this.afAuth.auth)
    this.afAuth.auth.onAuthStateChanged((user) => {
      console.log("AuthTrack App: onAuthStateChanged", user);
      if (user) {
        // User is authenticated.
        let signInTime = new Date(user.metadata.lastSignInTime);
        let nowTimestamp = new Date().getTime();
        console.log("TimeDiff", nowTimestamp - signInTime.getTime());
        this.configApi.selectAccount(user.uid).then(selected => {
          if (selected) {
            // if the doctor just signed in with email address then do a directLogin
            if (nowTimestamp - signInTime.getTime() < 8000) {
              this.configApi.directLoginDoctor().then(loggedIn => {
                this.initForUser(user);
              }, err => {
                this.initForUser(user);
              });
            } else {
              console.log("Waiting for PIN login");
              this.initForUser(user);
            }
          } else {
            console.log("no account selected", selected);
          }
        }, err => {
          console.log("Could not select account", err);
        });
      } else {
        // User is not authenticated.
        console.log("AuthTrack User is not authenticated");
        this.initForUser(user); // consider turning this into a promise
      }
    }, err => {
      console.log("AuthTrack Error on AuthStateChanged", err);
    });
  }
  //
  initForUser(user) {
    console.log("initForUser");
    if (user != null) {
      // someone is logged In
      // make the left menu appear
      this.authApi.doctorIsIn = true;
      this.configApi.reloadPINLoginStatus().then(reloaded => {
        // initialize the config before using it
        console.log("initForUser: reloadedPIN");
        this.configApi.setConfig(user.uid).then(ok => {
          // set the default clinic
          console.log("initForUser: ok setting config")
          this.configApi.setDefaultClinic().then(ok => {
            console.log("initForUser: ok setting default clinic")
            this.configApi.showWizardAlert();
          }, err => {
            console.log("initForUser: Could not set Default Clinic");
          });
        });
      }, err => {
        console.log("initForUser: Error while reloading PIN user")
      });

    } else {
      // there is no one logged In
      // make the left menu disappear
      this.authApi.doctorIsIn = false;
      this.initSections();
    }

  }


  //
  // initialize the sections on the left menu
  async initSections() {
    console.log("APP: initSections()", this.configApi.pinLoggedIn, this.configApi.pinLoginUserType);
    if (this.configApi.pinLoggedIn == true) {
      this.clinic = [
        {
          name: 'clinic',
          icon: 'menuclinic',
          link: '/clinic'
        }, {
          name: 'calendar',
          icon: 'menucalendar',
          link: '/calendar'
        }, {
          name: 'records',
          icon: 'menurecords',
          link: '/record-search'
        }, {
          name: 'help',
          icon: 'menuhelp',
          link: '/loading'
        }
      ];
      // only doctors can adjust settings
      if (this.configApi.pinLoginUserType == 'doctor') {
        this.clinic.push(
          {
            name: 'settings',
            icon: 'menusettings',
            link: '/settings'
          }
        )
      }
      // everyone needs to be able to logout
      this.clinic.push(
        {
          name: 'logout',
          icon: 'menulogout',
          link: '/login'
        }
      )
      // // side check for PIN Code
      // if(this.configApi.accountInfo.doctorPIN == ""){
      //   this.pinPrompt();
      // }
      if (this.firstRun) {
        let loading = await this.loadCtrl.create({
          message: 'Please wait...'
        });
        await loading.present();
        setTimeout(async () => {
          await loading.dismiss();
          this.setRootPage('/clinic');
        }, 3000);
        await loading.dismiss();
        this.setRootPage('/clinic');
        this.events.publish('clinicConfig:Loaded', 'ok');
        // setTimeout(async () => {
        //   await loading.dismiss();
        //   this.setRootPage('/clinic');
        // }, 3000);
      }
    } else {
      this.clinic = [
        {
          name: 'login',
          icon: 'menulogout',
          link: '/login'
        }
      ];
      this.setRootPage('login');
    }
  }

  setPaths() {
    return new Promise((resolve, reject) => {
      this.configApi.getClinicPath().then(clinicPath => {
        console.log("Got Clinic Path", clinicPath)
        this.recordApi.setPath(clinicPath);
        this.clinicApi.setPath(clinicPath).then(clinicReady => {
          console.log("Clinic is Ready")
          resolve("OK");
        });
      }, err => {
        console.log("Error while getting Clinic", err);
        reject(err);
      });
    })

  }

  //
  setRootPage(page = null) {
    console.log("setRootPage", page);
    if (this.firstRun) {
      // if its the first run we also have to hide the splash screen
      this.router.navigate([page]);
        // .then(() => this.platform.ready())
        // .then(() => {
        //
        //   // Okay, so the platform is ready and our plugins are available.
        //   // Here you can do any higher level native things you might need.
        //   this.statusBar.styleDefault();
        //   this.splashScreen.hide();
        //   this.firstRun = false;
        // });
    } else {
      this.router.navigate([page])
    }
  }

}
