import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tele-script',
  templateUrl: './tele-script.page.html',
  styleUrls: ['./tele-script.page.scss'],
})
export class TeleScriptPage implements OnInit {
  mode:string="start script";
  title:string="Read and follow this script";

  constructor(public modalCtrl: ModalController, navParams:NavParams) {
    console.log(navParams.get('firstName'));
    if(navParams.get('mode') == 'video link'){
      this.mode = 'video link';
      this.title = "How to create a Video Chat Meeting";
    }
  }

  ngOnInit() {
  }

  close(){
    this.modalCtrl.dismiss();
  }

}
